
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { CWA_CODE: '183000',   AREA_NO: '183000',  PACKAGE: 'P3',      EM_DATE: '2023-08-28', A_SYS_QTY: 35,        ASH_TOT: 4817,    ASH_ACT: 4439,    ASH_REM: '378',     ASH_PRO: 92.2,    ASH_STATUS: 'INPROGRESS', AP_TOT: 312,    AP_ACT: 308,    AP_REM: 4,      AP_PRO: 98.7,   BP_TOT: 2413,   BP_ACT: 1001,   BP_REM: 1412,   BP_PRO: 41.5,   BSH_TOT: 2326,    BSH_ACT: 287,     BSH_REM: 2039,    BSH_PRO: 12.3,    CP_TOT: 12,     CP_ACT: 2,      CP_REM: 10,     CP_PRO: 16.7,   CWA_DESC: 'GPPE Vent Gas Recovery And Propylene Refrigeraton Area Of Line 1 7350 GPPE 1', },
    { CWA_CODE: '183100',   AREA_NO: '183100',  PACKAGE: 'P3',      EM_DATE: '2023-11-30', A_SYS_QTY: 23,        ASH_TOT: 793,     ASH_ACT: 56,      ASH_REM: '737',     ASH_PRO: 7.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 2,      AP_ACT: 0,      AP_REM: 2,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 264,     BSH_ACT: 0,       BSH_REM: 264,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Area B Substation B', },
    { CWA_CODE: '184302',   AREA_NO: '184302',  PACKAGE: 'P1',      EM_DATE: '2024-03-20', A_SYS_QTY: 13,        ASH_TOT: 1189,    ASH_ACT: 20,      ASH_REM: '1169',    ASH_PRO: 1.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 392,     BSH_ACT: 0,       BSH_REM: 392,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Demineralized Water Unit Condensate Water Treatment', },
    { CWA_CODE: '184303',   AREA_NO: '184303',  PACKAGE: 'P1',      EM_DATE: '2024-03-20', A_SYS_QTY: 5,         ASH_TOT: 246,     ASH_ACT: 0,       ASH_REM: '246',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 88,      BSH_ACT: 0,       BSH_REM: 88,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Demineralized Water Unit Acid Caustic Station', },
    { CWA_CODE: '184800',   AREA_NO: '184800',  PACKAGE: 'P1',      EM_DATE: '2024-04-01', A_SYS_QTY: 109,       ASH_TOT: 5621,    ASH_ACT: 16,      ASH_REM: '5605',    ASH_PRO: 0.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 4,      AP_ACT: 0,      AP_REM: 4,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1452,    BSH_ACT: 0,       BSH_REM: 1452,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Boiler Unit Boiler Battery Limit', },
    { CWA_CODE: '184891',   AREA_NO: '184891',  PACKAGE: 'P1',      EM_DATE: '2023-10-30', A_SYS_QTY: 23,        ASH_TOT: 922,     ASH_ACT: 779,     ASH_REM: '143',     ASH_PRO: 84.5,    ASH_STATUS: 'INPROGRESS', AP_TOT: 114,    AP_ACT: 0,      AP_REM: 114,    AP_PRO: 0,      BP_TOT: 74,     BP_ACT: 0,      BP_REM: 74,     BP_PRO: 0,      BSH_TOT: 244,     BSH_ACT: 0,       BSH_REM: 244,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Boiler Unit Boiler RIE6 RIE6', },
    { CWA_CODE: '184892',   AREA_NO: '184892',  PACKAGE: 'P1',      EM_DATE: '2023-10-30', A_SYS_QTY: 26,        ASH_TOT: 630,     ASH_ACT: 560,     ASH_REM: '70',      ASH_PRO: 88.9,    ASH_STATUS: 'INPROGRESS', AP_TOT: 89,     AP_ACT: 1,      AP_REM: 88,     AP_PRO: 1.1,    BP_TOT: 59,     BP_ACT: 0,      BP_REM: 59,     BP_PRO: 0,      BSH_TOT: 315,     BSH_ACT: 0,       BSH_REM: 315,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Boiler Unit Boiler Unit Substation', },
    { CWA_CODE: '185300',   AREA_NO: '185300',  PACKAGE: 'P3',      EM_DATE: '2023-12-20', A_SYS_QTY: 30,        ASH_TOT: 1665,    ASH_ACT: 80,      ASH_REM: '1585',    ASH_PRO: 4.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 33,     AP_ACT: 0,      AP_REM: 33,     AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 327,     BSH_ACT: 0,       BSH_REM: 327,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Air Compression Station', },
    { CWA_CODE: '185392',   AREA_NO: '185392',  PACKAGE: 'P3',      EM_DATE: '2023-12-05', A_SYS_QTY: 31,        ASH_TOT: 505,     ASH_ACT: 24,      ASH_REM: '481',     ASH_PRO: 4.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 2,      AP_ACT: 0,      AP_REM: 2,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 267,     BSH_ACT: 0,       BSH_REM: 267,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Air Compression Station Substation', },
    { CWA_CODE: '185394',   AREA_NO: '185394',  PACKAGE: 'P3',      EM_DATE: '2023-12-31', A_SYS_QTY: 13,        ASH_TOT: 542,     ASH_ACT: 20,      ASH_REM: '522',     ASH_PRO: 3.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 206,     BSH_ACT: 0,       BSH_REM: 206,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Air Compression Station Operator Shelter', },
    { CWA_CODE: '186000',   AREA_NO: '186000',  PACKAGE: 'P2',      EM_DATE: '2023-12-05', A_SYS_QTY: 46,        ASH_TOT: 6490,    ASH_ACT: 2822,    ASH_REM: '3668',    ASH_PRO: 43.5,    ASH_STATUS: 'INPROGRESS', AP_TOT: 34,     AP_ACT: 0,      AP_REM: 34,     AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 2873,    BSH_ACT: 0,       BSH_REM: 2873,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Control Center Building', },
    { CWA_CODE: '187501',   AREA_NO: '187501',  PACKAGE: 'P3',      EM_DATE: '2023-12-15', A_SYS_QTY: 7,         ASH_TOT: 95,      ASH_ACT: 0,       ASH_REM: '95',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 21,      BSH_ACT: 0,       BSH_REM: 21,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Radio Antenna tower incl Telecom equipment room', },
    { CWA_CODE: '190400',   AREA_NO: '190400',  PACKAGE: 'P3',      EM_DATE: '2024-03-30', A_SYS_QTY: 1,         ASH_TOT: 87,      ASH_ACT: 0,       ASH_REM: '87',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Eastward Pipeline', },
    { CWA_CODE: '252701',   AREA_NO: '252701',  PACKAGE: 'P3',      EM_DATE: '2024-03-31', A_SYS_QTY: 1,         ASH_TOT: 31,      ASH_ACT: 0,       ASH_REM: '31',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Truck Scale1 BaggingPPLDPE 1 1', },
    { CWA_CODE: '252702',   AREA_NO: '252702',  PACKAGE: 'P3',      EM_DATE: '2024-03-31', A_SYS_QTY: 1,         ASH_TOT: 31,      ASH_ACT: 0,       ASH_REM: '31',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Truck Scale2 BaggingGPPE 2 2', },
    { CWA_CODE: '252703',   AREA_NO: '252703',  PACKAGE: 'P3',      EM_DATE: '2023-12-20', A_SYS_QTY: 1,         ASH_TOT: 28,      ASH_ACT: 0,       ASH_REM: '28',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Truck Scale3 Chemical Warehouse 3', },
    { CWA_CODE: '270100',   AREA_NO: '270100',  PACKAGE: 'P1',      EM_DATE: '2024-06-19', A_SYS_QTY: 20,        ASH_TOT: 502,     ASH_ACT: 8,       ASH_REM: '494',     ASH_PRO: 1.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 49,      BSH_ACT: 1,       BSH_REM: 48,      BSH_PRO: 2,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Offspec Ethylene Tank Farm', },
    { CWA_CODE: '270300',   AREA_NO: '270300',  PACKAGE: 'P1',      EM_DATE: '2024-04-30', A_SYS_QTY: 34,        ASH_TOT: 3695,    ASH_ACT: 66,      ASH_REM: '3629',    ASH_PRO: 1.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 11,     AP_ACT: 0,      AP_REM: 11,     AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 640,     BSH_ACT: 14,      BSH_REM: 626,     BSH_PRO: 2.2,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Pressurized Tank Farm 2 2', },
    { CWA_CODE: '277000',   AREA_NO: '277000',  PACKAGE: 'P1',      EM_DATE: '2024-08-09', A_SYS_QTY: 2,         ASH_TOT: 270,     ASH_ACT: 27,      ASH_REM: '243',     ASH_PRO: 10,      ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 104,     BSH_ACT: 0,       BSH_REM: 104,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Atmospheric Tank Farm Common Area', },
    { CWA_CODE: '277082',   AREA_NO: '277082',  PACKAGE: 'P1',      EM_DATE: '2024-03-30', A_SYS_QTY: 15,        ASH_TOT: 118,     ASH_ACT: 4,       ASH_REM: '114',     ASH_PRO: 3.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 67,      BSH_ACT: 0,       BSH_REM: 67,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Tank Farm 2nd Substation', },
    { CWA_CODE: '277100',   AREA_NO: '277100',  PACKAGE: 'P1',      EM_DATE: '2024-08-09', A_SYS_QTY: 27,        ASH_TOT: 1559,    ASH_ACT: 10,      ASH_REM: '1549',    ASH_PRO: 0.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 11,     AP_ACT: 0,      AP_REM: 11,     AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 301,     BSH_ACT: 4,       BSH_REM: 297,     BSH_PRO: 1.3,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Intermediate Tank Farm 2 2', },
    { CWA_CODE: '280200',   AREA_NO: '280200',  PACKAGE: 'P3',      EM_DATE: '2024-02-29', A_SYS_QTY: 22,        ASH_TOT: 2172,    ASH_ACT: 0,       ASH_REM: '2172',    ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 682,     BSH_ACT: 0,       BSH_REM: 682,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Cooling Water System 2', },
    { CWA_CODE: '280292',   AREA_NO: '280292',  PACKAGE: 'P3',      EM_DATE: '2023-12-20', A_SYS_QTY: 23,        ASH_TOT: 182,     ASH_ACT: 8,       ASH_REM: '174',     ASH_PRO: 4.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 2,      AP_ACT: 0,      AP_REM: 2,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 314,     BSH_ACT: 0,       BSH_REM: 314,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Cooling Water System2 Substation', },
    { CWA_CODE: '280294',   AREA_NO: '280294',  PACKAGE: 'P3',      EM_DATE: '2023-11-30', A_SYS_QTY: 11,        ASH_TOT: 220,     ASH_ACT: 8,       ASH_REM: '212',     ASH_PRO: 3.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 141,     BSH_ACT: 0,       BSH_REM: 141,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Operator Shelter', },
    { CWA_CODE: '281000',   AREA_NO: '281000',  PACKAGE: 'P3',      EM_DATE: '2023-12-05', A_SYS_QTY: 13,        ASH_TOT: 327,     ASH_ACT: 88,      ASH_REM: '239',     ASH_PRO: 26.9,    ASH_STATUS: 'INPROGRESS', AP_TOT: 10,     AP_ACT: 0,      AP_REM: 10,     AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 133,     BSH_ACT: 0,       BSH_REM: 133,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Water and Firewater Pump Station 2', },
    { CWA_CODE: '281001',   AREA_NO: '281001',  PACKAGE: 'P3',      EM_DATE: '2023-12-05', A_SYS_QTY: 7,         ASH_TOT: 70,      ASH_ACT: 17,      ASH_REM: '53',      ASH_PRO: 24.3,    ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 17,      BSH_ACT: 0,       BSH_REM: 17,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Telecom equipment room Water and Firewater Pump Station2 area', },
    { CWA_CODE: '370100',   AREA_NO: '370100',  PACKAGE: 'P1',      EM_DATE: '2024-06-10', A_SYS_QTY: 14,        ASH_TOT: 303,     ASH_ACT: 1,       ASH_REM: '302',     ASH_PRO: 0.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 69,      BSH_ACT: 3,       BSH_REM: 66,      BSH_PRO: 4.3,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Hexene1 Tank Farm 1', },
    { CWA_CODE: '377100',   AREA_NO: '377100',  PACKAGE: 'P1',      EM_DATE: '2024-08-09', A_SYS_QTY: 22,        ASH_TOT: 890,     ASH_ACT: 3,       ASH_REM: '887',     ASH_PRO: 0.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 141,     BSH_ACT: 3,       BSH_REM: 138,     BSH_PRO: 2.1,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Intermediate Tank Farm 3 3', },
    { CWA_CODE: '381006',   AREA_NO: '381006',  PACKAGE: 'P3',      EM_DATE: '2023-11-30', A_SYS_QTY: 8,         ASH_TOT: 193,     ASH_ACT: 15,      ASH_REM: '178',     ASH_PRO: 7.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 83,      BSH_ACT: 0,       BSH_REM: 83,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Firewater Pump Station B B', },
    { CWA_CODE: '111300',   AREA_NO: '111300',  PACKAGE: 'P1',      EM_DATE: '2024-08-20', A_SYS_QTY: 45,        ASH_TOT: 2721,    ASH_ACT: 76,      ASH_REM: '2645',    ASH_PRO: 2.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 921,     BSH_ACT: 0,       BSH_REM: 921,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Benzene Toluene Battery Limit 745', },
    { CWA_CODE: '111342',   AREA_NO: '111342',  PACKAGE: 'P1',      EM_DATE: '2024-08-10', A_SYS_QTY: 2,         ASH_TOT: 72,      ASH_ACT: 0,       ASH_REM: '72',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 14,      BSH_ACT: 0,       BSH_REM: 14,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Benzene Toluene Sanitary Sewage Sump 745', },
    { CWA_CODE: '111394',   AREA_NO: '111394',  PACKAGE: 'P1',      EM_DATE: '2024-09-30', A_SYS_QTY: 5,         ASH_TOT: 66,      ASH_ACT: 8,       ASH_REM: '58',      ASH_PRO: 12.1,    ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 18,      BSH_ACT: 0,       BSH_REM: 18,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Benzene Toluene Coproduct Operator Shelter 745', },
    { CWA_CODE: '121301',   AREA_NO: '121301',  PACKAGE: 'P2',      EM_DATE: '2024-03-01', A_SYS_QTY: 5,         ASH_TOT: 51,      ASH_ACT: 1,       ASH_REM: '50',      ASH_PRO: 2,       ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 25,      BSH_ACT: 0,       BSH_REM: 25,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Interconnecting Pipe Rack 7350 GPPE', },
    { CWA_CODE: '121303',   AREA_NO: '121303',  PACKAGE: 'P2',      EM_DATE: '2024-05-15', A_SYS_QTY: 5,         ASH_TOT: 255,     ASH_ACT: 28,      ASH_REM: '227',     ASH_PRO: 11,      ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 52,      BSH_ACT: 0,       BSH_REM: 52,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Feed And Utility Area B 7350 GPPE B', },
    { CWA_CODE: '121305',   AREA_NO: '121305',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 2,         ASH_TOT: 110,     ASH_ACT: 4,       ASH_REM: '106',     ASH_PRO: 3.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 22,      BSH_ACT: 0,       BSH_REM: 22,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Thermal Oxidizer Blower Area 7350 GPPE', },
    { CWA_CODE: '121306',   AREA_NO: '121306',  PACKAGE: 'P2',      EM_DATE: '2024-06-01', A_SYS_QTY: 17,        ASH_TOT: 804,     ASH_ACT: 0,       ASH_REM: '804',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 198,     BSH_ACT: 0,       BSH_REM: 198,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Pellet Dryers Classifiers And Surge Bin Area 7350 GPPE', },
    { CWA_CODE: '121308',   AREA_NO: '121308',  PACKAGE: 'P2',      EM_DATE: '2024-06-01', A_SYS_QTY: 17,        ASH_TOT: 1371,    ASH_ACT: 0,       ASH_REM: '1371',    ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 369,     BSH_ACT: 0,       BSH_REM: 369,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Elutriator And Pellet Silos Area 7350 GPPE', },
    { CWA_CODE: '121312',   AREA_NO: '121312',  PACKAGE: 'P2',      EM_DATE: '2024-07-31', A_SYS_QTY: 19,        ASH_TOT: 776,     ASH_ACT: 4,       ASH_REM: '772',     ASH_PRO: 0.5,     ASH_STATUS: 'INPROGRESS', AP_TOT: 9,      AP_ACT: 0,      AP_REM: 9,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 195,     BSH_ACT: 0,       BSH_REM: 195,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 1 7350 GPPE 1', },
    { CWA_CODE: '121323',   AREA_NO: '121323',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 5,         ASH_TOT: 145,     ASH_ACT: 8,       ASH_REM: '137',     ASH_PRO: 5.5,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 44,      BSH_ACT: 0,       BSH_REM: 44,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Granules Conveying System Of Line 2 7350 GPPE 2', },
    { CWA_CODE: '121332',   AREA_NO: '121332',  PACKAGE: 'P2',      EM_DATE: '2024-05-31', A_SYS_QTY: 2,         ASH_TOT: 81,      ASH_ACT: 8,       ASH_REM: '73',      ASH_PRO: 9.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 26,      BSH_ACT: 0,       BSH_REM: 26,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE CHEMICAL STORAGE 7350 GPPE', },
    { CWA_CODE: '121342',   AREA_NO: '121342',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 5,         ASH_TOT: 118,     ASH_ACT: 0,       ASH_REM: '118',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 37,      BSH_ACT: 0,       BSH_REM: 37,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Waste WaterStorm Water Area 7350 GPPE', },
    { CWA_CODE: '121403',   AREA_NO: '121403',  PACKAGE: 'P2',      EM_DATE: '2024-06-18', A_SYS_QTY: 13,        ASH_TOT: 437,     ASH_ACT: 4,       ASH_REM: '433',     ASH_PRO: 0.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 96,      BSH_ACT: 0,       BSH_REM: 96,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Hp Recycle Area 50', },
    { CWA_CODE: '121404',   AREA_NO: '121404',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 12,        ASH_TOT: 321,     ASH_ACT: 0,       ASH_REM: '321',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 70,      BSH_ACT: 0,       BSH_REM: 70,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Purge Gas Area 50', },
    { CWA_CODE: '121405',   AREA_NO: '121405',  PACKAGE: 'P2',      EM_DATE: '2024-06-30', A_SYS_QTY: 28,        ASH_TOT: 959,     ASH_ACT: 0,       ASH_REM: '959',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 343,     BSH_ACT: 0,       BSH_REM: 343,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Extrusion Area 50', },
    { CWA_CODE: '121406',   AREA_NO: '121406',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 8,         ASH_TOT: 410,     ASH_ACT: 0,       ASH_REM: '410',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 162,     BSH_ACT: 0,       BSH_REM: 162,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Compressor Lubrication Cooling Oil 50', },
    { CWA_CODE: '121408',   AREA_NO: '121408',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 9,         ASH_TOT: 437,     ASH_ACT: 0,       ASH_REM: '437',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 145,     BSH_ACT: 0,       BSH_REM: 145,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Chilled Water 50', },
    { CWA_CODE: '121411',   AREA_NO: '121411',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 18,        ASH_TOT: 589,     ASH_ACT: 24,      ASH_REM: '565',     ASH_PRO: 4.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 201,     BSH_ACT: 0,       BSH_REM: 201,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Modifier and Solvent System 50', },
    { CWA_CODE: '121412',   AREA_NO: '121412',  PACKAGE: 'P2',      EM_DATE: '2024-07-31', A_SYS_QTY: 5,         ASH_TOT: 422,     ASH_ACT: 2,       ASH_REM: '420',     ASH_PRO: 0.5,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 99,      BSH_ACT: 0,       BSH_REM: 99,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Vent Gas Treatment Package 50 RTO', },
    { CWA_CODE: '121413',   AREA_NO: '121413',  PACKAGE: 'P2',      EM_DATE: '2024-06-30', A_SYS_QTY: 23,        ASH_TOT: 1335,    ASH_ACT: 50,      ASH_REM: '1285',    ASH_PRO: 3.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 400,     BSH_ACT: 0,       BSH_REM: 400,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Pellet Degassing Silos 50', },
    { CWA_CODE: '121414',   AREA_NO: '121414',  PACKAGE: 'P2',      EM_DATE: '2024-06-30', A_SYS_QTY: 3,         ASH_TOT: 204,     ASH_ACT: 0,       ASH_REM: '204',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 24,      BSH_ACT: 0,       BSH_REM: 24,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Blend And Bagging Silos 50', },
    { CWA_CODE: '121442',   AREA_NO: '121442',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 5,         ASH_TOT: 133,     ASH_ACT: 1,       ASH_REM: '132',     ASH_PRO: 0.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 46,      BSH_ACT: 0,       BSH_REM: 46,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Sump Area 50', },
    { CWA_CODE: '121491',   AREA_NO: '121491',  PACKAGE: 'P2',      EM_DATE: '2024-03-31', A_SYS_QTY: 25,        ASH_TOT: 439,     ASH_ACT: 5,       ASH_REM: '434',     ASH_PRO: 1.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 470,     BSH_ACT: 0,       BSH_REM: 470,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE RIE 3 50', },
    { CWA_CODE: '121901',   AREA_NO: '121901',  PACKAGE: 'P2',      EM_DATE: '2024-03-01', A_SYS_QTY: 6,         ASH_TOT: 132,     ASH_ACT: 0,       ASH_REM: '132',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 32,      BSH_ACT: 0,       BSH_REM: 32,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Battery Limit Pipe Rack 48475', },
    { CWA_CODE: '121902',   AREA_NO: '121902',  PACKAGE: 'P2',      EM_DATE: '2024-06-15', A_SYS_QTY: 7,         ASH_TOT: 334,     ASH_ACT: 1,       ASH_REM: '333',     ASH_PRO: 0.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 81,      BSH_ACT: 0,       BSH_REM: 81,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP TEAL Facility Area 48475 PP', },
    { CWA_CODE: '121904',   AREA_NO: '121904',  PACKAGE: 'P2',      EM_DATE: '2024-05-31', A_SYS_QTY: 19,        ASH_TOT: 901,     ASH_ACT: 12,      ASH_REM: '889',     ASH_PRO: 1.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 291,     BSH_ACT: 0,       BSH_REM: 291,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Pellet Silos Area 48475 PP', },
    { CWA_CODE: '121905',   AREA_NO: '121905',  PACKAGE: 'P2',      EM_DATE: '2024-05-31', A_SYS_QTY: 8,         ASH_TOT: 389,     ASH_ACT: 0,       ASH_REM: '389',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 105,     BSH_ACT: 0,       BSH_REM: 105,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Pellet Elutriation and Bagging Area 48475 PP', },
    { CWA_CODE: '121906',   AREA_NO: '121906',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 3,         ASH_TOT: 280,     ASH_ACT: 1,       ASH_REM: '279',     ASH_PRO: 0.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 2,      AP_ACT: 0,      AP_REM: 2,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 46,      BSH_ACT: 0,       BSH_REM: 46,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Hydrogen Compressor Unit 48475 PP', },
    { CWA_CODE: '121907',   AREA_NO: '121907',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 17,        ASH_TOT: 751,     ASH_ACT: 1,       ASH_REM: '750',     ASH_PRO: 0.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 6,      AP_ACT: 0,      AP_REM: 6,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 211,     BSH_ACT: 0,       BSH_REM: 211,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP OilGrease Storage and Mixing Propylene Dryer Area 48475 PP', },
    { CWA_CODE: '121908',   AREA_NO: '121908',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 18,        ASH_TOT: 481,     ASH_ACT: 8,       ASH_REM: '473',     ASH_PRO: 1.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 123,     BSH_ACT: 0,       BSH_REM: 123,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Oil Treatment Chilled Water and Steam Condensate Drum Area 48475 PP', },
    { CWA_CODE: '121909',   AREA_NO: '121909',  PACKAGE: 'P2',      EM_DATE: '2024-05-31', A_SYS_QTY: 6,         ASH_TOT: 108,     ASH_ACT: 1,       ASH_REM: '107',     ASH_PRO: 0.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 22,      BSH_ACT: 0,       BSH_REM: 22,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Regenerative Thermal Oxidizer Area 48475 PP RTO', },
    { CWA_CODE: '121912',   AREA_NO: '121912',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 14,        ASH_TOT: 450,     ASH_ACT: 5,       ASH_REM: '445',     ASH_PRO: 1.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 119,     BSH_ACT: 0,       BSH_REM: 119,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 1 Catalyst Preparation and Metering Area 48475 PP1', },
    { CWA_CODE: '121921',   AREA_NO: '121921',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 16,        ASH_TOT: 457,     ASH_ACT: 9,       ASH_REM: '448',     ASH_PRO: 2,       ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 110,     BSH_ACT: 0,       BSH_REM: 110,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 2 Propylene Feed Area 48475 PP2', },
    { CWA_CODE: '121922',   AREA_NO: '121922',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 18,        ASH_TOT: 127,     ASH_ACT: 1,       ASH_REM: '126',     ASH_PRO: 0.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 39,      BSH_ACT: 0,       BSH_REM: 39,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 2 Catalyst Preparation and Metering Area 48475 PP2', },
    { CWA_CODE: '121924',   AREA_NO: '121924',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 9,         ASH_TOT: 262,     ASH_ACT: 0,       ASH_REM: '262',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 4,      AP_ACT: 0,      AP_REM: 4,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 51,      BSH_ACT: 0,       BSH_REM: 51,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 2 Polymer Degassing and Granules Drying Area 48475 PP2', },
    { CWA_CODE: '121925',   AREA_NO: '121925',  PACKAGE: 'P2',      EM_DATE: '2024-03-01', A_SYS_QTY: 3,         ASH_TOT: 41,      ASH_ACT: 1,       ASH_REM: '40',      ASH_PRO: 2.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 2,       BSH_ACT: 0,       BSH_REM: 2,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 2 Polymerization Area Pipe Rack 48475 PP2', },
    { CWA_CODE: '121926',   AREA_NO: '121926',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 9,         ASH_TOT: 200,     ASH_ACT: 10,      ASH_REM: '190',     ASH_PRO: 5,       ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 41,      BSH_ACT: 0,       BSH_REM: 41,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 2 TEAL Scrubber and Recycle Gas Compressor Area 48475 PP2TEAL', },
    { CWA_CODE: '121927',   AREA_NO: '121927',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 14,        ASH_TOT: 304,     ASH_ACT: 4,       ASH_REM: '300',     ASH_PRO: 1.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 54,      BSH_ACT: 0,       BSH_REM: 54,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 2 Blowdown and Ethylene Compressor Area 48475 PP2', },
    { CWA_CODE: '121932',   AREA_NO: '121932',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 3,         ASH_TOT: 92,      ASH_ACT: 8,       ASH_REM: '84',      ASH_PRO: 8.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 24,      BSH_ACT: 0,       BSH_REM: 24,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Chemical Storage 48475 PP', },
    { CWA_CODE: '121942',   AREA_NO: '121942',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 3,         ASH_TOT: 120,     ASH_ACT: 0,       ASH_REM: '120',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 33,      BSH_ACT: 0,       BSH_REM: 33,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Waste WaterStorm Water Area 48475 PP', },
    { CWA_CODE: '121992',   AREA_NO: '121992',  PACKAGE: 'P2',      EM_DATE: '2023-12-30', A_SYS_QTY: 16,        ASH_TOT: 249,     ASH_ACT: 8,       ASH_REM: '241',     ASH_PRO: 3.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 6,      AP_ACT: 0,      AP_REM: 6,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 726,     BSH_ACT: 0,       BSH_REM: 726,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Substation 48475 PP', },
    { CWA_CODE: '124700',   AREA_NO: '124700',  PACKAGE: 'P1',      EM_DATE: '2024-06-19', A_SYS_QTY: 22,        ASH_TOT: 1808,    ASH_ACT: 55,      ASH_REM: '1753',    ASH_PRO: 3,       ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 285,     BSH_ACT: 6,       BSH_REM: 279,     BSH_PRO: 2.1,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Propylene Tank Farm', },
    { CWA_CODE: '127742',   AREA_NO: '127742',  PACKAGE: 'P1',      EM_DATE: '2024-08-20', A_SYS_QTY: 5,         ASH_TOT: 167,     ASH_ACT: 0,       ASH_REM: '167',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 49,      BSH_ACT: 0,       BSH_REM: 49,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Butadiene CoProduct Waste Water Sump 27', },
    { CWA_CODE: '127905',   AREA_NO: '127905',  PACKAGE: 'P1',      EM_DATE: '2024-08-30', A_SYS_QTY: 46,        ASH_TOT: 2371,    ASH_ACT: 105,     ASH_REM: '2266',    ASH_PRO: 4.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 2,      AP_ACT: 0,      AP_REM: 2,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 725,     BSH_ACT: 0,       BSH_REM: 725,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Hot Fraction Area 160', },
    { CWA_CODE: '127907',   AREA_NO: '127907',  PACKAGE: 'P1',      EM_DATE: '2024-09-20', A_SYS_QTY: 20,        ASH_TOT: 557,     ASH_ACT: 24,      ASH_REM: '533',     ASH_PRO: 4.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 172,     BSH_ACT: 0,       BSH_REM: 172,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Spent Caustic Area 160', },
    { CWA_CODE: '127908',   AREA_NO: '127908',  PACKAGE: 'P1',      EM_DATE: '2024-09-20', A_SYS_QTY: 21,        ASH_TOT: 344,     ASH_ACT: 8,       ASH_REM: '336',     ASH_PRO: 2.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 125,     BSH_ACT: 0,       BSH_REM: 125,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Steam Condensate Area 160', },
    { CWA_CODE: '127909',   AREA_NO: '127909',  PACKAGE: 'P1',      EM_DATE: '2024-09-30', A_SYS_QTY: 15,        ASH_TOT: 421,     ASH_ACT: 12,      ASH_REM: '409',     ASH_PRO: 2.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 126,     BSH_ACT: 0,       BSH_REM: 126,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Benzene Removal Area 160', },
    { CWA_CODE: '127942',   AREA_NO: '127942',  PACKAGE: 'P1',      EM_DATE: '2024-08-30', A_SYS_QTY: 5,         ASH_TOT: 220,     ASH_ACT: 12,      ASH_REM: '208',     ASH_PRO: 5.5,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 80,      BSH_ACT: 0,       BSH_REM: 80,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Waste Water Sump 160', },
    { CWA_CODE: '150200',   AREA_NO: '150200',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 1,         ASH_TOT: 30,      ASH_ACT: 0,       ASH_REM: '30',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Overall Grading Layout Landscaping Road layout', },
    { CWA_CODE: '150300',   AREA_NO: '150300',  PACKAGE: 'P3',      EM_DATE: '2024-03-10', A_SYS_QTY: 10,        ASH_TOT: 990,     ASH_ACT: 0,       ASH_REM: '990',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 517,     BSH_ACT: 0,       BSH_REM: 517,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Main UG Piping for whole Plant P1P2P3 P1P2P3', },
    { CWA_CODE: '150400',   AREA_NO: '150400',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 1,         ASH_TOT: 32,      ASH_ACT: 0,       ASH_REM: '32',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 15,      BSH_ACT: 0,       BSH_REM: 15,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Fence for all area P1P2P3 P1P2P3', },
    { CWA_CODE: '150401',   AREA_NO: '150401',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 2,         ASH_TOT: 48,      ASH_ACT: 0,       ASH_REM: '48',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 4,       BSH_ACT: 0,       BSH_REM: 4,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Gate and Gate house 1 1', },
    { CWA_CODE: '150402',   AREA_NO: '150402',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 2,         ASH_TOT: 47,      ASH_ACT: 0,       ASH_REM: '47',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1,       BSH_ACT: 0,       BSH_REM: 1,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Gate and Gate house 2 2', },
    { CWA_CODE: '150403',   AREA_NO: '150403',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 2,         ASH_TOT: 47,      ASH_ACT: 0,       ASH_REM: '47',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1,       BSH_ACT: 0,       BSH_REM: 1,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Gate and Gate house 3 3', },
    { CWA_CODE: '150404',   AREA_NO: '150404',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 2,         ASH_TOT: 47,      ASH_ACT: 0,       ASH_REM: '47',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1,       BSH_ACT: 0,       BSH_REM: 1,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Gate and Gate house 4 4', },
    { CWA_CODE: '150405',   AREA_NO: '150405',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 2,         ASH_TOT: 50,      ASH_ACT: 0,       ASH_REM: '50',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 6,       BSH_ACT: 0,       BSH_REM: 6,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Gate and Gate house 5 5', },
    { CWA_CODE: '152000',   AREA_NO: '152000',  PACKAGE: 'P3',      EM_DATE: '2024-02-20', A_SYS_QTY: 22,        ASH_TOT: 1625,    ASH_ACT: 3,       ASH_REM: '1622',    ASH_PRO: 0.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1482,    BSH_ACT: 0,       BSH_REM: 1482,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Central Laboratory and Environmental Monitoring Station', },
    { CWA_CODE: '152004',   AREA_NO: '152004',  PACKAGE: 'P3',      EM_DATE: '2024-02-29', A_SYS_QTY: 1,         ASH_TOT: 1,       ASH_ACT: 0,       ASH_REM: '1',       ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Environmental Monitoring station1 in Central Laboratory 1', },
    { CWA_CODE: '152200',   AREA_NO: '152200',  PACKAGE: 'P3',      EM_DATE: '2024-01-15', A_SYS_QTY: 15,        ASH_TOT: 812,     ASH_ACT: 9,       ASH_REM: '803',     ASH_PRO: 1.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 171,     BSH_ACT: 0,       BSH_REM: 171,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Maintenance Workshop', },
    { CWA_CODE: '152400',   AREA_NO: '152400',  PACKAGE: 'P3',      EM_DATE: '2023-12-20', A_SYS_QTY: 28,        ASH_TOT: 1143,    ASH_ACT: 5,       ASH_REM: '1138',    ASH_PRO: 0.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 570,     BSH_ACT: 0,       BSH_REM: 570,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Chemical Warehouses', },
    { CWA_CODE: '152700',   AREA_NO: '152700',  PACKAGE: 'P3',      EM_DATE: '2024-03-30', A_SYS_QTY: 1,         ASH_TOT: 31,      ASH_ACT: 0,       ASH_REM: '31',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Main plant Dispatch Area', },
    { CWA_CODE: '152801',   AREA_NO: '152801',  PACKAGE: 'P2',      EM_DATE: '2024-07-31', A_SYS_QTY: 16,        ASH_TOT: 1281,    ASH_ACT: 0,       ASH_REM: '1281',    ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 868,     BSH_ACT: 0,       BSH_REM: 868,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PBL No1 Warehouse 1', },
    { CWA_CODE: '152802',   AREA_NO: '152802',  PACKAGE: 'P2',      EM_DATE: '2024-07-31', A_SYS_QTY: 10,        ASH_TOT: 1514,    ASH_ACT: 112,     ASH_REM: '1402',    ASH_PRO: 7.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 970,     BSH_ACT: 0,       BSH_REM: 970,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PBL No2 Warehouse 2', },
    { CWA_CODE: '152803',   AREA_NO: '152803',  PACKAGE: 'P2',      EM_DATE: '2024-03-01', A_SYS_QTY: 6,         ASH_TOT: 217,     ASH_ACT: 0,       ASH_REM: '217',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 73,      BSH_ACT: 0,       BSH_REM: 73,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PBL No1 Package Plant 1', },
    { CWA_CODE: '152804',   AREA_NO: '152804',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 8,         ASH_TOT: 180,     ASH_ACT: 6,       ASH_REM: '174',     ASH_PRO: 3.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 67,      BSH_ACT: 0,       BSH_REM: 67,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PBL No2 Package Plant 2', },
    { CWA_CODE: '166800',   AREA_NO: '166800',  PACKAGE: 'P3',      EM_DATE: '2024-03-30', A_SYS_QTY: 14,        ASH_TOT: 294,     ASH_ACT: 6,       ASH_REM: '288',     ASH_PRO: 2,       ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 92,      BSH_ACT: 0,       BSH_REM: 92,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Ammonia Water Tank Farm', },
    { CWA_CODE: '168000',   AREA_NO: '168000',  PACKAGE: 'P1',      EM_DATE: '2024-08-01', A_SYS_QTY: 25,        ASH_TOT: 933,     ASH_ACT: 6,       ASH_REM: '927',     ASH_PRO: 0.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 219,     BSH_ACT: 4,       BSH_REM: 215,     BSH_PRO: 1.8,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Naphtha Tank Farm', },
    { CWA_CODE: '170000',   AREA_NO: '170000',  PACKAGE: 'P1',      EM_DATE: '2024-06-19', A_SYS_QTY: 2,         ASH_TOT: 229,     ASH_ACT: 9,       ASH_REM: '220',     ASH_PRO: 3.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 100,     BSH_ACT: 0,       BSH_REM: 100,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Pressurized Tank Farm Common Area', },
    { CWA_CODE: '170300',   AREA_NO: '170300',  PACKAGE: 'P1',      EM_DATE: '2024-04-30', A_SYS_QTY: 29,        ASH_TOT: 2500,    ASH_ACT: 44,      ASH_REM: '2456',    ASH_PRO: 1.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 445,     BSH_ACT: 12,      BSH_REM: 433,     BSH_PRO: 2.7,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Pressurized Tank Farm 1 1', },
    { CWA_CODE: '178000',   AREA_NO: '178000',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 18,        ASH_TOT: 368,     ASH_ACT: 0,       ASH_REM: '368',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 107,     BSH_ACT: 0,       BSH_REM: 107,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra LoadingUnloading Station', },
    { CWA_CODE: '178001',   AREA_NO: '178001',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 4,         ASH_TOT: 177,     ASH_ACT: 0,       ASH_REM: '177',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 68,      BSH_ACT: 0,       BSH_REM: 68,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra LoadingUnloading Station Building', },
    { CWA_CODE: '178600',   AREA_NO: '178600',  PACKAGE: 'P3',      EM_DATE: '2024-03-31', A_SYS_QTY: 22,        ASH_TOT: 1044,    ASH_ACT: 9,       ASH_REM: '1035',    ASH_PRO: 0.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 3,      AP_ACT: 0,      AP_REM: 3,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 237,     BSH_ACT: 5,       BSH_REM: 232,     BSH_PRO: 2.1,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Elevated Flare', },
    { CWA_CODE: '178903',   AREA_NO: '178903',  PACKAGE: 'P3',      EM_DATE: '2024-04-01', A_SYS_QTY: 10,        ASH_TOT: 155,     ASH_ACT: 0,       ASH_REM: '155',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 78,      BSH_ACT: 0,       BSH_REM: 78,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Flare Gas Recovery Unit', },
    { CWA_CODE: '178992',   AREA_NO: '178992',  PACKAGE: 'P3',      EM_DATE: '2024-02-28', A_SYS_QTY: 14,        ASH_TOT: 99,      ASH_ACT: 8,       ASH_REM: '91',      ASH_PRO: 8.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 134,     BSH_ACT: 0,       BSH_REM: 134,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Ground Flare substation', },
    { CWA_CODE: '179000',   AREA_NO: '179000',  PACKAGE: 'P3',      EM_DATE: '2023-12-15', A_SYS_QTY: 16,        ASH_TOT: 306,     ASH_ACT: 4,       ASH_REM: '302',     ASH_PRO: 1.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 85,      BSH_ACT: 0,       BSH_REM: 85,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Overall Level1 Pipe Rack 12 12', },
    { CWA_CODE: '180200',   AREA_NO: '180200',  PACKAGE: 'P3',      EM_DATE: '2024-02-29', A_SYS_QTY: 24,        ASH_TOT: 3279,    ASH_ACT: 0,       ASH_REM: '3279',    ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1039,    BSH_ACT: 0,       BSH_REM: 1039,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Cooling Water System 1', },
    { CWA_CODE: '180292',   AREA_NO: '180292',  PACKAGE: 'P3',      EM_DATE: '2023-12-20', A_SYS_QTY: 24,        ASH_TOT: 559,     ASH_ACT: 16,      ASH_REM: '543',     ASH_PRO: 2.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 4,      AP_ACT: 0,      AP_REM: 4,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 394,     BSH_ACT: 0,       BSH_REM: 394,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Cooling Water System1 Substation', },
    { CWA_CODE: '180401',   AREA_NO: '180401',  PACKAGE: 'P1',      EM_DATE: '2023-12-15', A_SYS_QTY: 7,         ASH_TOT: 86,      ASH_ACT: 5,       ASH_REM: '81',      ASH_PRO: 5.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 23,      BSH_ACT: 0,       BSH_REM: 23,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Waste Water Pump Station 1 B Area 1', },
    { CWA_CODE: '180403',   AREA_NO: '180403',  PACKAGE: 'P1',      EM_DATE: '2024-08-09', A_SYS_QTY: 7,         ASH_TOT: 214,     ASH_ACT: 0,       ASH_REM: '214',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 50,      BSH_ACT: 0,       BSH_REM: 50,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Waste Water Pump Station 3 Intermediate Tank Farm 1 3', },
    { CWA_CODE: '180404',   AREA_NO: '180404',  PACKAGE: 'P1',      EM_DATE: '2023-12-20', A_SYS_QTY: 6,         ASH_TOT: 102,     ASH_ACT: 0,       ASH_REM: '102',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 21,      BSH_ACT: 0,       BSH_REM: 21,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Waste Water Pump Station 4 Chemical Warehouse 4', },
    { CWA_CODE: '180600',   AREA_NO: '180600',  PACKAGE: 'P3',      EM_DATE: '2023-12-31', A_SYS_QTY: 12,        ASH_TOT: 972,     ASH_ACT: 16,      ASH_REM: '956',     ASH_PRO: 1.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 333,     BSH_ACT: 0,       BSH_REM: 333,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Storm Water Pump Station and Accident Buffer Basin', },
    { CWA_CODE: '181200',   AREA_NO: '181200',  PACKAGE: 'P3',      EM_DATE: '2023-12-05', A_SYS_QTY: 25,        ASH_TOT: 1229,    ASH_ACT: 324,     ASH_REM: '905',     ASH_PRO: 26.4,    ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 556,     BSH_ACT: 0,       BSH_REM: 556,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra FF Gas Protection Station', },
    { CWA_CODE: '181301',   AREA_NO: '181301',  PACKAGE: 'P3',      EM_DATE: '2024-04-30', A_SYS_QTY: 3,         ASH_TOT: 61,      ASH_ACT: 0,       ASH_REM: '61',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 11,      BSH_ACT: 0,       BSH_REM: 11,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Foam Station 1 Ethylene Cryogenic Tank 1', },
    { CWA_CODE: '181302',   AREA_NO: '181302',  PACKAGE: 'P3',      EM_DATE: '2024-08-09', A_SYS_QTY: 4,         ASH_TOT: 93,      ASH_ACT: 3,       ASH_REM: '90',      ASH_PRO: 3.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 29,      BSH_ACT: 0,       BSH_REM: 29,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Foam Station 2 Intermediate Tank Farm 1 2', },
    { CWA_CODE: '181303',   AREA_NO: '181303',  PACKAGE: 'P3',      EM_DATE: '2024-06-19', A_SYS_QTY: 3,         ASH_TOT: 93,      ASH_ACT: 3,       ASH_REM: '90',      ASH_PRO: 3.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 29,      BSH_ACT: 0,       BSH_REM: 29,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Foam Station 3 Naphtha Tank Farm 3', },
    { CWA_CODE: '181304',   AREA_NO: '181304',  PACKAGE: 'P3',      EM_DATE: '2024-08-09', A_SYS_QTY: 4,         ASH_TOT: 71,      ASH_ACT: 0,       ASH_REM: '71',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 22,      BSH_ACT: 0,       BSH_REM: 22,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Foam Station 4 Naphtha Tank Farm 4', },
    { CWA_CODE: '182400',   AREA_NO: '182400',  PACKAGE: 'P3',      EM_DATE: '2024-01-16', A_SYS_QTY: 19,        ASH_TOT: 945,     ASH_ACT: 33,      ASH_REM: '912',     ASH_PRO: 3.5,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 378,     BSH_ACT: 0,       BSH_REM: 378,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Wastewater PreTreatment Unit', },
    { CWA_CODE: '182492',   AREA_NO: '182492',  PACKAGE: 'P3',      EM_DATE: '2023-12-20', A_SYS_QTY: 21,        ASH_TOT: 363,     ASH_ACT: 16,      ASH_REM: '347',     ASH_PRO: 4.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 2,      AP_ACT: 0,      AP_REM: 2,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 335,     BSH_ACT: 0,       BSH_REM: 335,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Wastewater PreTreatment Unit Substation', },
    { CWA_CODE: '184300',   AREA_NO: '184300',  PACKAGE: 'P1',      EM_DATE: '2024-02-29', A_SYS_QTY: 40,        ASH_TOT: 474,     ASH_ACT: 12,      ASH_REM: '462',     ASH_PRO: 2.5,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 160,     BSH_ACT: 0,       BSH_REM: 160,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Demineralized Water Unit Battery Limit', },
    { CWA_CODE: '184301',   AREA_NO: '184301',  PACKAGE: 'P1',      EM_DATE: '2024-02-29', A_SYS_QTY: 18,        ASH_TOT: 2309,    ASH_ACT: 18,      ASH_REM: '2291',    ASH_PRO: 0.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 741,     BSH_ACT: 0,       BSH_REM: 741,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Demineralized Water Unit Chemical Water Treatment', },
    { CWA_CODE: '121391',   AREA_NO: '121391',  PACKAGE: 'P2',      EM_DATE: '2024-02-16', A_SYS_QTY: 28,        ASH_TOT: 368,     ASH_ACT: 8,       ASH_REM: '360',     ASH_PRO: 2.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 176,     BSH_ACT: 0,       BSH_REM: 176,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE RIE 5 7350 GPPE GPPE RIE 5', },
    { CWA_CODE: '121392',   AREA_NO: '121392',  PACKAGE: 'P2',      EM_DATE: '2023-12-30', A_SYS_QTY: 20,        ASH_TOT: 217,     ASH_ACT: 8,       ASH_REM: '209',     ASH_PRO: 3.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 8,      AP_ACT: 0,      AP_REM: 8,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 158,     BSH_ACT: 0,       BSH_REM: 158,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Substation 7350 GPPE', },
    { CWA_CODE: '121402',   AREA_NO: '121402',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 16,        ASH_TOT: 251,     ASH_ACT: 0,       ASH_REM: '251',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 11,     BP_ACT: 0,      BP_REM: 11,     BP_PRO: 0,      BSH_TOT: 67,      BSH_ACT: 0,       BSH_REM: 67,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Reaction Area 50', },
    { CWA_CODE: '121300',   AREA_NO: '121300',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 86,        ASH_TOT: 6537,    ASH_ACT: 138,     ASH_REM: '6399',    ASH_PRO: 2.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1682,    BSH_ACT: 0,       BSH_REM: 1682,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Battery limit 7350 GPPE', },
    { CWA_CODE: '121302',   AREA_NO: '121302',  PACKAGE: 'P2',      EM_DATE: '2024-05-15', A_SYS_QTY: 31,        ASH_TOT: 1073,    ASH_ACT: 25,      ASH_REM: '1048',    ASH_PRO: 2.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 269,     BSH_ACT: 0,       BSH_REM: 269,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Feed And Utility Area A 7350 GPPE A', },
    { CWA_CODE: '121304',   AREA_NO: '121304',  PACKAGE: 'P2',      EM_DATE: '2024-06-01', A_SYS_QTY: 81,        ASH_TOT: 2809,    ASH_ACT: 16,      ASH_REM: '2793',    ASH_PRO: 0.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 9,      AP_ACT: 0,      AP_REM: 9,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1113,    BSH_ACT: 0,       BSH_REM: 1113,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Extrusion Building 7350 GPPE', },
    { CWA_CODE: '121307',   AREA_NO: '121307',  PACKAGE: 'P2',      EM_DATE: '2024-06-01', A_SYS_QTY: 7,         ASH_TOT: 268,     ASH_ACT: 14,      ASH_REM: '254',     ASH_PRO: 5.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 98,      BSH_ACT: 0,       BSH_REM: 98,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Pellet Transfer Compressor Area 7350 GPPE', },
    { CWA_CODE: '121311',   AREA_NO: '121311',  PACKAGE: 'P2',      EM_DATE: '2024-06-17', A_SYS_QTY: 46,        ASH_TOT: 2171,    ASH_ACT: 6,       ASH_REM: '2165',    ASH_PRO: 0.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 35,     AP_ACT: 0,      AP_REM: 35,     AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 572,     BSH_ACT: 1,       BSH_REM: 571,     BSH_PRO: 0.2,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Reaction And Purge Bin Area Of Line 1 7350 GPPE 1', },
    { CWA_CODE: '121313',   AREA_NO: '121313',  PACKAGE: 'P2',      EM_DATE: '2024-06-01', A_SYS_QTY: 7,         ASH_TOT: 530,     ASH_ACT: 14,      ASH_REM: '516',     ASH_PRO: 2.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 155,     BSH_ACT: 0,       BSH_REM: 155,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Granules Conveying System Of Line 1 7350 GPPE 1', },
    { CWA_CODE: '121321',   AREA_NO: '121321',  PACKAGE: 'P2',      EM_DATE: '2024-07-17', A_SYS_QTY: 40,        ASH_TOT: 1700,    ASH_ACT: 5,       ASH_REM: '1695',    ASH_PRO: 0.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 36,     AP_ACT: 0,      AP_REM: 36,     AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 477,     BSH_ACT: 1,       BSH_REM: 476,     BSH_PRO: 0.2,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Reaction And Purge Bin Area Of Line 2 7350 GPPE 2', },
    { CWA_CODE: '121322',   AREA_NO: '121322',  PACKAGE: 'P2',      EM_DATE: '2024-08-30', A_SYS_QTY: 18,        ASH_TOT: 1035,    ASH_ACT: 4,       ASH_REM: '1031',    ASH_PRO: 0.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 7,      AP_ACT: 0,      AP_REM: 7,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 275,     BSH_ACT: 0,       BSH_REM: 275,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 2 7350 GPPE 2', },
    { CWA_CODE: '121400',   AREA_NO: '121400',  PACKAGE: 'P2',      EM_DATE: '2024-03-01', A_SYS_QTY: 58,        ASH_TOT: 4509,    ASH_ACT: 15,      ASH_REM: '4494',    ASH_PRO: 0.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1353,    BSH_ACT: 0,       BSH_REM: 1353,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Battery Limit 50 LDPE', },
    { CWA_CODE: '121401',   AREA_NO: '121401',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 30,        ASH_TOT: 908,     ASH_ACT: 0,       ASH_REM: '908',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 3,      AP_ACT: 0,      AP_REM: 3,      AP_PRO: 0,      BP_TOT: 7,      BP_ACT: 0,      BP_REM: 7,      BP_PRO: 0,      BSH_TOT: 348,     BSH_ACT: 2,       BSH_REM: 346,     BSH_PRO: 0.6,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Compressor House 50', },
    { CWA_CODE: '121407',   AREA_NO: '121407',  PACKAGE: 'P2',      EM_DATE: '2024-07-31', A_SYS_QTY: 19,        ASH_TOT: 807,     ASH_ACT: 13,      ASH_REM: '794',     ASH_PRO: 1.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 269,     BSH_ACT: 0,       BSH_REM: 269,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Initiator Mixing 50', },
    { CWA_CODE: '121409',   AREA_NO: '121409',  PACKAGE: 'P2',      EM_DATE: '2024-07-31', A_SYS_QTY: 6,         ASH_TOT: 174,     ASH_ACT: 8,       ASH_REM: '166',     ASH_PRO: 4.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 33,      BSH_ACT: 0,       BSH_REM: 33,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Flare KnockOut 50', },
    { CWA_CODE: '121410',   AREA_NO: '121410',  PACKAGE: 'P2',      EM_DATE: '2024-07-31', A_SYS_QTY: 15,        ASH_TOT: 547,     ASH_ACT: 28,      ASH_REM: '519',     ASH_PRO: 5.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 140,     BSH_ACT: 0,       BSH_REM: 140,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Utility Water Area 50', },
    { CWA_CODE: '121415',   AREA_NO: '121415',  PACKAGE: 'P2',      EM_DATE: '2024-07-31', A_SYS_QTY: 3,         ASH_TOT: 55,      ASH_ACT: 0,       ASH_REM: '55',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 4,       BSH_ACT: 0,       BSH_REM: 4,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE At LineLAB 50', },
    { CWA_CODE: '121492',   AREA_NO: '121492',  PACKAGE: 'P2',      EM_DATE: '2024-02-29', A_SYS_QTY: 30,        ASH_TOT: 604,     ASH_ACT: 4,       ASH_REM: '600',     ASH_PRO: 0.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 5,      AP_ACT: 0,      AP_REM: 5,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1338,    BSH_ACT: 0,       BSH_REM: 1338,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'LDPE Substation 50 LDPE', },
    { CWA_CODE: '121900',   AREA_NO: '121900',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 76,        ASH_TOT: 3214,    ASH_ACT: 18,      ASH_REM: '3196',    ASH_PRO: 0.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 800,     BSH_ACT: 0,       BSH_REM: 800,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Battery Limit 48475 PP', },
    { CWA_CODE: '121903',   AREA_NO: '121903',  PACKAGE: 'P2',      EM_DATE: '2024-05-31', A_SYS_QTY: 90,        ASH_TOT: 2436,    ASH_ACT: 18,      ASH_REM: '2418',    ASH_PRO: 0.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 3,      AP_ACT: 0,      AP_REM: 3,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1004,    BSH_ACT: 0,       BSH_REM: 1004,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP Granules Transfer and Extrusion Building 48475 PP', },
    { CWA_CODE: '121911',   AREA_NO: '121911',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 10,        ASH_TOT: 416,     ASH_ACT: 11,      ASH_REM: '405',     ASH_PRO: 2.6,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 105,     BSH_ACT: 0,       BSH_REM: 105,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 1 Propylene Feed and Steam Condensate Area 48475 PP1', },
    { CWA_CODE: '121913',   AREA_NO: '121913',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 17,        ASH_TOT: 846,     ASH_ACT: 9,       ASH_REM: '837',     ASH_PRO: 1.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 3,      AP_ACT: 0,      AP_REM: 3,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 234,     BSH_ACT: 0,       BSH_REM: 234,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 1 Bulk Polymerization Area 48475 PP1', },
    { CWA_CODE: '121914',   AREA_NO: '121914',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 40,        ASH_TOT: 2285,    ASH_ACT: 1,       ASH_REM: '2284',    ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 7,      AP_ACT: 0,      AP_REM: 7,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 542,     BSH_ACT: 0,       BSH_REM: 542,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 1 Gas Phase Reactors Polymer Degassing and Granules Drying Area 48475 PP1', },
    { CWA_CODE: '121916',   AREA_NO: '121916',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 12,        ASH_TOT: 429,     ASH_ACT: 12,      ASH_REM: '417',     ASH_PRO: 2.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 94,      BSH_ACT: 0,       BSH_REM: 94,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 1 TEAL Scrubber and Recycle Gas Compressor Area 48475 PP1TEAL', },
    { CWA_CODE: '121923',   AREA_NO: '121923',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 17,        ASH_TOT: 964,     ASH_ACT: 7,       ASH_REM: '957',     ASH_PRO: 0.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 2,      AP_ACT: 0,      AP_REM: 2,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 230,     BSH_ACT: 0,       BSH_REM: 230,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 2 Bulk Polymerization Area 48475 PP2', },
    { CWA_CODE: '121931',   AREA_NO: '121931',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 1,         ASH_TOT: 53,      ASH_ACT: 0,       ASH_REM: '53',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 2,       BSH_ACT: 0,       BSH_REM: 2,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP New LubeWaste Oil Drum Storage 48475 PP', },
    { CWA_CODE: '121991',   AREA_NO: '121991',  PACKAGE: 'P2',      EM_DATE: '2024-02-16', A_SYS_QTY: 31,        ASH_TOT: 327,     ASH_ACT: 7,       ASH_REM: '320',     ASH_PRO: 2.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 153,     BSH_ACT: 0,       BSH_REM: 153,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP RIE 4 48475 PPRIE 4', },
    { CWA_CODE: '127700',   AREA_NO: '127700',  PACKAGE: 'P1',      EM_DATE: '2024-08-30', A_SYS_QTY: 68,        ASH_TOT: 5556,    ASH_ACT: 69,      ASH_REM: '5487',    ASH_PRO: 1.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 3,      AP_ACT: 3,      AP_REM: 0,      AP_PRO: 100,    BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1644,    BSH_ACT: 0,       BSH_REM: 1644,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Butadiene Battery Limit 27', },
    { CWA_CODE: '127791',   AREA_NO: '127791',  PACKAGE: 'P1',      EM_DATE: '2024-06-30', A_SYS_QTY: 21,        ASH_TOT: 328,     ASH_ACT: 0,       ASH_REM: '328',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 131,     BSH_ACT: 0,       BSH_REM: 131,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Butadiene RIE 2 27 RIE2', },
    { CWA_CODE: '127792',   AREA_NO: '127792',  PACKAGE: 'P1',      EM_DATE: '2024-06-30', A_SYS_QTY: 23,        ASH_TOT: 190,     ASH_ACT: 4,       ASH_REM: '186',     ASH_PRO: 2.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 384,     BSH_ACT: 0,       BSH_REM: 384,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Butadiene Coproduct Substation 27', },
    { CWA_CODE: '127800',   AREA_NO: '127800',  PACKAGE: 'P1',      EM_DATE: '2024-08-30', A_SYS_QTY: 66,        ASH_TOT: 6180,    ASH_ACT: 80,      ASH_REM: '6100',    ASH_PRO: 1.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 4,      AP_ACT: 0,      AP_REM: 4,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1460,    BSH_ACT: 0,       BSH_REM: 1460,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Gasoline Hydrotreating Battery Limit 120', },
    { CWA_CODE: '127900',   AREA_NO: '127900',  PACKAGE: 'P1',      EM_DATE: '2024-09-30', A_SYS_QTY: 109,       ASH_TOT: 3144,    ASH_ACT: 0,       ASH_REM: '3144',    ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 884,     BSH_ACT: 0,       BSH_REM: 884,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Battery Limit 160', },
    { CWA_CODE: '136200',   AREA_NO: '136200',  PACKAGE: 'P1',      EM_DATE: '2024-09-30', A_SYS_QTY: 53,        ASH_TOT: 3238,    ASH_ACT: 126,     ASH_REM: '3112',    ASH_PRO: 3.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 876,     BSH_ACT: 0,       BSH_REM: 876,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'DiIsobutylene Battery Limit 155DIB', },
    { CWA_CODE: '150406',   AREA_NO: '150406',  PACKAGE: 'P3',      EM_DATE: '2024-03-20', A_SYS_QTY: 2,         ASH_TOT: 51,      ASH_ACT: 0,       ASH_REM: '51',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 6,       BSH_ACT: 0,       BSH_REM: 6,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Gate and Gate house 6 6', },
    { CWA_CODE: '127901',   AREA_NO: '127901',  PACKAGE: 'P1',      EM_DATE: '2024-09-30', A_SYS_QTY: 235,       ASH_TOT: 25535,   ASH_ACT: 40,      ASH_REM: '25495',   ASH_PRO: 0.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 7,      AP_ACT: 1,      AP_REM: 6,      AP_PRO: 14.3,   BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 6050,    BSH_ACT: 0,       BSH_REM: 6050,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Furnaces Area 160', },
    { CWA_CODE: '127902',   AREA_NO: '127902',  PACKAGE: 'P1',      EM_DATE: '2024-09-10', A_SYS_QTY: 73,        ASH_TOT: 2971,    ASH_ACT: 164,     ASH_REM: '2807',    ASH_PRO: 5.5,     ASH_STATUS: 'INPROGRESS', AP_TOT: 1,      AP_ACT: 1,      AP_REM: 0,      AP_PRO: 100,    BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 901,     BSH_ACT: 2,       BSH_REM: 899,     BSH_PRO: 0.2,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Quench Area 160', },
    { CWA_CODE: '127903',   AREA_NO: '127903',  PACKAGE: 'P1',      EM_DATE: '2024-08-20', A_SYS_QTY: 90,        ASH_TOT: 4934,    ASH_ACT: 207,     ASH_REM: '4727',    ASH_PRO: 4.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 6,      AP_ACT: 0,      AP_REM: 6,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1425,    BSH_ACT: 0,       BSH_REM: 1425,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Compression Area 160', },
    { CWA_CODE: '127904',   AREA_NO: '127904',  PACKAGE: 'P1',      EM_DATE: '2024-08-30', A_SYS_QTY: 82,        ASH_TOT: 3928,    ASH_ACT: 86,      ASH_REM: '3842',    ASH_PRO: 2.2,     ASH_STATUS: 'INPROGRESS', AP_TOT: 6,      AP_ACT: 0,      AP_REM: 6,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1026,    BSH_ACT: 0,       BSH_REM: 1026,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins Cold Fraction Area 160', },
    { CWA_CODE: '127906',   AREA_NO: '127906',  PACKAGE: 'P1',      EM_DATE: '2024-09-20', A_SYS_QTY: 6,         ASH_TOT: 221,     ASH_ACT: 1,       ASH_REM: '220',     ASH_PRO: 0.5,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 40,      BSH_ACT: 0,       BSH_REM: 40,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins PSA Unit 160 PSA', },
    { CWA_CODE: '127982',   AREA_NO: '127982',  PACKAGE: 'P1',      EM_DATE: '2024-06-30', A_SYS_QTY: 17,        ASH_TOT: 211,     ASH_ACT: 6,       ASH_REM: '205',     ASH_PRO: 2.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 6,      AP_ACT: 0,      AP_REM: 6,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 277,     BSH_ACT: 0,       BSH_REM: 277,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins 2nd Substation 160', },
    { CWA_CODE: '127991',   AREA_NO: '127991',  PACKAGE: 'P1',      EM_DATE: '2024-06-30', A_SYS_QTY: 23,        ASH_TOT: 580,     ASH_ACT: 5,       ASH_REM: '575',     ASH_PRO: 0.9,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 322,     BSH_ACT: 0,       BSH_REM: 322,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins RIE1 160 RIE1', },
    { CWA_CODE: '127992',   AREA_NO: '127992',  PACKAGE: 'P1',      EM_DATE: '2024-05-01', A_SYS_QTY: 21,        ASH_TOT: 238,     ASH_ACT: 2,       ASH_REM: '236',     ASH_PRO: 0.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 3,      AP_ACT: 0,      AP_REM: 3,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 381,     BSH_ACT: 0,       BSH_REM: 381,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Olefins 1st Substation 160', },
    { CWA_CODE: '151000',   AREA_NO: '151000',  PACKAGE: 'P3',      EM_DATE: '2024-06-30', A_SYS_QTY: 4,         ASH_TOT: 95,      ASH_ACT: 0,       ASH_REM: '95',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 41,      BSH_ACT: 0,       BSH_REM: 41,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Common Area of Management Facilities B B', },
    { CWA_CODE: '152014',   AREA_NO: '152014',  PACKAGE: 'P3',      EM_DATE: '2024-02-29', A_SYS_QTY: 1,         ASH_TOT: 2,       ASH_ACT: 0,       ASH_REM: '2',       ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 4,       BSH_ACT: 0,       BSH_REM: 4,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Environmental Monitoring station2 in Central Laboratory 2', },
    { CWA_CODE: '152300',   AREA_NO: '152300',  PACKAGE: 'P3',      EM_DATE: '2023-12-20', A_SYS_QTY: 8,         ASH_TOT: 234,     ASH_ACT: 7,       ASH_REM: '227',     ASH_PRO: 3,       ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 137,     BSH_ACT: 0,       BSH_REM: 137,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Warehouse Management Room and Spare Parts Warehouse', },
    { CWA_CODE: '152800',   AREA_NO: '152800',  PACKAGE: 'P2',      EM_DATE: '2024-03-31', A_SYS_QTY: 1,         ASH_TOT: 37,      ASH_ACT: 0,       ASH_REM: '37',      ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 2,       BSH_ACT: 0,       BSH_REM: 2,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PBL PBL Battery Limit', },
    { CWA_CODE: '152805',   AREA_NO: '152805',  PACKAGE: 'P2',      EM_DATE: '2024-05-04', A_SYS_QTY: 7,         ASH_TOT: 153,     ASH_ACT: 3,       ASH_REM: '150',     ASH_PRO: 2,       ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 57,      BSH_ACT: 0,       BSH_REM: 57,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PBL Auxiliary Facility', },
    { CWA_CODE: '154000',   AREA_NO: '154000',  PACKAGE: 'P3',      EM_DATE: '2023-12-31', A_SYS_QTY: 23,        ASH_TOT: 6257,    ASH_ACT: 3,       ASH_REM: '6254',    ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 3118,    BSH_ACT: 0,       BSH_REM: 3118,    BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Administration Office Building B B', },
    { CWA_CODE: '154001',   AREA_NO: '154001',  PACKAGE: 'P3',      EM_DATE: '2023-11-15', A_SYS_QTY: 1,         ASH_TOT: 1,       ASH_ACT: 0,       ASH_REM: '1',       ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Connecting Corridor Overhead Bridge', },
    { CWA_CODE: '170091',   AREA_NO: '170091',  PACKAGE: 'P1',      EM_DATE: '2024-01-31', A_SYS_QTY: 10,        ASH_TOT: 248,     ASH_ACT: 1,       ASH_REM: '247',     ASH_PRO: 0.4,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 61,      BSH_ACT: 0,       BSH_REM: 61,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra RIE 7 RIE 7', },
    { CWA_CODE: '170092',   AREA_NO: '170092',  PACKAGE: 'P1',      EM_DATE: '2024-01-31', A_SYS_QTY: 18,        ASH_TOT: 156,     ASH_ACT: 2,       ASH_REM: '154',     ASH_PRO: 1.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 2,      AP_ACT: 0,      AP_REM: 2,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 194,     BSH_ACT: 0,       BSH_REM: 194,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Tank Farm 1st Substation', },
    { CWA_CODE: '170100',   AREA_NO: '170100',  PACKAGE: 'P1',      EM_DATE: '2024-04-20', A_SYS_QTY: 36,        ASH_TOT: 3781,    ASH_ACT: 0,       ASH_REM: '3781',    ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 1,      AP_ACT: 0,      AP_REM: 1,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 652,     BSH_ACT: 11,      BSH_REM: 641,     BSH_PRO: 1.7,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Ethylene Cryogenic Tank Farm', },
    { CWA_CODE: '170800',   AREA_NO: '170800',  PACKAGE: 'P1',      EM_DATE: '2024-04-30', A_SYS_QTY: 33,        ASH_TOT: 2078,    ASH_ACT: 17,      ASH_REM: '2061',    ASH_PRO: 0.8,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 371,     BSH_ACT: 6,       BSH_REM: 365,     BSH_PRO: 1.6,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Mogas Tank Farm', },
    { CWA_CODE: '177100',   AREA_NO: '177100',  PACKAGE: 'P1',      EM_DATE: '2024-06-19', A_SYS_QTY: 33,        ASH_TOT: 2371,    ASH_ACT: 6,       ASH_REM: '2365',    ASH_PRO: 0.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 377,     BSH_ACT: 3,       BSH_REM: 374,     BSH_PRO: 0.8,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Intermediate Tank Farm 1 1', },
    { CWA_CODE: '178900',   AREA_NO: '178900',  PACKAGE: 'P3',      EM_DATE: '2024-04-20', A_SYS_QTY: 53,        ASH_TOT: 5912,    ASH_ACT: 5,       ASH_REM: '5907',    ASH_PRO: 0.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 1169,    BSH_ACT: 1,       BSH_REM: 1168,    BSH_PRO: 0.1,     CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Ground Flare', },
    { CWA_CODE: '121915',   AREA_NO: '121915',  PACKAGE: 'P2',      EM_DATE: '2024-03-01', A_SYS_QTY: 2,         ASH_TOT: 32,      ASH_ACT: 1,       ASH_REM: '31',      ASH_PRO: 3.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 0,       BSH_ACT: 0,       BSH_REM: 0,       BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 1 Polymerization Area Pipe Rack 48475 PP1', },
    { CWA_CODE: '121917',   AREA_NO: '121917',  PACKAGE: 'P2',      EM_DATE: '2024-07-01', A_SYS_QTY: 8,         ASH_TOT: 231,     ASH_ACT: 4,       ASH_REM: '227',     ASH_PRO: 1.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 40,      BSH_ACT: 0,       BSH_REM: 40,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'PP CPP 1 Blowdown Area 48475 PP1', },
    { CWA_CODE: '179100',   AREA_NO: '179100',  PACKAGE: 'P3',      EM_DATE: '2024-03-30', A_SYS_QTY: 53,        ASH_TOT: 3184,    ASH_ACT: 4,       ASH_REM: '3180',    ASH_PRO: 0.1,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 101,     BSH_ACT: 2,       BSH_REM: 99,      BSH_PRO: 2,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Overall Level1 Pipe Rack 3456 7 34567', },
    { CWA_CODE: '179600',   AREA_NO: '179600',  PACKAGE: 'P3',      EM_DATE: '2024-08-09', A_SYS_QTY: 13,        ASH_TOT: 186,     ASH_ACT: 0,       ASH_REM: '186',     ASH_PRO: 0,       ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 21,      BSH_ACT: 0,       BSH_REM: 21,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Vapor Gas Recovery Unit', },
    { CWA_CODE: '180402',   AREA_NO: '180402',  PACKAGE: 'P1',      EM_DATE: '2024-06-10', A_SYS_QTY: 7,         ASH_TOT: 140,     ASH_ACT: 1,       ASH_REM: '139',     ASH_PRO: 0.7,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 29,      BSH_ACT: 0,       BSH_REM: 29,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Waste Water Pump Station 2 Hexene1 Tank Farm 2', },
    { CWA_CODE: '181000',   AREA_NO: '181000',  PACKAGE: 'P3',      EM_DATE: '2023-12-15', A_SYS_QTY: 19,        ASH_TOT: 613,     ASH_ACT: 100,     ASH_REM: '513',     ASH_PRO: 16.3,    ASH_STATUS: 'INPROGRESS', AP_TOT: 14,     AP_ACT: 10,     AP_REM: 4,      AP_PRO: 71.4,   BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 231,     BSH_ACT: 0,       BSH_REM: 231,     BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Water and Firewater Pump Station 1', },
    { CWA_CODE: '181001',   AREA_NO: '181001',  PACKAGE: 'P3',      EM_DATE: '2023-12-15', A_SYS_QTY: 6,         ASH_TOT: 53,      ASH_ACT: 20,      ASH_REM: '33',      ASH_PRO: 37.7,    ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 17,      BSH_ACT: 0,       BSH_REM: 17,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Telecom equipment room Water and Firewater Pump Station1 area', },
    { CWA_CODE: '182200',   AREA_NO: '182200',  PACKAGE: 'P3',      EM_DATE: '2023-12-30', A_SYS_QTY: 11,        ASH_TOT: 225,     ASH_ACT: 21,      ASH_REM: '204',     ASH_PRO: 9.3,     ASH_STATUS: 'INPROGRESS', AP_TOT: 0,      AP_ACT: 0,      AP_REM: 0,      AP_PRO: 0,      BP_TOT: 0,      BP_ACT: 0,      BP_REM: 0,      BP_PRO: 0,      BSH_TOT: 53,      BSH_ACT: 0,       BSH_REM: 53,      BSH_PRO: 0,       CP_TOT: 0,      CP_ACT: 0,      CP_REM: 0,      CP_PRO: 0,      CWA_DESC: 'Infra Hazardous waste temporary warehouse', },

  ],

  Queries: {

    SQL1: [ // Timeline
      {
        X:                      40,
        Y:                      800,
        LENGTH:                 1200,
        CUTOFF:                 '2023-10-25',
        START:                  '2023-02-15', 
        END:                    '2024-12-30',
        REF_DATE:               'EM_DATE', // <---
        FONT:                   'roboto',

        MONTH_HEIGHT:           20,
        MONTH_LINE:             15,
        MONTH_STROKE_WIDTH:     0.5,
        MONTH_STROKE_COLOR:     '#BCBCBC',
        MONTH_TEXT_SIZE:        10,
        MONTH_TEXT_COLOR:       '#333',

        YEAR_HEIGHT:            16,
        YEAR_LINE:              20,
        YEAR_STROKE_WIDTH:      0.5,
        YEAR_STROKE_COLOR:      '#BCBCBC',
        YEAR_TEXT_SIZE:         11,
        YEAR_TEXT_COLOR:        '#000',
      }
    ],

    
    SQL2: [ 
      {
        TITLE_MAIN:             'Skyline Chart for A Checksheet',
        TITLE_SUB:              'Status A Checksheet for System Completion',
        LEGEND_TYPE:            'CUBE', // (CUBE / DONUT)
        REF_BOX:                'CWA_CODE', // <---
        REF_STATUS:             'ASH_STATUS', // <---

        BOX_FONT:               'roboto',
        BOX_HEIGHT:             20,
        BOX_GAP:                2, 
        BOX_RADIUS:             2,
        BOX_TEXT_SIZE:          10.5,
        BOX_MAX_LEN:            15,

        SKY_HOVER_SCALE:        1.4,
        SKY_NAME:               'None       / In-Progress / Turn-Over / Complete   ',
        SKY_STATUS:             'NOTSTARTED / INPROGRESS  / TURNOVER  / COMPLETED  ',
        SKY_TYPE:               '           / ASH_PRO     /           /            ',
        SKY_FONT_WEIGHT:        'regular    / regular     / regular   / regular    ',
        SKY_FONT_COLOR:         '#000       / #000        / #000      / #000       ',
        SKY_STROKE_WEIGHT:      '0.5        / 0.5         / 0.5       / 0.5        ',
        SKY_STROKE_COLOR:       '#bcbcbc    / #bcbcbc     / #bcbcbc   / #bcbcbc    ',
        SKY_COLOR:              '#fff       / #FEED57     / #83D2F5   / #82B22E    ',
        SKY_OPACITY:            '1          / 1           / 1         / 1          ',

        // Search
        SEARCH_X:               50,
        SEARCH_Y:               210.5,
        SEARCH_VISIBLE:         'Y',
        SEARCH_NOTE:            'Enter the KMS/LMS number'
      }
    ],

    
    SQL3: [ // Legends 
      {
        LEGEND_VISIBLE:          'Y',
        LEGEND_X:                50,
        LEGEND_Y:                100,
        LEGEND_WIDTH:            80,       
        LEGEND_STROKE_SIZE:      6,
        LEGEND_STROKE_COLOR:     '#bcbcbc',
        LEGEND_OPACITY:          1,
        LEGEND_RADIUS:           30,
        LEGEND_TITLE_SIZE:       11,
        LEGEND_TITLE_COLOR:      '#757575',
        LEGEND_VALUE_SIZE:       13,
        LEGEND_NAME:             'Total   / Not-Started / In-Progress / Turn-Over / Completed / Completed2 ',
        LEGEND_STATUS:           'TOTAL   / NOTSTARTED  / INPROGRESS  / TURNOVER  / COMPLETED / COMPLETED2 ', // Do not change 'TOTAL'
        LEGEND_CHART_COLOR:      '#F7BACF / #BDBCBC     / #FFDA00     / #83D2F5   / #82B22E   / red   ',
        LEGEND_TEXT_COLOR:       '#F7BACF / #BDBCBC     / #FFDA00     / #83D2F5   / #82B22E   / #82B22E   ',

      }
    ],

    
    SQL4: [ // Detail infomation Box
      {
        DETAIL_VISIBLE:           'Y',
        DETAIL_ONOFF:             'OFF',
        DETAIL_X:                 50,
        DETAIL_Y:                 300,
        DETAIL_WIDTH:             300,
        DETAIL_TEXT_SIZE:         10,
        DETAIL_LINEHEIGHT:        18,
        
        DETAIL_CODE:              ' PACKAGE  / EM_DATE   / CWA_DESC     / ASH       / BSH       / AP        / BP       ',
        DETAIL_NAME:              ' Package: / EM-Date:  / Description: / A-Sheet:  / B-Sheet:  / Punch-A:  / Punch-B: ',
        DETAIL_TYPE:              ' TEXT     / TEXT      / TRIM         / BAR       / BAR       / BAR       / BAR      ',
        DETAIL_VALUE_COLOR:       ' #44A9DF  / gray      / gray         / #83D2F5   / #83D2F5   / #F7BACF   / #F7BACF  ',
        DETAIL_TITLE_WIDTH:       50,
        DETAIL_TITLE_COLOR:       'gray',
      }
    ],




    
  }
}