
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },


  DataItems: [
    { CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Hull',    DISC: 'E&I',        PE_WK: 0.4,  PL_WK: 1.9,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.3, DL_WK: -1.9, PE_CUM: 97.7, PL_CUM: 89.6, AC_CUM: 95.5, FC_CUM: 95.5, DE_CUM: -2.2,  DL_CUM: 6, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 100,  FC_CUM: 100,  DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 0,    PL_WK: 0.2,  AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: -0.2, PE_CUM: 100,  PL_CUM: 99.7, AC_CUM: 99.7, FC_CUM: 99.7, DE_CUM: -0.3,  DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Hull',    DISC: 'Overall',    PE_WK: 1.3,  PL_WK: 1.7,  AC_WK: 0.7,  FC_WK: 0.7,  DE_WK: -0.6, DL_WK: -1,   PE_CUM: 92.7, PL_CUM: 85.7, AC_CUM: 86,   FC_CUM: 86,   DE_CUM: -6.8,  DL_CUM: 0.3, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Hull',    DISC: 'Piping',     PE_WK: 2,    PL_WK: 2.8,  AC_WK: 0,    FC_WK: 0,    DE_WK: -2,   DL_WK: -2.8, PE_CUM: 98.3, PL_CUM: 81.2, AC_CUM: 94.8, FC_CUM: 94.8, DE_CUM: -3.5,  DL_CUM: 13.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Hull',    DISC: 'Process',    PE_WK: 0,    PL_WK: 0.3,  AC_WK: 0.2,  FC_WK: 0.2,  DE_WK: 0.1,  DL_WK: -0.2, PE_CUM: 98.3, PL_CUM: 96.4, AC_CUM: 97.7, FC_CUM: 97.7, DE_CUM: -0.6,  DL_CUM: 1.3, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Hull',    DISC: 'Structural', PE_WK: 1.7,  PL_WK: 1.7,  AC_WK: 1.3,  FC_WK: 1.3,  DE_WK: -0.4, DL_WK: -0.4, PE_CUM: 87,   PL_CUM: 82,   AC_CUM: 75.7, FC_CUM: 75.7, DE_CUM: -11.4, DL_CUM: -6.3, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'LQ',      DISC: 'E&I',        PE_WK: 1,    PL_WK: 0.4,  AC_WK: 0.4,  FC_WK: 0.4,  DE_WK: -0.6, DL_WK: 0,    PE_CUM: 86.8, PL_CUM: 86.3, AC_CUM: 80.5, FC_CUM: 80.5, DE_CUM: -6.3,  DL_CUM: -5.8, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'LQ',      DISC: 'HVAC/Arch.', PE_WK: 3.2,  PL_WK: 0.6,  AC_WK: 3.4,  FC_WK: 3.4,  DE_WK: 0.2,  DL_WK: 2.8,  PE_CUM: 95,   PL_CUM: 63,   AC_CUM: 88.2, FC_CUM: 88.2, DE_CUM: -6.8,  DL_CUM: 25.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'LQ',      DISC: 'Mechanical', PE_WK: null, PL_WK: null, AC_WK: null, FC_WK: null, DE_WK: null, DL_WK: null, PE_CUM: null, PL_CUM: null, AC_CUM: null, FC_CUM: null, DE_CUM: null,  DL_CUM: null, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'LQ',      DISC: 'Overall',    PE_WK: 1.9,  PL_WK: 0.4,  AC_WK: 1.7,  FC_WK: 1.7,  DE_WK: -0.2, DL_WK: 1.3,  PE_CUM: 90.5, PL_CUM: 77.3, AC_CUM: 84.3, FC_CUM: 84.3, DE_CUM: -6.2,  DL_CUM: 7.1, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'LQ',      DISC: 'Piping',     PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 58.2, PL_CUM: 50.1, AC_CUM: 55.4, FC_CUM: 55.4, DE_CUM: -2.8,  DL_CUM: 5.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'LQ',      DISC: 'Process',    PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 98,   PL_CUM: 54.3, AC_CUM: 85,   FC_CUM: 85,   DE_CUM: -13,   DL_CUM: 30.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'LQ',      DISC: 'Structural', PE_WK: 1.5,  PL_WK: 0,    AC_WK: 1.6,  FC_WK: 1.6,  DE_WK: 0.1,  DL_WK: 1.6,  PE_CUM: 88.9, PL_CUM: 91.2, AC_CUM: 85.3, FC_CUM: 85.3, DE_CUM: -3.5,  DL_CUM: -5.9, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Overall', DISC: 'E&I',        PE_WK: 1.3,  PL_WK: 1.4,  AC_WK: 0.6,  FC_WK: 0.6,  DE_WK: -0.7, DL_WK: -0.8, PE_CUM: 84.4, PL_CUM: 75.7, AC_CUM: 78.3, FC_CUM: 78.3, DE_CUM: -6.1,  DL_CUM: 2.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 3,    PL_WK: 0.7,  AC_WK: 3.1,  FC_WK: 3.1,  DE_WK: 0.1,  DL_WK: 2.4,  PE_CUM: 93.7, PL_CUM: 64.7, AC_CUM: 86.9, FC_CUM: 86.9, DE_CUM: -6.8,  DL_CUM: 22.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Overall', DISC: 'Mechanical', PE_WK: 0.4,  PL_WK: 0.5,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.3, DL_WK: -0.4, PE_CUM: 93.1, PL_CUM: 91.6, AC_CUM: 99,   FC_CUM: 99,   DE_CUM: 5.9,   DL_CUM: 7.4, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Overall', DISC: 'Overall',    PE_WK: 1.5,  PL_WK: 1.5,  AC_WK: 0.6,  FC_WK: 0.6,  DE_WK: -0.9, DL_WK: -0.9, PE_CUM: 86.2, PL_CUM: 75.3, AC_CUM: 78.1, FC_CUM: 78.1, DE_CUM: -8.2,  DL_CUM: 2.8, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Overall', DISC: 'Piping',     PE_WK: 1.8,  PL_WK: 1.9,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -1.7, DL_WK: -1.9, PE_CUM: 85.3, PL_CUM: 76,   AC_CUM: 84.9, FC_CUM: 84.9, DE_CUM: -0.4,  DL_CUM: 8.9, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Overall', DISC: 'Process',    PE_WK: 0.8,  PL_WK: 0.8,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.7, DL_WK: -0.7, PE_CUM: 91.4, PL_CUM: 81.6, AC_CUM: 86.7, FC_CUM: 86.7, DE_CUM: -4.7,  DL_CUM: 5.1, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Overall', DISC: 'Structural', PE_WK: 1.9,  PL_WK: 1.9,  AC_WK: 0.9,  FC_WK: 0.9,  DE_WK: -1,   DL_WK: -1,   PE_CUM: 83.9, PL_CUM: 69.3, AC_CUM: 63.6, FC_CUM: 63.6, DE_CUM: -20.3, DL_CUM: -5.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Topside', DISC: 'E&I',        PE_WK: 1.7,  PL_WK: 1.6,  AC_WK: 0.8,  FC_WK: 0.8,  DE_WK: -0.8, DL_WK: -0.8, PE_CUM: 80.2, PL_CUM: 69.1, AC_CUM: 73.2, FC_CUM: 73.2, DE_CUM: -7.1,  DL_CUM: 4.1, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 1.4,  PL_WK: 1.4,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -1.3, DL_WK: -1.3, PE_CUM: 72.1, PL_CUM: 72.1, AC_CUM: 61.5, FC_CUM: 61.5, DE_CUM: -10.5, DL_CUM: -10.5, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Topside', DISC: 'Mechanical', PE_WK: 0.6,  PL_WK: 0.7,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.5, DL_WK: -0.6, PE_CUM: 89,   PL_CUM: 86.8, AC_CUM: 98.6, FC_CUM: 98.6, DE_CUM: 9.6,   DL_CUM: 11.9, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Topside', DISC: 'Overall',    PE_WK: 1.6,  PL_WK: 1.6,  AC_WK: 0.4,  FC_WK: 0.4,  DE_WK: -1.2, DL_WK: -1.2, PE_CUM: 81.5, PL_CUM: 68.6, AC_CUM: 72.1, FC_CUM: 72.1, DE_CUM: -9.4,  DL_CUM: 3.5, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Topside', DISC: 'Piping',     PE_WK: 1.6,  PL_WK: 1.5,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -1.6, DL_WK: -1.4, PE_CUM: 79.3, PL_CUM: 73.6, AC_CUM: 80.3, FC_CUM: 80.3, DE_CUM: 1,     DL_CUM: 6.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Topside', DISC: 'Process',    PE_WK: 1.2,  PL_WK: 1.1,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -1,   DL_WK: -0.9, PE_CUM: 88.3, PL_CUM: 76.8, AC_CUM: 82.4, FC_CUM: 82.4, DE_CUM: -5.9,  DL_CUM: 5.5, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Detail Engineering', AREA: 'Topside', DISC: 'Structural', PE_WK: 2.2,  PL_WK: 2.4,  AC_WK: 0.4,  FC_WK: 0.4,  DE_WK: -1.8, DL_WK: -2,   PE_CUM: 79.5, PL_CUM: 51.5, AC_CUM: 46.4, FC_CUM: 46.4, DE_CUM: -33.1, DL_CUM: -5, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Hull',    DISC: 'E&I',        PE_WK: 0.3,  PL_WK: 1.5,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.2, DL_WK: -1.5, PE_CUM: 76,   PL_CUM: 69.6, AC_CUM: 74.3, FC_CUM: 74.3, DE_CUM: -1.7,  DL_CUM: 4.6, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 64,   PL_CUM: 64,   AC_CUM: 64,   FC_CUM: 64,   DE_CUM: -0.1,  DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 0,    PL_WK: 0.2,  AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: -0.2, PE_CUM: 88.2, PL_CUM: 87.9, AC_CUM: 87.9, FC_CUM: 87.9, DE_CUM: -0.3,  DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Hull',    DISC: 'Overall',    PE_WK: 1.5,  PL_WK: 1.5,  AC_WK: 0.5,  FC_WK: 0.5,  DE_WK: -0.9, DL_WK: -0.9, PE_CUM: 73.7, PL_CUM: 67.4, AC_CUM: 67.2, FC_CUM: 67.2, DE_CUM: -6.5,  DL_CUM: -0.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Hull',    DISC: 'Piping',     PE_WK: 1.6,  PL_WK: 2.3,  AC_WK: 0,    FC_WK: 0,    DE_WK: -1.7, DL_WK: -2.3, PE_CUM: 79.9, PL_CUM: 66,   AC_CUM: 77.1, FC_CUM: 77.1, DE_CUM: -2.8,  DL_CUM: 11.1, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Hull',    DISC: 'Process',    PE_WK: 0,    PL_WK: 0.3,  AC_WK: 0.2,  FC_WK: 0.2,  DE_WK: 0.1,  DL_WK: -0.2, PE_CUM: 98.3, PL_CUM: 96.4, AC_CUM: 97.7, FC_CUM: 97.7, DE_CUM: -0.6,  DL_CUM: 1.3, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Hull',    DISC: 'Structural', PE_WK: 2.1,  PL_WK: 1.5,  AC_WK: 1,    FC_WK: 1,    DE_WK: -1.1, DL_WK: -0.6, PE_CUM: 66.4, PL_CUM: 61.1, AC_CUM: 55.8, FC_CUM: 55.8, DE_CUM: -10.6, DL_CUM: -5.3, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'LQ',      DISC: 'E&I',        PE_WK: 0.9,  PL_WK: 0.4,  AC_WK: 0.3,  FC_WK: 0.3,  DE_WK: -0.5, DL_WK: 0,    PE_CUM: 76.2, PL_CUM: 75.7, AC_CUM: 70.6, FC_CUM: 70.6, DE_CUM: -5.5,  DL_CUM: -5.1, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'LQ',      DISC: 'HVAC/Arch.', PE_WK: 2.9,  PL_WK: 0.5,  AC_WK: 2.8,  FC_WK: 2.8,  DE_WK: -0.1, DL_WK: 2.3,  PE_CUM: 80.2, PL_CUM: 52.8, AC_CUM: 73.9, FC_CUM: 73.9, DE_CUM: -6.3,  DL_CUM: 21.1, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'LQ',      DISC: 'Mechanical', PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'LQ',      DISC: 'Overall',    PE_WK: 1.7,  PL_WK: 0.4,  AC_WK: 1.4,  FC_WK: 1.4,  DE_WK: -0.3, DL_WK: 1,    PE_CUM: 71.1, PL_CUM: 60.3, AC_CUM: 65.9, FC_CUM: 65.9, DE_CUM: -5.3,  DL_CUM: 5.6, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'LQ',      DISC: 'Piping',     PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 10.3, PL_CUM: 8.8,  AC_CUM: 9.8,  FC_CUM: 9.8,  DE_CUM: -0.5,  DL_CUM: 0.9, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'LQ',      DISC: 'Process',    PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 98,   PL_CUM: 54.3, AC_CUM: 85,   FC_CUM: 85,   DE_CUM: -13,   DL_CUM: 30.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'LQ',      DISC: 'Structural', PE_WK: 1.9,  PL_WK: 0.1,  AC_WK: 1.4,  FC_WK: 1.4,  DE_WK: -0.5, DL_WK: 1.3,  PE_CUM: 70.4, PL_CUM: 70.4, AC_CUM: 66.2, FC_CUM: 66.2, DE_CUM: -4.2,  DL_CUM: -4.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Overall', DISC: 'E&I',        PE_WK: 1,    PL_WK: 1.1,  AC_WK: 0.5,  FC_WK: 0.5,  DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 66.7, PL_CUM: 59.8, AC_CUM: 61.9, FC_CUM: 61.9, DE_CUM: -4.8,  DL_CUM: 2.1, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 2.7,  PL_WK: 0.6,  AC_WK: 2.5,  FC_WK: 2.5,  DE_WK: -0.1, DL_WK: 2,    PE_CUM: 77.4, PL_CUM: 53.1, AC_CUM: 71.2, FC_CUM: 71.2, DE_CUM: -6.1,  DL_CUM: 18.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Overall', DISC: 'Mechanical', PE_WK: 0.3,  PL_WK: 0.4,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.2, DL_WK: -0.3, PE_CUM: 72.3, PL_CUM: 71.1, AC_CUM: 76.9, FC_CUM: 76.9, DE_CUM: 4.6,   DL_CUM: 5.8, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Overall', DISC: 'Overall',    PE_WK: 1.4,  PL_WK: 1.3,  AC_WK: 0.5,  FC_WK: 0.5,  DE_WK: -0.9, DL_WK: -0.8, PE_CUM: 67.9, PL_CUM: 59,   AC_CUM: 61,   FC_CUM: 61,   DE_CUM: -6.9,  DL_CUM: 2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Overall', DISC: 'Piping',     PE_WK: 1.4,  PL_WK: 1.6,  AC_WK: 0,    FC_WK: 0,    DE_WK: -1.4, DL_WK: -1.5, PE_CUM: 68.9, PL_CUM: 61.3, AC_CUM: 68.5, FC_CUM: 68.5, DE_CUM: -0.3,  DL_CUM: 7.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Overall', DISC: 'Process',    PE_WK: 0.8,  PL_WK: 0.8,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.7, DL_WK: -0.7, PE_CUM: 91.4, PL_CUM: 81.6, AC_CUM: 86.7, FC_CUM: 86.7, DE_CUM: -4.7,  DL_CUM: 5.1, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Overall', DISC: 'Structural', PE_WK: 1.9,  PL_WK: 1.6,  AC_WK: 0.7,  FC_WK: 0.7,  DE_WK: -1.2, DL_WK: -0.9, PE_CUM: 62.6, PL_CUM: 50.9, AC_CUM: 46.3, FC_CUM: 46.3, DE_CUM: -16.3, DL_CUM: -4.6, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Topside', DISC: 'E&I',        PE_WK: 1.3,  PL_WK: 1.2,  AC_WK: 0.6,  FC_WK: 0.6,  DE_WK: -0.7, DL_WK: -0.6, PE_CUM: 61.9, PL_CUM: 53.3, AC_CUM: 56.5, FC_CUM: 56.5, DE_CUM: -5.4,  DL_CUM: 3.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 1,    PL_WK: 1,    AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.9, DL_WK: -0.9, PE_CUM: 50.7, PL_CUM: 50.7, AC_CUM: 43.3, FC_CUM: 43.3, DE_CUM: -7.4,  DL_CUM: -7.4, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Topside', DISC: 'Mechanical', PE_WK: 0.5,  PL_WK: 0.6,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.4, DL_WK: -0.5, PE_CUM: 71.5, PL_CUM: 69.6, AC_CUM: 79.2, FC_CUM: 79.2, DE_CUM: 7.7,   DL_CUM: 9.5, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Topside', DISC: 'Overall',    PE_WK: 1.3,  PL_WK: 1.3,  AC_WK: 0.3,  FC_WK: 0.3,  DE_WK: -1,   DL_WK: -1,   PE_CUM: 63.8, PL_CUM: 53.6, AC_CUM: 56.3, FC_CUM: 56.3, DE_CUM: -7.5,  DL_CUM: 2.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Topside', DISC: 'Piping',     PE_WK: 1.3,  PL_WK: 1.2,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -1.3, DL_WK: -1.2, PE_CUM: 64,   PL_CUM: 59.4, AC_CUM: 64.8, FC_CUM: 64.8, DE_CUM: 0.8,   DL_CUM: 5.4, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Topside', DISC: 'Process',    PE_WK: 1.2,  PL_WK: 1.1,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -1,   DL_WK: -0.9, PE_CUM: 88.3, PL_CUM: 76.8, AC_CUM: 82.4, FC_CUM: 82.4, DE_CUM: -5.9,  DL_CUM: 5.5, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Overall',            AREA: 'Topside', DISC: 'Structural', PE_WK: 1.8,  PL_WK: 1.8,  AC_WK: 0.3,  FC_WK: 0.3,  DE_WK: -1.5, DL_WK: -1.5, PE_CUM: 57.3, PL_CUM: 36.8, AC_CUM: 33,   FC_CUM: 33,   DE_CUM: -24.2, DL_CUM: -3.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Hull',    DISC: 'E&I',        PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 0.1,  PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 0.1,  PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: -0.1,  DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 0.1,  PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 0.1,  PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: -0.1,  DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Hull',    DISC: 'Overall',    PE_WK: 1.9,  PL_WK: 0.7,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -1.9, DL_WK: -0.6, PE_CUM: 5.9,  PL_CUM: 2.1,  AC_CUM: 0.4,  FC_CUM: 0.4,  DE_CUM: -5.5,  DL_CUM: -1.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Hull',    DISC: 'Piping',     PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Hull',    DISC: 'Structural', PE_WK: 2.9,  PL_WK: 1.1,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -2.8, DL_WK: -1,   PE_CUM: 9.1,  PL_CUM: 3.3,  AC_CUM: 0.6,  FC_CUM: 0.6,  DE_CUM: -8.4,  DL_CUM: -2.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'LQ',      DISC: 'E&I',        PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'LQ',      DISC: 'HVAC/Arch.', PE_WK: 1.5,  PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: -1.5, DL_WK: 0,    PE_CUM: 3.9,  PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: -3.9,  DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'LQ',      DISC: 'Mechanical', PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'LQ',      DISC: 'Overall',    PE_WK: 0.9,  PL_WK: 0.1,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -0.8, DL_WK: 0,    PE_CUM: 2.4,  PL_CUM: 0.1,  AC_CUM: 0.4,  FC_CUM: 0.4,  DE_CUM: -2.1,  DL_CUM: 0.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'LQ',      DISC: 'Piping',     PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'LQ',      DISC: 'Structural', PE_WK: 3.3,  PL_WK: 0.4,  AC_WK: 0.7,  FC_WK: 0.7,  DE_WK: -2.6, DL_WK: 0.3,  PE_CUM: 8.7,  PL_CUM: 0.9,  AC_CUM: 2.3,  FC_CUM: 2.3,  DE_CUM: -6.4,  DL_CUM: 1.4, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Overall', DISC: 'E&I',        PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 1.2,  PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: -1.2, DL_WK: 0,    PE_CUM: 3.1,  PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: -3.1,  DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Overall', DISC: 'Mechanical', PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Overall', DISC: 'Overall',    PE_WK: 0.9,  PL_WK: 0.3,  AC_WK: 0,    FC_WK: 0,    DE_WK: -0.9, DL_WK: -0.3, PE_CUM: 2.7,  PL_CUM: 0.8,  AC_CUM: 0.2,  FC_CUM: 0.2,  DE_CUM: -2.6,  DL_CUM: -0.7, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Overall', DISC: 'Piping',     PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Overall', DISC: 'Structural', PE_WK: 1.9,  PL_WK: 0.6,  AC_WK: 0.1,  FC_WK: 0.1,  DE_WK: -1.9, DL_WK: -0.6, PE_CUM: 6,    PL_CUM: 1.9,  AC_CUM: 0.4,  FC_CUM: 0.4,  DE_CUM: -5.5,  DL_CUM: -1.5, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Topside', DISC: 'E&I',        PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Topside', DISC: 'Mechanical', PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Topside', DISC: 'Overall',    PE_WK: 0.3,  PL_WK: 0.1,  AC_WK: 0,    FC_WK: 0,    DE_WK: -0.3, DL_WK: -0.1, PE_CUM: 0.8,  PL_CUM: 0.2,  AC_CUM: 0,    FC_CUM: 0,    DE_CUM: -0.8,  DL_CUM: -0.2, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Topside', DISC: 'Piping',     PE_WK: 0,    PL_WK: 0,    AC_WK: 0,    FC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Engineering', LEV: 'Shop Drawing',       AREA: 'Topside', DISC: 'Structural', PE_WK: 0.7,  PL_WK: 0.2,  AC_WK: 0,    FC_WK: 0,    DE_WK: -0.7, DL_WK: -0.2, PE_CUM: 2.3,  PL_CUM: 0.5,  AC_CUM: 0,    FC_CUM: 0,    DE_CUM: -2.3,  DL_CUM: -0.5, },
  ],


  Queries: {

    SQL1: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          850,
        
        PADDING_TOP:            290,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         30,
        PADDING_LEFT:           90,

        // Timeline
        WEEKEND:                'friday',
        CUTOFF:                 '2024-09-27',


        // Line Chart Properties
        REF_CODE                : 'PE_CUM     / PL_CUM     / AC_CUM  / FC_CUM   ',
        NAME                    : 'Early Plan / Late Plan  / Actual  / Forecast ',
        LINE_TYPE               : 'LINE       / LINE       / ACT     / DASH     ',
        LINE_SIZE               : '1.5        / 1.5        / 1.5     / 1.5      ',
        LINE_COLOR              : '#A7A7A7    / #44A9DF    / #EC407A / #EC407A  ',
        LINE_OPACITY            : '1          / 1          / 1       / 1        ',
      }
    ],

    SQL2: [
      { MS: 'Steel Cutting',         MSDATE: '2024-11-01', },
      { MS: 'Block Paint',           MSDATE: '2025-03-14', },
      { MS: '2nd P.E ',              MSDATE: '2025-04-08', },
      { MS: 'Setting',               MSDATE: '2025-07-01', },
      { MS: 'Mechanical Completion', MSDATE: '2025-08-25', },
      { MS: 'Commissioning',         MSDATE: '2026-02-02', },
    ],
    
  }
}