export default {
  Canvas: {
    CanvasWidth: 900,
    CanvasHeight: 550,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#fff',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 80,
    CanvasChartY: 130.5,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
}