import __C from '../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 400,
      CanvasHeight: 370,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 320,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'white',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  
  DataItems: [
    {CDATE: '2023-12-15', PACKAGE: 'Project Overall', LEVEL:'L5', LEFT_NO: '',     RIGHT_NO: '0/0', },
    {CDATE: '2023-12-15', PACKAGE: 'Project Overall', LEVEL:'L4', LEFT_NO: '',     RIGHT_NO: '0/0', },
    {CDATE: '2023-12-15', PACKAGE: 'Project Overall', LEVEL:'L3', LEFT_NO: '',     RIGHT_NO: '0/0', },
    {CDATE: '2023-12-15', PACKAGE: 'Project Overall', LEVEL:'L2', LEFT_NO: '0/0',  RIGHT_NO: '0/0', },
    {CDATE: '2023-12-15', PACKAGE: 'Project Overall', LEVEL:'L1', LEFT_NO: '1/7',  RIGHT_NO: '0/0', },
    {CDATE: '2023-12-15', PACKAGE: 'Project Overall', LEVEL:'L0', LEFT_NO: '0/33', RIGHT_NO: '3/8', },
  ],

  Queries: {

  },
  
}