// svg1 custom svg
import JLibChart from '../../lib/jin/svgchartlib/01_Charts/00_Customs'
import JLibPage from '../../lib/jin/svgchartlib/02_PageComponents/00_Customs'
// svg1 custom svg data
import JLibChartData from '../../lib/jin/svgchartlib/01_Charts/00_Customs/data'
import JLibPageData from '../../lib/jin/svgchartlib/02_PageComponents/00_Customs/data'

// svg2 Component
import JLibV2Chart from '../../lib/jin/svgchartlib-v2/export/Component_index'
// svg2 Trion Project
import JLibV2Page from '../../lib/jin/svgchartlib-v2/export/Trion_index'

// svg2 Component data
import JLibV2ChartData from '../../lib/jin/svgchartlib-v2/export/Component_data'
// svg2 Trion Project data
import JLibV2PageData from '../../lib/jin/svgchartlib-v2/export/Trion_data'

export default {
  Component: {
    ...JLibChart,
    ...JLibPage,
    ...JLibV2Page,
    ...JLibV2Chart,
  },
  Data: {
    ...JLibChartData,
    ...JLibPageData,
    ...JLibV2PageData,
    ...JLibV2ChartData,
  }
}

