import __C from '../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 300,
      CanvasHeight: 120,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {PKG:"P1", DISC:"ELE", TOTAL:220, ACTUAL:59, ACTIVE:161, },
      {PKG:"P1", DISC:"HVA", TOTAL:250, ACTUAL:99, ACTIVE:59,  },
      {PKG:"P2", DISC:"INS", TOTAL:152, ACTUAL:35, ACTIVE:117, },
      {PKG:"P2", DISC:"ROT", TOTAL:278, ACTUAL:38, ACTIVE:240, },
      {PKG:"P3", DISC:"PRO", TOTAL:99,  ACTUAL:250,ACTIVE:50,  },
    ],

    Queries: {
      SQL1: [
        {
          X: 10,
          Y: 10,
          HEADER_COLOR      : '#000',
          HEADER_TEXT_SIZE  : 10.5,
          DATA_TEXT_SIZE    : 10, // DO not Change if possible
          TABLE_Y           : 30, // DO not Change if possible
          CELL_PADDING      : 4, // DO not Change if possible
          LINE_HEIGHT       : 15, // DO not Change if possible

          BAR_COLOR         : '#EC407A',
          BAR_WIDTH         : 75,
          BAR_OPACITY       : 0.3,

          // Column Properties
          REF_CODE          : 'PKG    / DISC       / TOTAL / ACTUAL / ACTIVE  ',
          NAME              : 'PKG    / Discipline / Total / Closed / Active  ',
          JSON_MAP          : 'KEY1   / KEY2       /       /        /         ', 
          JSON_LINK         : '       /            / JSON1 / JSON1  / JSON1   ',
          FILTER            : '       /            / F1    / F2     / F3      ',
          OVERLAP           : 'N      / Y          / Y     / Y      / Y       ',
          COL_WIDTH         : '45     / 45         / 35    / 35     / 100     ',
          TEXT_COLOR        : '#000   / #000       / gray  / gray   / red     ',
          ALIGN             : 'middle / middle     / end   / end    / start   ',
        },
      ],

      // Filter
      SQL2: [
        { 
          F1 : " [CATEGORY] = ''A''",
          F2: " [CATEGORY] = ''A'' AND [STATUS_CODE] = ''C''",
          F3: " [CATEGORY] = ''A'' AND [STATUS_CODE] != ''C''",
        }
      ]
    },
}