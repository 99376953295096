
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },


  DataItems: [
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Hull',    DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Hull',    DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Hull',    DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Hull',    DISC: 'Structural', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'LQ',      DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'LQ',      DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'LQ',      DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'LQ',      DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'LQ',      DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'LQ',      DISC: 'Structural', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Overall', DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Overall', DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Overall', DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Overall', DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Overall', DISC: 'Structural', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Topside', DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Topside', DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Topside', DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Topside', DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Fabrication',           AREA: 'Topside', DISC: 'Structural', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Hull',    DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Hull',    DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Hull',    DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'LQ',      DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Overall', DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Overall', DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Overall', DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Overall', DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Topside', DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Topside', DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Topside', DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Mechanical Completion', AREA: 'Topside', DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Hull',    DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Hull',    DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Hull',    DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Hull',    DISC: 'Structural', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'LQ',      DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'LQ',      DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'LQ',      DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'LQ',      DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'LQ',      DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'LQ',      DISC: 'Structural', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Overall', DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Overall', DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Overall', DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Overall', DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Overall', DISC: 'Structural', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Topside', DISC: 'E&I',        PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Topside', DISC: 'Mechanical', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Topside', DISC: 'Overall',    PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Topside', DISC: 'Piping',     PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },
    { CDATE: '2024-10-18', PHASE: 'Construction', LEV: 'Overall',               AREA: 'Topside', DISC: 'Structural', PE_WK: 0, PL_WK: 0, AC_WK: null, CF_WK: 0, DE_WK: null, DL_WK: null, PE_CUM: 0, PL_CUM: 0, AC_CUM: null, FC_CUM: 0, DE_CUM: null, DL_CUM: null, },

  ],


  Queries: {

    SQL1: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          850,
        
        PADDING_TOP:            290,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         30,
        PADDING_LEFT:           90,

        // Timeline
        WEEKEND:                'friday',
        CUTOFF:                 '2024-10-18',


        // Line Chart Properties
        REF_CODE                : 'PE_CUM     / PL_CUM     / AC_CUM  / FC_CUM   ',
        NAME                    : 'Early Plan / Late Plan  / Actual  / Forecast ',
        LINE_TYPE               : 'LINE       / LINE       / ACT     / DASH     ',
        LINE_SIZE               : '1.5        / 1.5        / 1.5     / 1.5      ',
        LINE_COLOR              : '#A7A7A7    / #44A9DF    / #EC407A / #EC407A  ',
        LINE_OPACITY            : '1          / 1          / 1       / 1        ',
      }
    ],

    SQL2: [
      { MS: 'Steel Cutting',         MSDATE: '2024-11-01', },
      { MS: 'Block Paint',           MSDATE: '2025-03-14', },
      { MS: '2nd P.E ',              MSDATE: '2025-04-08', },
      { MS: 'Setting',               MSDATE: '2025-07-01', },
      { MS: 'Mechanical Completion', MSDATE: '2025-08-25', },
      { MS: 'Commissioning',         MSDATE: '2026-02-02', },
    ]


  }
}