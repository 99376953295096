import __C from '../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 800,
      CanvasHeight: 280,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      
    },

    DataItems: [
      { CDATE: '2024-08-09', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.8, AC_WK: 0.4,  FC_WK: 0.4, DE_WK: -0.3, DL_WK: -0.4, },
      { CDATE: '2024-08-16', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.8, AC_WK: 0.4,  FC_WK: 0.4, DE_WK: -0.3, DL_WK: -0.4, },
      { CDATE: '2024-08-23', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.8, PL_WK: 0.9, AC_WK: 0.8,  FC_WK: 0.8, DE_WK: 0,    DL_WK: -0.1, },
      { CDATE: '2024-08-30', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.7, AC_WK: 0.5,  FC_WK: 0.5, DE_WK: -0.3, DL_WK: -0.2, },
      { CDATE: '2024-09-06', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.7, AC_WK: 0.9,  FC_WK: 0.9, DE_WK: 0.2,  DL_WK: 0.2, },
      { CDATE: '2024-09-13', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.7, AC_WK: 0.7,  FC_WK: 0.7, DE_WK: 0,    DL_WK: 0, },
      { CDATE: '2024-09-20', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.7, AC_WK: 0.3,  FC_WK: 0.3, DE_WK: -0.5, DL_WK: -0.4, },
      { CDATE: '2024-09-27', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.8, PL_WK: 0.7, AC_WK: 0.5,  FC_WK: 0.5, DE_WK: -0.3, DL_WK: -0.2, },
      { CDATE: '2024-10-04', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.7, AC_WK: null, FC_WK: 0.5, DE_WK: null, DL_WK: null, },
      { CDATE: '2024-10-11', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.7, AC_WK: null, FC_WK: 0.5, DE_WK: null, DL_WK: null, },
      { CDATE: '2024-10-18', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.8, PL_WK: 0.7, AC_WK: null, FC_WK: 0.5, DE_WK: null, DL_WK: null, },
      { CDATE: '2024-10-25', PHASE: 'Overall', AREA: 'Overall', PE_WK: 0.8, PL_WK: 0.7, AC_WK: null, FC_WK: 0.5, DE_WK: null, DL_WK: null, },
    ],

    Queries: {
      
      SQL1: [
        {
          // Timeline
          TL_CUTOFF               : '2024-09-27',
          TL_TEXT_TCOLOR          : '#333',
          TL_TEXT_SIZE            : 9,

          // Chart
          CHART_X                 : 50,
          CHART_Y                 : 60,
          CHART_WIDTH             : 650,
          CHART_HEIGHT            : 150, 
          CHART_BORDER_COLOR      : '#757575',
          CHART_BORDER_SWIDTH     : 0.5,
          CHART_BORDER_OPACITY    : 1,
          CHART_BAR_SIZE          : 10,

          // Title
          TITLE_MAIN              : 'Stack Chart &  Histogram for Construction',
          TITLE_SUB               : 'Mechanical',
          TITLE_ALIGN             : 'left',
          TITLE_MAIN_VISIBLE      : 'Y',
          TITLE_MAIN_TCOLOR       : '#000000',
          TITLE_MAIN_TSIZE        : 12,
          TITLE_MAIN_MARGIN       : 50,

          // Primery
          PRIMARY_TITLE_NAME      : 'Weekly Progress(%)',
          PRIMARY_TITLE_TCOLOR    : '#333333',
          PRIMARY_TITLE_TSIZE     : 11,
          PRIMARY_TITLE_MARGIN    : 35,
          PRIMARY_VALUE_TCOLOR    : '#757575',
          PRIMARY_VALUE_TSIZE     : 9,
          PRIMARY_VALUE_MARGIN    : 4,

          // Legend
          LEGEND_TCOLOR           : '#333',
          LEGEND_TSIZE            : 9,
          LEGEND_MARGIN           : 12,
          LEGEND_ALIGN            : 'left',

          // Delta
          DELTA_TSIZE             : 10,
          DELTA_MARGIN            : 3,
          DELTA_UP_COLOR          : '#44A9DF',
          DELTA_DOWN_COLOR        : '#F35E90',
  
          // Bar Chart Styles
          REF_CODE                : 'PE_WK      / PL_WK       / AC_WK    / FC_WK    ',
          NAME                    : 'Early Plan / Late Plan   / Actual    / Forecast ',
          TYPE                    : '           /             / ACT       / FC       ',
          COLOR                   : '#BDBCBC    / #E0E0DF     / #83D2F5   / #CCEFFF  ',  
        },
      ],

    },
}