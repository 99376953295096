import __C from '../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 360,
      CanvasHeight: 120,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      
    },

    DataItems: [
      {PKG:"P1", DISC:"ELE", TOTAL:220, ACTUAL:59, REMAIN:161, PROG1:25},
      {PKG:"P1", DISC:"INS", TOTAL:250, ACTUAL:99, REMAIN:59,  PROG1:50},
      {PKG:"P2", DISC:"INS", TOTAL:152, ACTUAL:35, REMAIN:117, PROG1:75},
      {PKG:"P2", DISC:"ROT", TOTAL:278, ACTUAL:38, REMAIN:240, PROG1:15},
      {PKG:"P3", DISC:"PRO", TOTAL:99,  ACTUAL:250,REMAIN:50,  PROG1:95},
    ],

    Queries: {
      SQL1: [
        {
          X: 10,
          Y: 10,
          HEADER_COLOR    : '#000',
          HEADER_TEXT_SIZE: 10.5, 
          DATA_TEXT_SIZE  : 10, // DO not Change if possible
          TABLE_Y         : 30, // DO not Change if possible
          CELL_PADDING    : 4, // DO not Change if possible
          LINE_HEIGHT     : 15, // DO not Change if possible
          
          BAR_COLOR       : '#B4E3FA',
          BAR_WIDTH       : 100,
          BAR_OPACITY     : 0.5,

          // Column Properties
          REF_CODE          : 'PKG    / DISC       / TOTAL / ACTUAL / REMAIN / PROG1     ',
          NAME              : 'PKG    / Discipline / Total / Closed / Remain / Progress  ',
          JSON_MAP          : 'KEY1   / KEY2       /       /        /        /           ', // COL_LINK
          JSON_LINK         : '       /            / JSON1 / JSON1  / JSON1  /           ', // LINK
          FILTER            : '       /            / F1    / F2     / F3     /           ',
          OVERLAP           : 'N      / Y          / Y     / Y      / Y      / Y         ',
          COL_WIDTH         : '45     / 45         / 40    / 40     / 40     / 100       ',
          TEXT_COLOR        : '#000   / #000       / gray  / gray   / gray   / red       ',
          ALIGN             : 'middle / middle     / end   / end    / end    / start     ',   
        },
      ],

      // Filter
      SQL2: [
        { 
          F1 : " [CATEGORY] = ''A''",
          F2 : " [CATEGORY] = ''A'' AND [STATUS_CODE] = ''C''",
          F3 : " [CATEGORY] = ''A'' AND [STATUS_CODE] != ''C''",
        }
      ]
    },
}