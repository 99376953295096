import System_ChartOptions_Data from      '../../09_Projects/TCO/00_System/ChartOptions/includes/primitives/Data'
import System_Dropdown_Data from          '../../09_Projects/TCO/00_System/Dropdown/includes/primitives/Data'
import System_SubMenu_01_Data from        '../../09_Projects/TCO/00_System/SubMenu_01/includes/primitives/Data'

// Common
import CMN_HistoryTable_Data from         '../../01_Charts/99_Common/HistoryTable/includes/primitives/Data'
import CMN_TBD_Data from                  '../../01_Charts/99_Common/TBD/includes/primitives/Data'
import CMN_Up2UText_Data from             '../../01_Charts/99_Common/Up2UText/includes/primitives/Data'
import CMN_3DViewerEmbedded_Data from     '../../01_Charts/99_Common/3DViewerEmbedded/includes/primitives/Data'

//~~~~~~~~~~~~~~~~~~~~CRISP Project --> svg v2

//00_Home
//01_Project
//02_Engineering
//03_Planning
//04_Construction
//05_Procurement
//06_QualityControl
//07_SystemCompletion

export default {

  // System
  System_ChartOptions_Data,
  System_Dropdown_Data,
  System_SubMenu_01_Data,

  // Common
  CMN_HistoryTable_Data,  
  CMN_TBD_Data,
  CMN_Up2UText_Data,
  CMN_3DViewerEmbedded_Data,

  //~~~~~~~~~~~~~~~~~~~~CRISP Project --> svg v2

  //00_Home
  //01_Project
  //02_Engineering
  //03_Planning
  //04_Construction
  //05_Procurement
  //06_QualityControl
  //07_SystemCompletion
}
