import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 300,
      CanvasHeight: 300,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      
    },

   
    DataItems: [
      {TITLE: 'Certificate Status ORCs',  TOTAL: 20245,  ACTUAL: 9125,  REMAIN: 6578,  PROG: 25.0, },
    ],


    Queries: {
      SQL1: [
        {
          CIRCLE_Y:             190,
          CIRCLE_RADIUS:        80,
          CIRCLE_TICKNESS:      25,
          CIRCLE_COLOR:         '#44A9DF',
          CIRCLE_OPACITY:       .8,
          GRID_COLOR:           '#F4F5F5',
          GRID_WEIGHT:          2,
          GRID_OPACITY:         1,
          NEEDLE_COLOR:         'DarkGray',

          TITLE_VISIVLE:        'Y',
          TITLE_COLOR:          '#333',
          TITLE_SIZE:           15,
          TITLE_WEIGHT:         400,
          TITLE_ALIGN:          '', // Future

          DATA_VISIVLE:         'Y',
          DATA_LINEHEIGHT:      18,
          HEADER_CODE:          ' TOTAL    / ACTUAL    / REMAIN   ',
          HEADER_NAME:          ' Total    / Completed / Remain   ',
          HEADER_TEXT_SIZE:     ' 11       / 11        / 11       ',
          HEADER_TEXT_COLOR:    ' gray     / gray      / gray     ',

          VALUE_TEXT_SIZE:      ' 10       / 10        / 10       / 15      ',
          VALUE_TEXT_COLOR:     ' #44A9DF  / #44A9DF   / #44A9DF  / #F48FB1 ',
          VALUE_TEXT_ALIGN:     '          /           /          /         ', // Future

          JSON_MAP:             '          /           /          /         ', // Future
          JSON_LINK:            ' JSON1    / JSON1     / JSON1    /         ', // Future
          VALUE_FILTER:         ' F1       / F2        / F3       /         ', // Future
        },
      ],

      // Filter
      SQL2: [
        {
          F1: " [CATEGORY] = ''A''",
          F2: " [CATEGORY] = ''A'' AND [STATUS_CODE] = ''C''",
          F3: " [CATEGORY] = ''A'' AND [STATUS_CODE] != ''C''",
        },
      ]
    },
}