export default {
    Canvas: {
      CanvasWidth: 620,
      CanvasHeight: 470,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 80,
      CanvasChartY: 130.5,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    DataItems: [
      {CDATE:'2019-12-20', A_T:38  , A_A:36   ,A_A_CUM:2335 , A_O:2   , A_PG:94.74, A_CUM_PG:1.45 , PA_O:0  , PB_O:0  },
      {CDATE:'2019-12-27', A_T:178 , A_A:153  ,A_A_CUM:2488 , A_O:25  , A_PG:85.96, A_CUM_PG:1.55 , PA_O:6  , PB_O:31 },
      {CDATE:'2020-01-03', A_T:167 , A_A:155  ,A_A_CUM:2643 , A_O:12  , A_PG:92.81, A_CUM_PG:1.65 , PA_O:0  , PB_O:8  },
      {CDATE:'2020-01-10', A_T:1484, A_A:1462 ,A_A_CUM:4105 , A_O:22  , A_PG:98.52, A_CUM_PG:2.56 , PA_O:4  , PB_O:35 },
      {CDATE:'2020-01-17', A_T:9003, A_A:8889 ,A_A_CUM:12994, A_O:114 , A_PG:98.73, A_CUM_PG:8.09 , PA_O:28 , PB_O:261},
      {CDATE:'2020-01-24', A_T:724 , A_A:708  ,A_A_CUM:13702, A_O:16  , A_PG:97.79, A_CUM_PG:8.54 , PA_O:2  , PB_O:138},
      {CDATE:'2020-01-31', A_T:734 , A_A:710  ,A_A_CUM:14412, A_O:24  , A_PG:96.73, A_CUM_PG:8.98 , PA_O:25 , PB_O:142},
      {CDATE:'2020-02-07', A_T:1171, A_A:1135 ,A_A_CUM:15547, A_O:36  , A_PG:96.93, A_CUM_PG:9.68 , PA_O:40 , PB_O:345},
      {CDATE:'2020-02-14', A_T:1548, A_A:1191 ,A_A_CUM:16738, A_O:357 , A_PG:76.94, A_CUM_PG:10.43, PA_O:38 , PB_O:142},
      {CDATE:'2020-02-21', A_T:1706, A_A:1400 ,A_A_CUM:18138, A_O:306 , A_PG:82.06, A_CUM_PG:11.3 , PA_O:35 , PB_O:167},
      {CDATE:'2020-02-28', A_T:1799, A_A:1337 ,A_A_CUM:19475, A_O:462 , A_PG:74.32, A_CUM_PG:12.13, PA_O:25 , PB_O:112},
      {CDATE:'2020-03-06', A_T:2981, A_A:2353 ,A_A_CUM:21828, A_O:628 , A_PG:78.93, A_CUM_PG:13.6 , PA_O:94 , PB_O:198},
      // {CDATE:'2020-03-13', A_T:4404, A_A:2972 ,A_A_CUM:24800, A_O:1432, A_PG:67.48, A_CUM_PG:15.45, PA_O:71 , PB_O:218},
      // {CDATE:'2020-03-20', A_T:4979, A_A:3807 ,A_A_CUM:28607, A_O:1172, A_PG:76.46, A_CUM_PG:17.82 ,PA_O:85 , PB_O:150},
    ],    
    Queries: {
      // Table Headers
      SQL1: [
      ],       
      SQL2: [
        { TYPE: 'A', LINK1: 'PI', DISC: 'Piping', TOTAL: 35215, ACTUAL: 21704, REMAIN: 13511, PROG: 61.63, },
      ],       
    },
}