
export default {
  Canvas: {
    CanvasWidth: 200,
    CanvasHeight: 150,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#FFF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 200,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  // --> this.rawData
  DataItems: [ 
    {
      COUNT: 5,

      TEXT1: 'Hull',
      TEXT2: 'Topside',
      TEXT3: 'LQ',
      TEXT4: 'LQ',
      TEXT5: 'LQ',

      
      COLOR1: '#333',
      COLOR2: '#333',
      COLOR3: '#333',
      COLOR4: '#333',
      COLOR5: '#333',

      VALUE1: 75.5,
      VALUE2: 30.8,
      VALUE3: 15.6,
      VALUE4: 15.6,
      VALUE5: 15.6,

      LINK1: 'N',
      LINK2: 'N',
      LINK3: 'N',
      LINK4: 'N',
      LINK5: 'N',

      FILTER1: 'filter1',
      FILTER2: 'filter2',
      FILTER3: 'filter3',
      FILTER4: 'filter4',
      FILTER5: 'filter5',

    }
  ],

  Queries: {

    // --> this.style
    SQL1:  [
      {
        // Table
        CIRCLE_RADIUS: 35,
        CIRCLE_THICKNESS: 10,
        CIRCLE_WIDTH: 200,
        CIRCLE_PADDING: 200,
        CIRCLE_FILL_COLOR: '#83D2F5',
        CIRCLE_STROKE_COLOR: '#BCBCBC',
        CIRCLE_STROKE_SIZE: 0.5,
        CIRCLE_OPACITY: .7,
        GRID_COLOR: '#E0E0DF',
        GRID_OPACITY: 0.5,
        
        // Column
        COL_TEXT_PADDING: 40,
        COL_TEXT_COLOR: '#000',
        COL_TEXT_SIZE: 11,
        COL_TEXT_WEIGHT: 400,
        
        // Value

        VALUE_TEXT_SIZE: 11,
        VALUE_TEXT_WEIGHT: 500,
      },
    ],
  },

}