export default {
    Canvas: {
      CanvasWidth: 350,
      CanvasHeight: 120,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 0,
      CanvasChartHeight: 0,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Title', x: 100, y: 20, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 20, y: 20, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 20,
          y: 40,
          font: 'roboto',
          style: 'regular',
          size: 14,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    DataItems: [],    
}