//import __C from '../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 330,
    CanvasHeight: 75,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 330,
    CanvasChartHeight: 75,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {

  },
  
  DataItems: [
    {WEEK:"LAST", VALUE:1}, // 'LAST' 수정하지말것
    {WEEK:"SAT",  VALUE:2},
    {WEEK:"SUN",  VALUE:0},
    {WEEK:"MON",  VALUE:4},
    {WEEK:"TUE",  VALUE:5},
    {WEEK:"WEN",  VALUE:6},
    {WEEK:"TUE",  VALUE:7},
    {WEEK:"FRI",  VALUE:8},
    {WEEK:"THIS", VALUE:9}, // 'THIS' 수정하지말것
  ],

  Queries: {
    // filter
    SQL1:  [
      {
        X: 45,
        Y: 10,
        LINK: 'Y', // 링크 유무
        TOTAL_SIZE: 35,
        TOTAL_TEXT_COLOR: '#333',

        WEEK_SIZE: 25,
        WEEK_TEXT_COLOR: '#bcbcbc',
        WEEK_GAP: 3, // 가능하면 수정하지말것
      },
    ],
  },
}