
export default {
  Canvas: {
    CanvasWidth: 300,
    CanvasHeight: 200,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  // --> this.rawData
  DataItems: [ 
    {GROUP: 'MC',        ITEM: 'ITR',  TOTAL: 1234, CLOSED: 1234, REMAIN: 1234},
    {GROUP: 'Punch',     ITEM: 'A',    TOTAL: 2345, CLOSED: 2345, REMAIN: 2345},
    {GROUP: 'Punch',     ITEM: 'B',    TOTAL: 3456, CLOSED: 3456, REMAIN: 3456},
    {GROUP: 'Pre-Comm.', ITEM: 'B1',   TOTAL: 4567, CLOSED: 4567, REMAIN: 4567},
  ],

  Queries: {

    SQL1:  [
      {
        // GROUP    
        GROUP_PADDING:          10,
        GROUP_MARGIN:           5,

        TITLE_COLOR:            ' #000    / #000     / #44A9DF  / #000     ',
        TITLE_SIZE:             ' 13      / 13       / 13       / 13       ',
        TITLE_WEIGHT:           ' 450     / 450      / 450      / 450      ',
        TITLE_JSON_MAP:         'KEY1',

        HEADER_HEIGHT:          20,
        HEADER_FONT_SIZE:       10,
        HEADER_FONT_WEIGHT:     400,
        HEADER_FONT_COLOR:      '#000',

        BOX_FILL_COLOR:         '#7BAF30  / #CFCD1A  / #FFE000  / #B4E3FA  ',
        BOX_FILL_OPACITY:       ' 0.5     / 0.5      / 0.5      / 0.5      ',
        BOX_STROKE_WIDTH:       ' 0       / 0        / 0        / 0        ',
        BOX_STROKE_COLOR:       '#757575  / #757575  / #757575  / #757575  ',
        BOX_STROKE_OPACITY:     ' 0.5     / 0.5      / 0.5      / 0.5      ',
        BOX_RADIUS:             ' 5       / 5        / 5        / 5        ',


        // VALUE    
        VALUE_PADDING:          5,
        VALUE_LINEHEIGHT:       15,
        VALUE_SIZE:             10,
        VALUE_BASE_Y:           1, // Do not change, if possible
        VALUE_WEIGHT:           400,

        
        // COLUMN
        REF_CODE:               ' ITEM    / TOTAL     / CLOSED    / REMAIN  ',
        TITLE_1:                '         / Total     / Actual    / Remain  ',
        TITLE_2:                '         / Total     / Closed    / Active  ',
        TITLE_3:                '         / Total     / Closed    / Open    ',
        WIDTH:                  ' 50      / 50        / 50        / 50      ',
        TEXT_COLOR:             ' #000    / gray      / gray      / #F35E90 ',
        ALIGN:                  ' start   / end       / end       / end     ',
        FORMAT:                 '         / $         / $         / %       ',
        JSON_MAP:               ' KEY2    /           /           /         ',
        JSON_LINK:              ' JSON1   / JSON2     / JSON2     / JSON2   ',
        FILTER:                 ' F0       / F1        / F2        / F3     ',

       
      },
    ],

    SQL2: [ // Fintering Conditions
      {
        F0 : " [F0] = '0' ",
        F1 : " [F1] = '1' ",
        F2 : " [F2] = '2' ",
        F3 : " [F3] = '3' ",
      },
    ],
  },

}