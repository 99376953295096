export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Queries: {
    SQL1:  []
  },

  Text: {
    Text: [
      { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD', x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: 41,
        y: 115,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },

  DataItems:  [{
    seiCodeL1: [
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127700",SEICODE_L1_DESC_E:"BD - Butadiene Unit",SEICODE_L1_DESC_C:"丁二烯抽提装置",DESCRIPTION:"127700 BD - Butadiene Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127800",SEICODE_L1_DESC_E:"GHU - Gasoline Hydrotreating Unit",SEICODE_L1_DESC_C:"裂解汽油加氢装置",DESCRIPTION:"127800 GHU - Gasoline Hydrotreating Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",DESCRIPTION:"127900 OR - Olefins Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"136200",SEICODE_L1_DESC_E:"DIB - DI-Isobutylene Unit",SEICODE_L1_DESC_C:"DIB装置",DESCRIPTION:"136200 DIB - DI-Isobutylene Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",DESCRIPTION:"170000 Pressurized Tank Farm Common Area"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170092",SEICODE_L1_DESC_E:"Tank Farm 1st Substation",SEICODE_L1_DESC_C:"罐区第一变电所",DESCRIPTION:"170092 Tank Farm 1st Substation"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184300",SEICODE_L1_DESC_E:"Demineralized Water Unit",SEICODE_L1_DESC_C:"脱盐水站",DESCRIPTION:"184300 Demineralized Water Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184800",SEICODE_L1_DESC_E:"Boiler Unit",SEICODE_L1_DESC_C:"锅炉装置",DESCRIPTION:"184800 Boiler Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",DESCRIPTION:"277000 Atmospheric Tank Farm Common Area"},
      {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277100",SEICODE_L1_DESC_E:"Intermediate Tank Farm 2",SEICODE_L1_DESC_C:"中间罐区2",DESCRIPTION:"277100 Intermediate Tank Farm 2"},
      {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"111300",SEICODE_L1_DESC_E:"BTX - Benzene Toluene Unit ",SEICODE_L1_DESC_C:"芳烃抽提装置 ",DESCRIPTION:"111300 BTX - Benzene Toluene Unit "},
      {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",DESCRIPTION:"121300 GPPE - Gas Phase Polyethylene Unit "},
      {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",DESCRIPTION:"121400 LDPE - Low Density Polyethylene Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",DESCRIPTION:"121900 PP - Polypropylene Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"152801",SEICODE_L1_DESC_E:"No.1 Warehouse",SEICODE_L1_DESC_C:"1#立体仓库",DESCRIPTION:"152801 No.1 Warehouse"},
      {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"152802",SEICODE_L1_DESC_E:"No.2 Warehouse",SEICODE_L1_DESC_C:"2#立体仓库",DESCRIPTION:"152802 No.2 Warehouse"},
      {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Polymer Bagging and Logistics",SEICODE_L1_DESC_C:"聚烯烃包装及立体库",DESCRIPTION:"NA Polymer Bagging and Logistics"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",DESCRIPTION:"151000 Common Area of Management Facilities(B+)"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",DESCRIPTION:"152400 Chemical Warehouses"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152700",SEICODE_L1_DESC_E:"Main plant Dispatch Area",SEICODE_L1_DESC_C:"停车场",DESCRIPTION:"152700 Main plant Dispatch Area"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178000",SEICODE_L1_DESC_E:"Loading/Unloading Station",SEICODE_L1_DESC_C:"汽车装卸设施",DESCRIPTION:"178000 Loading/Unloading Station"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178900",SEICODE_L1_DESC_E:"Ground Flare",SEICODE_L1_DESC_C:"地面火炬",DESCRIPTION:"178900 Ground Flare"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179000",SEICODE_L1_DESC_E:"Overall Interconnecting Pipe (A.G.) 1",SEICODE_L1_DESC_C:"整体连通管",DESCRIPTION:"179000 Overall Interconnecting Pipe (A.G.) 1"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179001",SEICODE_L1_DESC_E:"Pipe Galley #1",SEICODE_L1_DESC_C:"管道舱 #1",DESCRIPTION:"179001 Pipe Galley #1"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179002",SEICODE_L1_DESC_E:"Pipe Galley #2",SEICODE_L1_DESC_C:"管道舱 #2",DESCRIPTION:"179002 Pipe Galley #2"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179003",SEICODE_L1_DESC_E:"Pipe Galley #3",SEICODE_L1_DESC_C:"管道舱 #3",DESCRIPTION:"179003 Pipe Galley #3"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179004",SEICODE_L1_DESC_E:"Pipe Galley #4",SEICODE_L1_DESC_C:"管道舱 #4",DESCRIPTION:"179004 Pipe Galley #4"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179005",SEICODE_L1_DESC_E:"Pipe Galley #5",SEICODE_L1_DESC_C:"管道舱 #5",DESCRIPTION:"179005 Pipe Galley #5"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179006",SEICODE_L1_DESC_E:"Pipe Galley #6",SEICODE_L1_DESC_C:"管道舱 #6",DESCRIPTION:"179006 Pipe Galley #6"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179007",SEICODE_L1_DESC_E:"Pipe Galley #7",SEICODE_L1_DESC_C:"管道舱 #7",DESCRIPTION:"179007 Pipe Galley #7"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179100",SEICODE_L1_DESC_E:"Overall Interconnecting Pipe (A.G.) 2",SEICODE_L1_DESC_C:"整体连通管",DESCRIPTION:"179100 Overall Interconnecting Pipe (A.G.) 2"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180200",SEICODE_L1_DESC_E:"Cooling Water System 1",SEICODE_L1_DESC_C:"第一循环水场",DESCRIPTION:"180200 Cooling Water System 1"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180292",SEICODE_L1_DESC_E:"Substation",SEICODE_L1_DESC_C:"变电所",DESCRIPTION:"180292 Substation"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180600",SEICODE_L1_DESC_E:"Storm Water Pump Station and Accident Buffer Basin",SEICODE_L1_DESC_C:"雨水泵站及事故水池",DESCRIPTION:"180600 Storm Water Pump Station and Accident Buffer Basin"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"181000",SEICODE_L1_DESC_E:"Water and Firewater Pump Station 1",SEICODE_L1_DESC_C:"第一给水及消防泵站",DESCRIPTION:"181000 Water and Firewater Pump Station 1"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"182400",SEICODE_L1_DESC_E:"Wastewater Pre-Treatment Unit",SEICODE_L1_DESC_C:"污水预处理场",DESCRIPTION:"182400 Wastewater Pre-Treatment Unit"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"183000",SEICODE_L1_DESC_E:"Main Substation",SEICODE_L1_DESC_C:"总变电站",DESCRIPTION:"183000 Main Substation"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"185300",SEICODE_L1_DESC_E:"Air Compression Station",SEICODE_L1_DESC_C:"空压站",DESCRIPTION:"185300 Air Compression Station"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"280200",SEICODE_L1_DESC_E:"Cooling Water System 2",SEICODE_L1_DESC_C:"第二循环水场",DESCRIPTION:"280200 Cooling Water System 2"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"281000",SEICODE_L1_DESC_E:"Water and Firewater Pump Station 2",SEICODE_L1_DESC_C:"第二给水及消防泵站",DESCRIPTION:"281000 Water and Firewater Pump Station 2"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Electrical, Instrument and Telecom System",SEICODE_L1_DESC_C:"供配电及仪表通信系统",DESCRIPTION:"NA Electrical, Instrument and Telecom System"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",DESCRIPTION:"NA Overall General Layout"},
      {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",DESCRIPTION:"NA Storage and Transportation Facilities"}
    ],
    seiCodeInfo: [
      {NO:1,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"111300",SEICODE_L1_DESC_E:"BTX - Benzene Toluene Unit ",SEICODE_L1_DESC_C:"芳烃抽提装置 ",SEICODE_L2:"111300",SEICODE_DOC:"1300",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_MAINPLANT:1},
      {NO:2,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"111300",SEICODE_L1_DESC_E:"BTX - Benzene Toluene Unit ",SEICODE_L1_DESC_C:"芳烃抽提装置 ",SEICODE_L2:"111394",SEICODE_DOC:"1394",SEICODE_L2_DESC_E:"Co-product Operator Shelter",SEICODE_L2_DESC_C:"联合装置外操间",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:2},
      {NO:3,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"111300",SEICODE_L1_DESC_E:"BTX - Benzene Toluene Unit ",SEICODE_L1_DESC_C:"芳烃抽提装置 ",SEICODE_L2:"111342",SEICODE_DOC:"1342",SEICODE_L2_DESC_E:"Sanitary Sewage Sump",SEICODE_L2_DESC_C:"生活废水池",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:1},
      {NO:4,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121300",SEICODE_DOC:"1300",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_MAINPLANT:2},
      {NO:5,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121306",SEICODE_DOC:"1306",SEICODE_L2_DESC_E:"GPPE Pellet Dryers Classifiers And Surge Bin Area",SEICODE_L2_DESC_C:"GPPE 粒料干燥及出料单元",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:7},
      {NO:6,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121305",SEICODE_DOC:"1305",SEICODE_L2_DESC_E:"GPPE Thermal Oxidizer Blower Area",SEICODE_L2_DESC_C:"GPPE 热氧化物风机单元",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:8},
      {NO:7,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121307",SEICODE_DOC:"1307",SEICODE_L2_DESC_E:"GPPE Pellet Transfer Compressor Area",SEICODE_L2_DESC_C:"GPPE 粒料输送风机单元",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:14},
      {NO:8,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121332",SEICODE_DOC:"1332",SEICODE_L2_DESC_E:"GPPE CHEMICAL STORAGE",SEICODE_L2_DESC_C:"GPPE化学品库",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:9},
      {NO:9,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121308",SEICODE_DOC:"1308",SEICODE_L2_DESC_E:"GPPE Elutriator And Pellet Silos Area",SEICODE_L2_DESC_C:"GPPE 淘析和成品包装",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:13},
      {NO:10,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121304",SEICODE_DOC:"1304",SEICODE_L2_DESC_E:"GPPE Extrusion Building",SEICODE_L2_DESC_C:"GPPE 挤压造粒厂房",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:15},
      {NO:11,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121323",SEICODE_DOC:"1323",SEICODE_L2_DESC_E:"GPPE Granules Conveying System Of Line 2",SEICODE_L2_DESC_C:"GPPE 2线粉料输送风机单元",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:16},
      {NO:12,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121342",SEICODE_DOC:"1342",SEICODE_L2_DESC_E:"GPPE Waste Water/Storm Water Area",SEICODE_L2_DESC_C:"GPPE 废水池",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:17},
      {NO:13,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121301",SEICODE_DOC:"1301",SEICODE_L2_DESC_E:"GPPE Interconnecting Pipe Rack",SEICODE_L2_DESC_C:"GPPE街区管廊",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:19},
      {NO:14,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121321",SEICODE_DOC:"1321",SEICODE_L2_DESC_E:"GPPE Reaction And Purge Bin Area Of Line 2",SEICODE_L2_DESC_C:"GPPE 2线反应脱气单元",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:18},
      {NO:15,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121322",SEICODE_DOC:"1322",SEICODE_L2_DESC_E:"GPPE Vent Gas Recovery? And Propylene Refrigeration Area Of Line 2",SEICODE_L2_DESC_C:"GPPE 2线排放气回收及丙烯制冷单元",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:20},
      {NO:16,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121312",SEICODE_DOC:"1312",SEICODE_L2_DESC_E:"Vent Gas Recovery? And Propylene Refrigeration Area Of Line 1",SEICODE_L2_DESC_C:"GPPE 1线排放气回收及丙烯制冷单元GPPE",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:21},
      {NO:17,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121311",SEICODE_DOC:"1311",SEICODE_L2_DESC_E:"GPPE Reaction And Purge Bin Area Of Line 1",SEICODE_L2_DESC_C:"GPPE 1反应脱气单元",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:4},
      {NO:18,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121302",SEICODE_DOC:"1302",SEICODE_L2_DESC_E:"GPPE Feed And Utility Area A",SEICODE_L2_DESC_C:"GPPE 进料和共用工程A区",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:1},
      {NO:19,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121303",SEICODE_DOC:"1303",SEICODE_L2_DESC_E:"GPPE Feed And Utility Area B",SEICODE_L2_DESC_C:"GPPE 进料和共用工程B区",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:2},
      {NO:20,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121392",SEICODE_DOC:"1392",SEICODE_L2_DESC_E:"GPPE Substation",SEICODE_L2_DESC_C:"GPPE 变电所",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:3},
      {NO:21,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121391",SEICODE_DOC:"1391",SEICODE_L2_DESC_E:"GPPE RIE 5",SEICODE_L2_DESC_C:"GPPE 现场机柜室",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:6},
      {NO:22,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"121313",SEICODE_DOC:"1313",SEICODE_L2_DESC_E:"GPPE Granules Conveying System Of Line 1",SEICODE_L2_DESC_C:"GPPE 1线粉料输送风机单元",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:5},
      {NO:23,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"152805",SEICODE_DOC:"2805",SEICODE_L2_DESC_E:"Auxiliary Facility",SEICODE_L2_DESC_C:"辅助生产设施",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:10},
      {NO:24,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"152804",SEICODE_DOC:"2804",SEICODE_L2_DESC_E:"No.2 Package Plant",SEICODE_L2_DESC_C:"2#成品包装",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:11},
      {NO:25,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置",SEICODE_L2:"252702",SEICODE_DOC:"2527",SEICODE_L2_DESC_E:"Truck Scale2",SEICODE_L2_DESC_C:"汽车衡12",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:12},
      {NO:26,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121400",SEICODE_DOC:"1400",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_MAINPLANT:3},
      {NO:27,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121409",SEICODE_DOC:"1409",SEICODE_L2_DESC_E:"Flare Knock-Out",SEICODE_L2_DESC_C:"火炬罐",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:20},
      {NO:28,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121408",SEICODE_DOC:"1408",SEICODE_L2_DESC_E:"Chilled Water",SEICODE_L2_DESC_C:"冷冻水",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:2},
      {NO:29,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121492",SEICODE_DOC:"1492",SEICODE_L2_DESC_E:"LDPE Substation",SEICODE_L2_DESC_C:"LDPE变电所",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:3},
      {NO:30,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121407",SEICODE_DOC:"1407",SEICODE_L2_DESC_E:"Initiator Mixing",SEICODE_L2_DESC_C:"引发剂配置区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:4},
      {NO:31,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121402",SEICODE_DOC:"1402",SEICODE_L2_DESC_E:"Reaction Area",SEICODE_L2_DESC_C:"反应区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:18},
      {NO:32,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121406",SEICODE_DOC:"1406",SEICODE_L2_DESC_E:"Compressor Lubrication Cooling Oil",SEICODE_L2_DESC_C:"压缩机油站区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:5},
      {NO:33,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121401",SEICODE_DOC:"1401",SEICODE_L2_DESC_E:"Compressor House",SEICODE_L2_DESC_C:"压缩机厂房",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:14},
      {NO:34,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121411",SEICODE_DOC:"1411",SEICODE_L2_DESC_E:"Modifier and Solvent System",SEICODE_L2_DESC_C:"改性剂和溶剂系统",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:7},
      {NO:35,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121412",SEICODE_DOC:"1412",SEICODE_L2_DESC_E:"Vent Gas Treatment Package",SEICODE_L2_DESC_C:"RTO炉",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:8},
      {NO:36,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121413",SEICODE_DOC:"1413",SEICODE_L2_DESC_E:"Pellet Degassing Silos",SEICODE_L2_DESC_C:"脱气仓",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:9},
      {NO:37,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121415",SEICODE_DOC:"1415",SEICODE_L2_DESC_E:"At Line-LAB",SEICODE_L2_DESC_C:"在线分析室",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:13},
      {NO:38,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121414",SEICODE_DOC:"1414",SEICODE_L2_DESC_E:"Blend And Bagging Silos",SEICODE_L2_DESC_C:"掺混包装料仓",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:12},
      {NO:39,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121404",SEICODE_DOC:"1404",SEICODE_L2_DESC_E:"Purge Gas Area",SEICODE_L2_DESC_C:"排放气框架区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:15},
      {NO:40,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121405",SEICODE_DOC:"1405",SEICODE_L2_DESC_E:"Extrusion Area",SEICODE_L2_DESC_C:"挤压造粒区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:16},
      {NO:41,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121403",SEICODE_DOC:"1403",SEICODE_L2_DESC_E:"Hp Recycle Area",SEICODE_L2_DESC_C:"高压循环区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:17},
      {NO:42,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121410",SEICODE_DOC:"1410",SEICODE_L2_DESC_E:"Utility Water Area",SEICODE_L2_DESC_C:"公用工程区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:19},
      {NO:43,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121442",SEICODE_DOC:"1442",SEICODE_L2_DESC_E:"Sump Area",SEICODE_L2_DESC_C:"废水池",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:21},
      {NO:44,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"121491",SEICODE_DOC:"1491",SEICODE_L2_DESC_E:"LDPE RIE 3",SEICODE_L2_DESC_C:"现场机柜室",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:6},
      {NO:45,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"252701",SEICODE_DOC:"2527",SEICODE_L2_DESC_E:"Truck Scale1",SEICODE_L2_DESC_C:"汽车衡1",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:10},
      {NO:46,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"152803",SEICODE_DOC:"2803",SEICODE_L2_DESC_E:"No.1 Package Plant",SEICODE_L2_DESC_C:"1#成品包装",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:11},
      {NO:47,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE - Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置",SEICODE_L2:"152406",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 6",SEICODE_L2_DESC_C:"危险化学品库6",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:1},
      {NO:48,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121900",SEICODE_DOC:"1900",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_MAINPLANT:4},
      {NO:49,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121913",SEICODE_DOC:"1913",SEICODE_L2_DESC_E:"CPP-1 BULK POLYMERIZATION AREA",SEICODE_L2_DESC_C:"PP聚合区1线反应单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:3},
      {NO:50,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121914",SEICODE_DOC:"1914",SEICODE_L2_DESC_E:"CPP-1 GAS PHASE REACTORS,POLYMER DEGASSING AND GRANULES DRYING AREA",SEICODE_L2_DESC_C:"PP聚合区1线聚合干燥单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:5},
      {NO:51,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121916",SEICODE_DOC:"1916",SEICODE_L2_DESC_E:"CPP-1 TEAL SCRUBBER AND RECYCLE GAS COMPERSSOR AREA",SEICODE_L2_DESC_C:"PP聚合区1线TEAL洗涤和循环气压缩机单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:6},
      {NO:52,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121910",SEICODE_DOC:"1910",SEICODE_L2_DESC_E:"CPP-1 POLYMERIZATION AREA",SEICODE_L2_DESC_C:"PP聚合区1线",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:1},
      {NO:53,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121942",SEICODE_DOC:"1942",SEICODE_L2_DESC_E:"PP WASTE WATER/STORM WATER AREA",SEICODE_L2_DESC_C:"PP废水池",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:7},
      {NO:54,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121901",SEICODE_DOC:"1901",SEICODE_L2_DESC_E:"PP BATTERY LIMIT PIPE RACK",SEICODE_L2_DESC_C:"聚丙烯装置街区管廊",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:9},
      {NO:55,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121903",SEICODE_DOC:"1903",SEICODE_L2_DESC_E:"PP GRANULES TRANSFER AND EXTRUSION BUILDING",SEICODE_L2_DESC_C:"PP粉料输送和挤压造粒厂房",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:8},
      {NO:56,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121905",SEICODE_DOC:"1905",SEICODE_L2_DESC_E:"PP PELLET ELUTRIATION AND BAGGING AREA",SEICODE_L2_DESC_C:"PP淘析和成品包装",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:11},
      {NO:57,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121904",SEICODE_DOC:"1904",SEICODE_L2_DESC_E:"PP PELLET SILOS AREA",SEICODE_L2_DESC_C:"PP颗粒料仓",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:12},
      {NO:58,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121909",SEICODE_DOC:"1909",SEICODE_L2_DESC_E:"PP REGENERATIVE THERMAL OXIDIZER AREA",SEICODE_L2_DESC_C:"PP RTO单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:13},
      {NO:59,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121992",SEICODE_DOC:"1992",SEICODE_L2_DESC_E:"PP Substation",SEICODE_L2_DESC_C:"PP 变电所",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:15},
      {NO:60,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121932",SEICODE_DOC:"1932",SEICODE_L2_DESC_E:"PP CHEMICAL STORAGE",SEICODE_L2_DESC_C:"化学品间",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:14},
      {NO:61,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121926",SEICODE_DOC:"1926",SEICODE_L2_DESC_E:"CPP-2 TEAL SCRUBBER AND RECYCLE GAS COMPERSSOR AREA",SEICODE_L2_DESC_C:"PP聚合区2线TEAL洗涤和循环气压缩机单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:19},
      {NO:62,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121920",SEICODE_DOC:"1920",SEICODE_L2_DESC_E:"CPP-2 POLYMERIZATION AREA",SEICODE_L2_DESC_C:"PP聚合区2线",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:29},
      {NO:63,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121922",SEICODE_DOC:"1922",SEICODE_L2_DESC_E:"CPP-2 CATALYST PREPARATION AND METERING AREA",SEICODE_L2_DESC_C:"PP聚合区2线催化剂制备单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:20},
      {NO:64,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121921",SEICODE_DOC:"1921",SEICODE_L2_DESC_E:"CPP-2 PROPYLENE FEED AREA",SEICODE_L2_DESC_C:"PP聚合区2线丙烯进料单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:21},
      {NO:65,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121991",SEICODE_DOC:"1991",SEICODE_L2_DESC_E:"PP RIE 4",SEICODE_L2_DESC_C:"PP 现场机柜室",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:17},
      {NO:66,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121931",SEICODE_DOC:"1931",SEICODE_L2_DESC_E:"PP NEW LUBE/WASTE OIL DRUM STORAGE",SEICODE_L2_DESC_C:"PP新鲜油/废油储存间",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:18},
      {NO:67,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121906",SEICODE_DOC:"1906",SEICODE_L2_DESC_E:"PP HYDROGEN COMPRESSOR UNIT",SEICODE_L2_DESC_C:"PP氢气压缩机单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:22},
      {NO:68,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121902",SEICODE_DOC:"1902",SEICODE_L2_DESC_E:"PP TEAL FACILITY AREA",SEICODE_L2_DESC_C:"PP烷基铝配制",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:23},
      {NO:69,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121908",SEICODE_DOC:"1908",SEICODE_L2_DESC_E:"PP OIL TREATMENT,CHILLED WATER AND STEAM CONDENSATE DRUM AREA",SEICODE_L2_DESC_C:"PP油处理,冷冻水和蒸汽凝液单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:24},
      {NO:70,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121925",SEICODE_DOC:"1925",SEICODE_L2_DESC_E:"CPP-2 POLYMERIZATION AREA PIPE RACK",SEICODE_L2_DESC_C:"PP聚合区2线管廊",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:25},
      {NO:71,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121927",SEICODE_DOC:"1927",SEICODE_L2_DESC_E:"CPP-2 BLOWDOWN AND ETHYLENE COMPRESSOR AREA",SEICODE_L2_DESC_C:"PP聚合区2线火炬和乙烯压缩机单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:28},
      {NO:72,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121907",SEICODE_DOC:"1907",SEICODE_L2_DESC_E:"PP OIL/GREASE STORAGE AND MIXING,PROPYLENE DRYER AREA",SEICODE_L2_DESC_C:"PP油/脂储存和混合,及丙烯干燥单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:26},
      {NO:73,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121923",SEICODE_DOC:"1923",SEICODE_L2_DESC_E:"CPP-2 BULK POLYMERIZATION AREA",SEICODE_L2_DESC_C:"PP聚合区2线反应单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:27},
      {NO:74,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121924",SEICODE_DOC:"1924",SEICODE_L2_DESC_E:"CPP-2 POLYMER DEGASSING AND GRANULES DRYING AREA",SEICODE_L2_DESC_C:"PP聚合区2线汽蒸干燥单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:16},
      {NO:75,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121915",SEICODE_DOC:"1915",SEICODE_L2_DESC_E:"CPP-1 POLYMERIZATION AREA PIPE RACK",SEICODE_L2_DESC_C:"PP聚合区1线管廊",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:31},
      {NO:76,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121911",SEICODE_DOC:"1911",SEICODE_L2_DESC_E:"CPP-1 PROPYLENE FEED AND STEAM CONDENSATE DRUM AREA",SEICODE_L2_DESC_C:"PP聚合区1线丙烯进料和蒸汽凝液罐单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:30},
      {NO:77,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121912",SEICODE_DOC:"1912",SEICODE_L2_DESC_E:"CPP-1 CATALYST PREPARATION AND METERING AREA",SEICODE_L2_DESC_C:"PP聚合区1线催化剂制备单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:4},
      {NO:78,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP - Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置",SEICODE_L2:"121917",SEICODE_DOC:"1917",SEICODE_L2_DESC_E:"CPP-1 BLOWDOWN AREA",SEICODE_L2_DESC_C:"PP聚合区1线火炬单元",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:2},
      {NO:79,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127700",SEICODE_L1_DESC_E:"BD - Butadiene Unit",SEICODE_L1_DESC_C:"丁二烯抽提装置",SEICODE_L2:"127700",SEICODE_DOC:"7700",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_MAINPLANT:5},
      {NO:80,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127700",SEICODE_L1_DESC_E:"BD - Butadiene Unit",SEICODE_L1_DESC_C:"丁二烯抽提装置",SEICODE_L2:"127791",SEICODE_DOC:"7791",SEICODE_L2_DESC_E:"RIE-2",SEICODE_L2_DESC_C:"联合现场机柜室",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:2},
      {NO:81,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127700",SEICODE_L1_DESC_E:"BD - Butadiene Unit",SEICODE_L1_DESC_C:"丁二烯抽提装置",SEICODE_L2:"127742",SEICODE_DOC:"7742",SEICODE_L2_DESC_E:"Co-product Waste Water Sump",SEICODE_L2_DESC_C:"联合装置废水池",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:3},
      {NO:82,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127700",SEICODE_L1_DESC_E:"BD - Butadiene Unit",SEICODE_L1_DESC_C:"丁二烯抽提装置",SEICODE_L2:"127792",SEICODE_DOC:"7792",SEICODE_L2_DESC_E:"Co-product Substation",SEICODE_L2_DESC_C:"联合变电所 ",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:1},
      {NO:83,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127800",SEICODE_L1_DESC_E:"GHU - Gasoline Hydrotreating Unit",SEICODE_L1_DESC_C:"裂解汽油加氢装置",SEICODE_L2:"127800",SEICODE_DOC:"7800",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:1,SVG_MAINPLANT:6},
      {NO:84,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127900",SEICODE_DOC:"7900",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_MAINPLANT:7},
      {NO:85,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127906",SEICODE_DOC:"7906",SEICODE_L2_DESC_E:"PSA Area",SEICODE_L2_DESC_C:"PSA单元",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:4},
      {NO:86,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127904",SEICODE_DOC:"7904",SEICODE_L2_DESC_E:"Cold Fraction Area",SEICODE_L2_DESC_C:"冷区",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:5},
      {NO:87,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127905",SEICODE_DOC:"7905",SEICODE_L2_DESC_E:"Hot Fraction Area",SEICODE_L2_DESC_C:"热区",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:6},
      {NO:88,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127991",SEICODE_DOC:"7991",SEICODE_L2_DESC_E:"RIE-1",SEICODE_L2_DESC_C:"现场机柜室",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:7},
      {NO:89,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127992",SEICODE_DOC:"7992",SEICODE_L2_DESC_E:"OR 1st Substation",SEICODE_L2_DESC_C:"第一变电所",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:8},
      {NO:90,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127993",SEICODE_DOC:"7993",SEICODE_L2_DESC_E:"Water Spray",SEICODE_L2_DESC_C:"雨淋阀",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:10},
      {NO:91,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127909",SEICODE_DOC:"7909",SEICODE_L2_DESC_E:"Benzene Removal Area",SEICODE_L2_DESC_C:"污水脱苯单元",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:11},
      {NO:92,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127942",SEICODE_DOC:"7942",SEICODE_L2_DESC_E:"Waste Water Sump",SEICODE_L2_DESC_C:"废水池",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:12},
      {NO:93,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127902",SEICODE_DOC:"7902",SEICODE_L2_DESC_E:"Quench Area",SEICODE_L2_DESC_C:"急冷区",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:9},
      {NO:94,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127901",SEICODE_DOC:"7901",SEICODE_L2_DESC_E:"Furnaces Area",SEICODE_L2_DESC_C:"裂解炉区",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:13},
      {NO:95,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127903",SEICODE_DOC:"7903",SEICODE_L2_DESC_E:"Compression Area",SEICODE_L2_DESC_C:"压缩区",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:3},
      {NO:96,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127908",SEICODE_DOC:"7908",SEICODE_L2_DESC_E:"Steam & Condensate Area",SEICODE_L2_DESC_C:"蒸汽及凝液单元",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:2},
      {NO:97,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127982",SEICODE_DOC:"7982",SEICODE_L2_DESC_E:"OR 2nd Substation",SEICODE_L2_DESC_C:"第二变电所",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:14},
      {NO:98,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置",SEICODE_L2:"127907",SEICODE_DOC:"7907",SEICODE_L2_DESC_E:"Spent Caustic Area",SEICODE_L2_DESC_C:"废碱氧化单元",CON_CODE:"STCC",CON_DESC_E:"Sinopec Tenth Construction Company",CON_DESC_C:"中石化第十建设有限公司",JIANLI_CODE:"YPES",JIANLI_DESC_E:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd                                       ",JIANLI_DESC_C:"南京扬子石化工程监理有限责任公司",SVG_SN:1},
      {NO:99,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"136200",SEICODE_L1_DESC_E:"DIB - DI-Isobutylene Unit",SEICODE_L1_DESC_C:"DIB装置",SEICODE_L2:"136200",SEICODE_DOC:"6200",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:1,SVG_MAINPLANT:8},
      {NO:100,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",SEICODE_L2:"151000",SEICODE_DOC:"1510",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"GDHJ",CON_DESC_E:"Guangdong Chemical Construction",CON_DESC_C:"广东化建",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_MAINPLANT:9},
      {NO:101,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",SEICODE_L2:"180401",SEICODE_DOC:"1804",SEICODE_L2_DESC_E:"Waste Water Pump Station 1",SEICODE_L2_DESC_C:"污水提升泵站1",CON_CODE:"GDHJ",CON_DESC_E:"Guangdong Chemical Construction",CON_DESC_C:"广东化建",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:1},
      {NO:102,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",SEICODE_L2:"183100",SEICODE_DOC:"1831",SEICODE_L2_DESC_E:"Area B+ Substation",SEICODE_L2_DESC_C:"厂前区变电所",CON_CODE:"GDHJ",CON_DESC_E:"Guangdong Chemical Construction",CON_DESC_C:"广东化建",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:3},
      {NO:103,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",SEICODE_L2:"152000",SEICODE_DOC:"1520",SEICODE_L2_DESC_E:"Central Laboratory and Environmental Monitoring Station",SEICODE_L2_DESC_C:"中心化验室及环境监测站",CON_CODE:"GDHJ",CON_DESC_E:"Guangdong Chemical Construction",CON_DESC_C:"广东化建",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:2},
      {NO:104,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",SEICODE_L2:"186000",SEICODE_DOC:"1860",SEICODE_L2_DESC_E:"Control Center Building",SEICODE_L2_DESC_C:"中心控制室",CON_CODE:"GDHJ",CON_DESC_E:"Guangdong Chemical Construction",CON_DESC_C:"广东化建",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:6},
      {NO:105,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",SEICODE_L2:"381006",SEICODE_DOC:"3810",SEICODE_L2_DESC_E:"Firewater Pump Station B+",SEICODE_L2_DESC_C:"厂前区消防水泵站",CON_CODE:"GDHJ",CON_DESC_E:"Guangdong Chemical Construction",CON_DESC_C:"广东化建",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:4},
      {NO:106,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",SEICODE_L2:"187501",SEICODE_DOC:"1875",SEICODE_L2_DESC_E:"Telecom equipment room",SEICODE_L2_DESC_C:"电信机柜间",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:7},
      {NO:107,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区",SEICODE_L2:"154000",SEICODE_DOC:"1540",SEICODE_L2_DESC_E:"Administration Office Building B (including canteen & locker room)",SEICODE_L2_DESC_C:"综合办公楼B(包含餐厅和更衣室)",CON_CODE:"ZGLY",CON_DESC_E:"China Sixth Metallurgical Corporation",CON_DESC_C:"中国六冶",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:5},
      {NO:108,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152700",SEICODE_L1_DESC_E:"Main plant Dispatch Area",SEICODE_L1_DESC_C:"停车场",SEICODE_L2:"152700",SEICODE_DOC:"1527",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:1,SVG_MAINPLANT:11},
      {NO:109,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"152801",SEICODE_L1_DESC_E:"No.1 Warehouse",SEICODE_L1_DESC_C:"1#立体仓库",SEICODE_L2:"152801",SEICODE_DOC:"2801",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:1,SVG_MAINPLANT:12},
      {NO:110,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"152802",SEICODE_L1_DESC_E:"No.2 Warehouse",SEICODE_L1_DESC_C:"2#立体仓库",SEICODE_L2:"152802",SEICODE_DOC:"2802",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"FCC",CON_DESC_E:"Sinopec Fourth Construction Company",CON_DESC_C:"中石化第四建设有限公司",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_SN:1,SVG_MAINPLANT:13},
      {NO:111,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"170000",SEICODE_DOC:"1700",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_MAINPLANT:14},
      {NO:112,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"170100",SEICODE_DOC:"1701",SEICODE_L2_DESC_E:"Ethylene Cryogenic Tank Farm",SEICODE_L2_DESC_C:"乙烯低温罐区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:5},
      {NO:113,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"181301",SEICODE_DOC:"1813",SEICODE_L2_DESC_E:"Foam Station 1",SEICODE_L2_DESC_C:"泡沫站1",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:6},
      {NO:114,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"170300",SEICODE_DOC:"1703",SEICODE_L2_DESC_E:"Pressurized Tank Farm 1",SEICODE_L2_DESC_C:"压力罐区1",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:7},
      {NO:115,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"124700",SEICODE_DOC:"1247",SEICODE_L2_DESC_E:"Propylene Tank Farm",SEICODE_L2_DESC_C:"丙烯罐区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:8},
      {NO:116,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"270300",SEICODE_DOC:"2703",SEICODE_L2_DESC_E:"Pressurized Tank Farm 2",SEICODE_L2_DESC_C:"压力罐区2",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1},
      {NO:117,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"180402",SEICODE_DOC:"1804",SEICODE_L2_DESC_E:"Waste Water Pump Station 2",SEICODE_L2_DESC_C:"污水提升泵站2",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:2},
      {NO:118,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"270100",SEICODE_DOC:"2701",SEICODE_L2_DESC_E:"Offspec Ethylene Tank Farm",SEICODE_L2_DESC_C:"不合格乙烯罐区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:3},
      {NO:119,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区",SEICODE_L2:"370100",SEICODE_DOC:"3701",SEICODE_L2_DESC_E:"Hexene-1 Tank Farm",SEICODE_L2_DESC_C:"己烯-1罐区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:4},
      {NO:120,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178000",SEICODE_L1_DESC_E:"Loading/Unloading Station",SEICODE_L1_DESC_C:"汽车装卸设施",SEICODE_L2:"178000",SEICODE_DOC:"1780",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"CNCEC",CON_DESC_E:"China National Chemical Engineering Co. Ltd. 13th Company",CON_DESC_C:"十三化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1,SVG_MAINPLANT:16},
      {NO:121,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178000",SEICODE_L1_DESC_E:"Loading/Unloading Station",SEICODE_L1_DESC_C:"汽车装卸设施",SEICODE_L2:"178001",SEICODE_DOC:"1780",SEICODE_L2_DESC_E:"Station Building",SEICODE_L2_DESC_C:"站房",CON_CODE:"CNCEC",CON_DESC_E:"China National Chemical Engineering Co. Ltd. 13th Company",CON_DESC_C:"十三化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:2},
      {NO:122,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178900",SEICODE_L1_DESC_E:"Ground Flare",SEICODE_L1_DESC_C:"地面火炬",SEICODE_L2:"178900",SEICODE_DOC:"1789",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SYPEC",CON_DESC_E:"Shandong ShengYue Petrochemical Engineering Construction Co. Ltd.",CON_DESC_C:"山东胜越",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:3,SVG_MAINPLANT:17},
      {NO:123,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178900",SEICODE_L1_DESC_E:"Ground Flare",SEICODE_L1_DESC_C:"地面火炬",SEICODE_L2:"178600",SEICODE_DOC:"1786",SEICODE_L2_DESC_E:"Elevated Flare",SEICODE_L2_DESC_C:"高架火炬",CON_CODE:"SYPEC",CON_DESC_E:"Shandong ShengYue Petrochemical Engineering Construction Co. Ltd.",CON_DESC_C:"山东胜越",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:4},
      {NO:124,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178900",SEICODE_L1_DESC_E:"Ground Flare",SEICODE_L1_DESC_C:"地面火炬",SEICODE_L2:"178992",SEICODE_DOC:"1789",SEICODE_L2_DESC_E:"Substation",SEICODE_L2_DESC_C:"变电所",CON_CODE:"SYPEC",CON_DESC_E:"Shandong ShengYue Petrochemical Engineering Construction Co. Ltd.",CON_DESC_C:"山东胜越",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1},
      {NO:126,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178900",SEICODE_L1_DESC_E:"Ground Flare",SEICODE_L1_DESC_C:"地面火炬",SEICODE_L2:"166800",SEICODE_DOC:"1668",SEICODE_L2_DESC_E:"Ammonia Water Tank Farm",SEICODE_L2_DESC_C:"氨水罐区",CON_CODE:"CNCEC",CON_DESC_E:"China National Chemical Engineering Co. Ltd. 13th Company",CON_DESC_C:"十三化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:2},
      {NO:127,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180600",SEICODE_L1_DESC_E:"Storm Water Pump Station and Accident Buffer Basin",SEICODE_L1_DESC_C:"雨水泵站及事故水池",SEICODE_L2:"180600",SEICODE_DOC:"1806",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"CNCEC",CON_DESC_E:"China National Chemical Engineering Co. Ltd. 13th Company",CON_DESC_C:"十三化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1,SVG_MAINPLANT:20},
      {NO:128,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"181000",SEICODE_L1_DESC_E:"Water and Firewater Pump Station 1",SEICODE_L1_DESC_C:"第一给水及消防泵站",SEICODE_L2:"181000",SEICODE_DOC:"1810",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_SN:1,SVG_MAINPLANT:21},
      {NO:129,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"182400",SEICODE_L1_DESC_E:"Wastewater Pre-Treatment Unit",SEICODE_L1_DESC_C:"污水预处理场",SEICODE_L2:"182400",SEICODE_DOC:"1824",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"CNCEC",CON_DESC_E:"China National Chemical Engineering Co. Ltd. 13th Company",CON_DESC_C:"十三化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:2,SVG_MAINPLANT:22},
      {NO:130,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"182400",SEICODE_L1_DESC_E:"Wastewater Pre-Treatment Unit",SEICODE_L1_DESC_C:"污水预处理场",SEICODE_L2:"182492",SEICODE_DOC:"1824",SEICODE_L2_DESC_E:"Substation",SEICODE_L2_DESC_C:"变电所",CON_CODE:"CNCEC",CON_DESC_E:"China National Chemical Engineering Co. Ltd. 13th Company",CON_DESC_C:"十三化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1},
      {NO:131,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"183000",SEICODE_L1_DESC_E:"Main Substation",SEICODE_L1_DESC_C:"总变电站",SEICODE_L2:"183000",SEICODE_DOC:"1830",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"HNEDI",CON_DESC_E:"Hainan Electric",CON_DESC_C:"中国电建集团海南电力设计研究院有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_SN:1,SVG_MAINPLANT:23},
      {NO:132,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184300",SEICODE_L1_DESC_E:"Demineralized Water Unit",SEICODE_L1_DESC_C:"脱盐水站",SEICODE_L2:"184300",SEICODE_DOC:"4300",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_MAINPLANT:24},
      {NO:133,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184300",SEICODE_L1_DESC_E:"Demineralized Water Unit",SEICODE_L1_DESC_C:"脱盐水站",SEICODE_L2:"184301",SEICODE_DOC:"4301",SEICODE_L2_DESC_E:"Chemical Water Treatment",SEICODE_L2_DESC_C:"化学水处理单元",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:3},
      {NO:134,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184300",SEICODE_L1_DESC_E:"Demineralized Water Unit",SEICODE_L1_DESC_C:"脱盐水站",SEICODE_L2:"184302",SEICODE_DOC:"4302",SEICODE_L2_DESC_E:"Condensate Water Treatment",SEICODE_L2_DESC_C:"凝结水单元",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:2},
      {NO:135,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184300",SEICODE_L1_DESC_E:"Demineralized Water Unit",SEICODE_L1_DESC_C:"脱盐水站",SEICODE_L2:"184303",SEICODE_DOC:"4303",SEICODE_L2_DESC_E:"Acid & Caustic Station",SEICODE_L2_DESC_C:"酸碱站",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:1},
      {NO:136,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184800",SEICODE_L1_DESC_E:"Boiler Unit",SEICODE_L1_DESC_C:"锅炉装置",SEICODE_L2:"184800",SEICODE_DOC:"4800",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_MAINPLANT:25},
      {NO:137,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184800",SEICODE_L1_DESC_E:"Boiler Unit",SEICODE_L1_DESC_C:"锅炉装置",SEICODE_L2:"184891",SEICODE_DOC:"4891",SEICODE_L2_DESC_E:"RIE-6",SEICODE_L2_DESC_C:"联合现场机柜室",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:1},
      {NO:138,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184800",SEICODE_L1_DESC_E:"Boiler Unit",SEICODE_L1_DESC_C:"锅炉装置",SEICODE_L2:"184892",SEICODE_DOC:"4892",SEICODE_L2_DESC_E:"Substation",SEICODE_L2_DESC_C:"变电所",CON_CODE:"SNEI",CON_DESC_E:"Sinopec Nanjing Engineering Co. Ltd",CON_DESC_C:"中石化南京工程有限公司",JIANLI_CODE:"BJHX",JIANLI_DESC_E:"Beijign Huaxia Supervision Co., Ltd.                                ",JIANLI_DESC_C:"北京华夏石化工程监理有限公司",SVG_SN:2},
      {NO:139,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"185300",SEICODE_L1_DESC_E:"Air Compression Station",SEICODE_L1_DESC_C:"空压站",SEICODE_L2:"185300",SEICODE_DOC:"1853",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_MAINPLANT:26},
      {NO:140,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"185300",SEICODE_L1_DESC_E:"Air Compression Station",SEICODE_L1_DESC_C:"空压站",SEICODE_L2:"185392",SEICODE_DOC:"1853",SEICODE_L2_DESC_E:"Substation",SEICODE_L2_DESC_C:"变电所",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_SN:1},
      {NO:141,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"185300",SEICODE_L1_DESC_E:"Air Compression Station",SEICODE_L1_DESC_C:"空压站",SEICODE_L2:"185394",SEICODE_DOC:"1853",SEICODE_L2_DESC_E:"Operator Shelter",SEICODE_L2_DESC_C:"外操间",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_SN:2},
      {NO:142,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"277000",SEICODE_DOC:"2770",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_MAINPLANT:27},
      {NO:143,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"168000",SEICODE_DOC:"1680",SEICODE_L2_DESC_E:"Naphtha Tank Farm",SEICODE_L2_DESC_C:"石脑油罐区",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1},
      {NO:144,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"177100",SEICODE_DOC:"1771",SEICODE_L2_DESC_E:"Intermediate Tank Farm 1",SEICODE_L2_DESC_C:"中间罐区1",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:2},
      {NO:145,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"181302",SEICODE_DOC:"1813",SEICODE_L2_DESC_E:"Foam Station 2",SEICODE_L2_DESC_C:"泡沫站2",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:3},
      {NO:146,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"180403",SEICODE_DOC:"1804",SEICODE_L2_DESC_E:"Waste Water Pump Station 3",SEICODE_L2_DESC_C:"污水提升泵站3",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:4},
      {NO:147,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"179600",SEICODE_DOC:"1796",SEICODE_L2_DESC_E:"Vapor Gas Recovery Unit",SEICODE_L2_DESC_C:"油气回收处理设施",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:5},
      {NO:148,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"181303",SEICODE_DOC:"1813",SEICODE_L2_DESC_E:"Foam Station 3",SEICODE_L2_DESC_C:"泡沫站3",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:6},
      {NO:149,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"181304",SEICODE_DOC:"1813",SEICODE_L2_DESC_E:"Foam Station 4",SEICODE_L2_DESC_C:"泡沫站4",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:7},
      {NO:150,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区",SEICODE_L2:"277082",SEICODE_DOC:"2770",SEICODE_L2_DESC_E:"Tank Farm 2nd Substation",SEICODE_L2_DESC_C:"罐区第二变电所",CON_CODE:"CC7",CON_DESC_E:"China Chemical Construction No. 7 Company",CON_DESC_C:"七化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:8},
      {NO:151,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277100",SEICODE_L1_DESC_E:"Intermediate Tank Farm 2",SEICODE_L1_DESC_C:"中间罐区2",SEICODE_L2:"277100",SEICODE_DOC:"2771",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"WDECE",CON_DESC_E:"Jiangsu Weida Construction Group Co. Ltd.",CON_DESC_C:"威达建设",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:2,SVG_MAINPLANT:28},
      {NO:152,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277100",SEICODE_L1_DESC_E:"Intermediate Tank Farm 2",SEICODE_L1_DESC_C:"中间罐区2",SEICODE_L2:"170800",SEICODE_DOC:"1708",SEICODE_L2_DESC_E:"Mogas Tank Farm",SEICODE_L2_DESC_C:"汽油调和罐区",CON_CODE:"WDECE",CON_DESC_E:"Jiangsu Weida Construction Group Co. Ltd.",CON_DESC_C:"威达建设",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1},
      {NO:153,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277100",SEICODE_L1_DESC_E:"Intermediate Tank Farm 2",SEICODE_L1_DESC_C:"中间罐区2",SEICODE_L2:"377100",SEICODE_DOC:"3771",SEICODE_L2_DESC_E:"Intermediate Tank Farm 3",SEICODE_L2_DESC_C:"中间罐区3",CON_CODE:"WDECE",CON_DESC_E:"Jiangsu Weida Construction Group Co. Ltd.",CON_DESC_C:"威达建设",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:3},
      {NO:154,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"280200",SEICODE_L1_DESC_E:"Cooling Water System 2",SEICODE_L1_DESC_C:"第二循环水场",SEICODE_L2:"280200",SEICODE_DOC:"2802",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_MAINPLANT:29},
      {NO:155,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"280200",SEICODE_L1_DESC_E:"Cooling Water System 2",SEICODE_L1_DESC_C:"第二循环水场",SEICODE_L2:"280292",SEICODE_DOC:"2802",SEICODE_L2_DESC_E:"Substation",SEICODE_L2_DESC_C:"变电所",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_SN:1},
      {NO:156,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"280200",SEICODE_L1_DESC_E:"Cooling Water System 2",SEICODE_L1_DESC_C:"第二循环水场",SEICODE_L2:"280294",SEICODE_DOC:"2802",SEICODE_L2_DESC_E:"Operator Shelter",SEICODE_L2_DESC_C:"外操间",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_SN:2},
      {NO:157,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180200",SEICODE_L1_DESC_E:"Cooling Water System 1",SEICODE_L1_DESC_C:"第一循环水场",SEICODE_L2:"180200",SEICODE_DOC:"1802",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_SN:1,SVG_MAINPLANT:18},
      {NO:158,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152400",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_MAINPLANT:10},
      {NO:159,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"181200",SEICODE_DOC:"1812",SEICODE_L2_DESC_E:"FF & Gas Protection Station",SEICODE_L2_DESC_C:"消防及气防站",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:1},
      {NO:160,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"180404",SEICODE_DOC:"1804",SEICODE_L2_DESC_E:"Waste Water Pump Station 4 For Chemical Warehouse",SEICODE_L2_DESC_C:"污水提升泵站 4",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:2},
      {NO:161,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152300",SEICODE_DOC:"1523",SEICODE_L2_DESC_E:"Warehouse Management Room and Spare Parts Warehouse",SEICODE_L2_DESC_C:"全厂性仓库",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:8},
      {NO:162,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"182200",SEICODE_DOC:"1822",SEICODE_L2_DESC_E:"Hazardous waste temporary warehouse",SEICODE_L2_DESC_C:"危废暂存库",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:3},
      {NO:163,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152200",SEICODE_DOC:"1522",SEICODE_L2_DESC_E:"Maintenance Workshop",SEICODE_L2_DESC_C:"维修中心",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:10},
      {NO:164,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152408",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 8",SEICODE_L2_DESC_C:"危险化学品库8",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:9},
      {NO:165,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152492",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Substation",SEICODE_L2_DESC_C:"配电间",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:14},
      {NO:166,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152402",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 2",SEICODE_L2_DESC_C:"危险化学品库2",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:4},
      {NO:167,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152405",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 5",SEICODE_L2_DESC_C:"危险化学品库5",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:13},
      {NO:168,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152407",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 7",SEICODE_L2_DESC_C:"危险化学品库1",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:12},
      {NO:169,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152403",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 3",SEICODE_L2_DESC_C:"危险化学品库3",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:11},
      {NO:170,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152404",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 4",SEICODE_L2_DESC_C:"危险化学品库4",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:7},
      {NO:171,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"252703",SEICODE_DOC:"2527",SEICODE_L2_DESC_E:"Truck Scale3",SEICODE_L2_DESC_C:"汽车衡3",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:6},
      {NO:172,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库",SEICODE_L2:"152401",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 1",SEICODE_L2_DESC_C:"危险化学品库1",CON_CODE:"HZDJ",CON_DESC_E:"Huizhou Dong Jiang",CON_DESC_C:"惠州市东江建筑安装工程有限公司",JIANLI_CODE:"TJU",JIANLI_DESC_E:"Shanghai TJU Engineering Service Co., Ltd.                                ",JIANLI_DESC_C:"上海协同工程咨询有限公司",SVG_SN:5},
      {NO:173,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180292",SEICODE_L1_DESC_E:"Substation",SEICODE_L1_DESC_C:"变电所",SEICODE_L2:"180292",SEICODE_DOC:"1802",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"TJCD",JIANLI_DESC_E:"Tianjin Chenda Engineering Supervision Co., Ltd.                                    ",JIANLI_DESC_C:"天津辰达工程监理有限公司",SVG_SN:1,SVG_MAINPLANT:19},
      {NO:174,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170092",SEICODE_L1_DESC_E:"Tank Farm 1st Substation",SEICODE_L1_DESC_C:"罐区第一变电所",SEICODE_L2:"170092",SEICODE_DOC:"1700",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:2,SVG_MAINPLANT:15},
      {NO:175,CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170092",SEICODE_L1_DESC_E:"Tank Farm 1st Substation",SEICODE_L1_DESC_C:"罐区第一变电所",SEICODE_L2:"170091",SEICODE_DOC:"1700",SEICODE_L2_DESC_E:"7RIE 7£¨W/OS )",SEICODE_L2_DESC_C:"现场机柜室",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1},
      {NO:176,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"281000",SEICODE_L1_DESC_E:"Water and Firewater Pump Station 2",SEICODE_L1_DESC_C:"第二给水及消防泵站",SEICODE_L2:"281000",SEICODE_DOC:"2810",SEICODE_L2_DESC_E:"Common Area",SEICODE_L2_DESC_C:"公共区",CON_CODE:"CNCEC",CON_DESC_E:"China National Chemical Engineering Co. Ltd. 13th Company",CON_DESC_C:"十三化建",JIANLI_CODE:"JSS",JIANLI_DESC_E:"Shanghai Jinshen Engineering Project Management Co., Ltd.                                ",JIANLI_DESC_C:"上海金申工程建设监理有限公司",SVG_SN:1,SVG_MAINPLANT:30},
      {NO:177,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179000",SEICODE_L1_DESC_E:"Overall Interconnecting Pipe (A.G.) 1",SEICODE_L1_DESC_C:"整体连通管",SEICODE_L2:"179000",SEICODE_DOC:"1790",SEICODE_L2_DESC_E:"Overall Interconnecting Pipe (A.G.) 1",SEICODE_L2_DESC_C:"通盘",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司"},
      {NO:178,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179001",SEICODE_L1_DESC_E:"Pipe Galley #1",SEICODE_L1_DESC_C:"管道舱 #1",SEICODE_L2:"179001",SEICODE_L2_DESC_E:"Pipe Galley #1",SEICODE_L2_DESC_C:"管道舱 #1",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_MAINPLANT:31},
      {NO:179,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179002",SEICODE_L1_DESC_E:"Pipe Galley #2",SEICODE_L1_DESC_C:"管道舱 #2",SEICODE_L2:"179002",SEICODE_L2_DESC_E:"Pipe Galley #2",SEICODE_L2_DESC_C:"管道舱 #2",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_MAINPLANT:32},
      {NO:180,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179003",SEICODE_L1_DESC_E:"Pipe Galley #3",SEICODE_L1_DESC_C:"管道舱 #3",SEICODE_L2:"179003",SEICODE_L2_DESC_E:"Pipe Galley #3",SEICODE_L2_DESC_C:"管道舱 #3",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_MAINPLANT:33},
      {NO:181,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179004",SEICODE_L1_DESC_E:"Pipe Galley #4",SEICODE_L1_DESC_C:"管道舱 #4",SEICODE_L2:"179004",SEICODE_L2_DESC_E:"Pipe Galley #4",SEICODE_L2_DESC_C:"管道舱 #4",CON_CODE:"SFCC",CON_DESC_E:"Sinopec Fifth Construction Company",CON_DESC_C:"中石化第五建设有限公司",JIANLI_CODE:"Gosun",JIANLI_DESC_E:"Guangdong Gosun Engineering Management Co., Ltd.                                ",JIANLI_DESC_C:"广东国信工程监理集团有限公司",SVG_MAINPLANT:34},
      {NO:182,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179100",SEICODE_L1_DESC_E:"Overall Interconnecting Pipe (A.G.) 2",SEICODE_L1_DESC_C:"整体连通管",SEICODE_L2:"179100",SEICODE_DOC:"1791",SEICODE_L2_DESC_E:"Overall Interconnecting Pipe (A.G.) 2",SEICODE_L2_DESC_C:"通盘",CON_CODE:"SPC",CON_DESC_E:"Shanxi Petroleum",CON_DESC_C:"陕西化建",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司"},
      {NO:183,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179005",SEICODE_L1_DESC_E:"Pipe Galley #5",SEICODE_L1_DESC_C:"管道舱 #5",SEICODE_L2:"179005",SEICODE_L2_DESC_E:"Pipe Galley #5",SEICODE_L2_DESC_C:"管道舱 #5",CON_CODE:"SPC",CON_DESC_E:"Shanxi Petroleum",CON_DESC_C:"陕西化建",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_MAINPLANT:35},
      {NO:184,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179006",SEICODE_L1_DESC_E:"Pipe Galley #6",SEICODE_L1_DESC_C:"管道舱 #6",SEICODE_L2:"179006",SEICODE_L2_DESC_E:"Pipe Galley #6",SEICODE_L2_DESC_C:"管道舱 #6",CON_CODE:"SPC",CON_DESC_E:"Shanxi Petroleum",CON_DESC_C:"陕西化建",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_MAINPLANT:36},
      {NO:185,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179007",SEICODE_L1_DESC_E:"Pipe Galley #7",SEICODE_L1_DESC_C:"管道舱 #7",SEICODE_L2:"179007",SEICODE_L2_DESC_E:"Pipe Galley #7",SEICODE_L2_DESC_C:"管道舱 #7",CON_CODE:"SPC",CON_DESC_E:"Shanxi Petroleum",CON_DESC_C:"陕西化建",JIANLI_CODE:"QYECC",JIANLI_DESC_E:"Qingdao Yueyang Engineering consultant Co., Ltd.                                 ",JIANLI_DESC_C:"青岛越洋工程咨询有限公司",SVG_MAINPLANT:37},
      {NO:186,CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Polymer Bagging and Logistics",SEICODE_L1_DESC_C:"聚烯烃包装及立体库",SEICODE_L2:"152800",SEICODE_DOC:"2800",SEICODE_L2_DESC_E:"PBL Battery Limit",SEICODE_L2_DESC_C:"包装及立体库",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:187,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150100",SEICODE_DOC:"1501",SEICODE_L2_DESC_E:"Overall Plot Plan",SEICODE_L2_DESC_C:"厂区总平面",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:188,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150200",SEICODE_DOC:"1502",SEICODE_L2_DESC_E:"Overall Grading Layout£¬Landscaping£¬Road layout and Rainwater Drainage",SEICODE_L2_DESC_C:"厂区竖向布置, 绿化, 道路及排雨水",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:189,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150400",SEICODE_DOC:"1504",SEICODE_L2_DESC_E:"Fence, Gate and Gate house",SEICODE_L2_DESC_C:"厂区围墙、大门及警卫室",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:190,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150401",SEICODE_DOC:"1504",SEICODE_L2_DESC_E:"Gate and Gate house 1",SEICODE_L2_DESC_C:"大门及警卫室1",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:191,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150402",SEICODE_DOC:"1504",SEICODE_L2_DESC_E:"Gate and Gate house 2",SEICODE_L2_DESC_C:"大门及警卫室2",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:192,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150403",SEICODE_DOC:"1504",SEICODE_L2_DESC_E:"Gate and Gate house 3",SEICODE_L2_DESC_C:"大门及警卫室3",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:193,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150404",SEICODE_DOC:"1504",SEICODE_L2_DESC_E:"Gate and Gate house 4",SEICODE_L2_DESC_C:"大门及警卫室4",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:194,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150405",SEICODE_DOC:"1504",SEICODE_L2_DESC_E:"Gate and Gate house 5",SEICODE_L2_DESC_C:"大门及警卫室5",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:195,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"150406",SEICODE_DOC:"1504",SEICODE_L2_DESC_E:"Gate and Gate house 6",SEICODE_L2_DESC_C:"大门及警卫室6",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:196,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"152409",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Warehouses 9",SEICODE_L2_DESC_C:"危险化学品库9",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:197,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"152410",SEICODE_DOC:"1524",SEICODE_L2_DESC_E:"Chemical Storage Yard",SEICODE_L2_DESC_C:"化学品堆场",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:198,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输",SEICODE_L2:"152292",SEICODE_DOC:"1522",SEICODE_L2_DESC_E:"Maintenance Workshop Substation",SEICODE_L2_DESC_C:"维修中心配电间",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:199,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"178002",SEICODE_DOC:"1780",SEICODE_L2_DESC_E:" Truck Scale",SEICODE_L2_DESC_C:"汽车衡",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:200,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"178003",SEICODE_DOC:"1780",SEICODE_L2_DESC_E:" Truck Scale",SEICODE_L2_DESC_C:"汽车衡",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:201,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"190400",SEICODE_DOC:"1904",SEICODE_L2_DESC_E:"Eastward Pipeline",SEICODE_L2_DESC_C:"厂外管道",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:202,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"150300",SEICODE_DOC:"1503",SEICODE_L2_DESC_E:"Overall Water and Firefighting System",SEICODE_L2_DESC_C:"全厂给排水及消防水管网",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:203,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"181001",SEICODE_DOC:"1810",SEICODE_L2_DESC_E:"Telecom equipment room",SEICODE_L2_DESC_C:"电信机柜间",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:204,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"281001",SEICODE_DOC:"2810",SEICODE_L2_DESC_E:"telecom equipment room",SEICODE_L2_DESC_C:"电信机柜间",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:205,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"180400",SEICODE_DOC:"1804",SEICODE_L2_DESC_E:"Waste Water Pump Station",SEICODE_L2_DESC_C:"污水提升泵站",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:206,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"181300",SEICODE_DOC:"1813",SEICODE_L2_DESC_E:"Foam Station",SEICODE_L2_DESC_C:"泡沫站",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:207,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"180212",SEICODE_DOC:"1802",SEICODE_L2_DESC_E:"Dosage room",SEICODE_L2_DESC_C:"加药间",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:208,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程",SEICODE_L2:"280212",SEICODE_DOC:"2802",SEICODE_L2_DESC_E:"Dosage room",SEICODE_L2_DESC_C:"加药间",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:209,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Electrical, Instrument and Telecom System",SEICODE_L1_DESC_C:"供配电及仪表通信系统",SEICODE_L2:"183300",SEICODE_DOC:"1833",SEICODE_L2_DESC_E:"Overall Electrical System",SEICODE_L2_DESC_C:"全厂供电线路及道路照明",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:210,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Electrical, Instrument and Telecom System",SEICODE_L1_DESC_C:"供配电及仪表通信系统",SEICODE_L2:"183500",SEICODE_DOC:"1835",SEICODE_L2_DESC_E:"Overall Electrical Monitoring and Control System",SEICODE_L2_DESC_C:"全厂微机监控系统",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:211,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Electrical, Instrument and Telecom System",SEICODE_L1_DESC_C:"供配电及仪表通信系统",SEICODE_L2:"187500",SEICODE_DOC:"1875",SEICODE_L2_DESC_E:"Overall Telecom System",SEICODE_L2_DESC_C:"全厂电信系统",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"},
      {NO:212,CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Electrical, Instrument and Telecom System",SEICODE_L1_DESC_C:"供配电及仪表通信系统",SEICODE_L2:"186100",SEICODE_DOC:"1861",SEICODE_L2_DESC_E:"Overall Instrument System",SEICODE_L2_DESC_C:"全厂控制系统",CON_CODE:"NA",CON_DESC_E:"NA",CON_DESC_C:"无法使用",JIANLI_CODE:"NA",JIANLI_DESC_E:"NA",JIANLI_DESC_C:"无法使用"}
    ]
  }],
}