
export default {
  Canvas: {
    CanvasWidth: 1500,
    CanvasHeight: 300,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  // --> this.rawData
  DataItems: [ 
    {
      COUNT: 3,

      TEXT1: 'Total',
      TEXT2: 'Issued$',
      TEXT3: 'Remain$',

      COLOR1: '#333',
      COLOR2: '#333',
      COLOR3: '#F35E90',

      VALUE1: 1111,
      VALUE2: 2222,
      VALUE3: 3333,

      LINK1: 'Y',
      LINK2: 'Y',
      LINK3: 'N',

      FILTER1: 'filter1',
      FILTER2: 'filter2',
      FILTER3: 'filter3',

    }
  ],

  Queries: {

    // --> this.style
    SQL1:  [
      {
        // Table
        BOX_WIDTH: 200,
        BOX_HEIGHT: 40,
        BOX_PADDING: 10,
        BOX_FILL_COLOR: '#B4E3FA',
        BOX_STROKE_COLOR: '#BCBCBC',
        BOX_STROKE_SIZE: 0.5,
        BOX_OPACITY: .7,
        BOX_RADIUS: 5,
        
        // Column
        COL_V_PADDING: 5,
        COL_TEXT_COLOR: '#000',
        COL_TEXT_SIZE: 10,
        COL_TEXT_WEIGHT: 400,
        
        // Value
        VALUE_V_PADDING: 5,
        VALUE_TEXT_SIZE: 13,
        VALUE_TEXT_WEIGHT: 500,
      },
    ],
  },

}