import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 220,
    CanvasHeight: 300,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 220,
    CanvasChartHeight: 300,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {TOTAL: 1563,  ACTUAL: 965,  REMAIN: 657,  PROG: 15, },
  ],

  Queries: {
    SQL1: [
      {
        X:                    10,
        Y:                    10,
        TUBE_COLOR:           'SkyBlue',
        TUBE_HEIGHT:          150,
        TUBE_TICKNESS:        50,

        BASE_HEIGHT:          40,
        BASE_PADDING:         30,

        REF_CODE:             ' TOTAL     / ACTUAL     / REMAIN    / PROG    ',
        TITLE_DESC:           ' Total     / Complsted  / Remain    /         ',
        TITLE_VISIBLE:        'Y',
        TITLE_COLOR:          ' #757575   / #757575    / #757575   /         ',
        TITLE_SIZE:           ' 10        / 10         / 10        /         ',
        VALUE_COLOR:          ' #000      / #44A9DF    / #F7BACF   / #44A9DF ',
        VALUE_SIZE:           ' 11        / 11         / 11        / 14      ',
        JSON_MAP:             '           /            /           /         ', 
        JSON_LINK:            ' JSON1     / JSON1      / JSON1     /         ',
        FINTER:               ' F1        / F2         / F3        /         ',
      },
    ],

    // Filter
    SQL2: [
      {
        F1: '',
        F2: '',
        F3: '',
      },      
    ]
  },
}