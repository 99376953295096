
export default {
  Canvas: {
    CanvasWidth: 200,
    CanvasHeight: 100,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 200,
    CanvasChartHeight: 100,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [ 
    { TITLE: 'MS Assigned',   VALUE: 1111},
    { TITLE: 'prioe Key MS',  VALUE: 1111},
    { TITLE: 'Remain',        VALUE: 1111},
    { TITLE: 'Prog1',         VALUE: 1111},
  ],
  

  Queries: {

    SQL1:  [
      {
        WIDTH:              320,
        LINEHEIGHT:         25,
        MARGIN:             5,
        SPACE:              5,

        TITLE_ALIGN:        'LEFT',
        TITLE_TEXT_SIZE:    11,
        TITLE_TEXT_COLOR:   '#757575',
        TITLE_TEXT_WEIGHT:  400,

        BOX_VISIBLE:        'Y',
        BOX_WIDTH:          60,
        BOX_HEIGHT:         20,
        BOX_PADDING:        5,
        BOX_PLUS_Y:         -1,

        // Columns
        VALUE_ALIGN:        'LEFT',
        VALUE_TEXT_SIZE:    11,
        VALUE_TEXT_WEIGHT:  500,
        VALUE_FORMAT:       '             / %           / $           / %       ',
        VALUE_TEXT_COLOR:   ' #757575     / white       / #F35E90     / #44A9DF ',
        BOX_FILL_COLOR:     ' Transparent / #B4E3FA     / Transparent / #F7BACF ',
        BOX_STROKE_COLOR:   ' #F35E90     / #BCBCBC     / #BCBCBC     / #BCBCBC ',
        BOX_STROKE_SIZE:    ' 0.5         / 0.5         / 0.5         / 0.5     ',
        BOX_OPACITY:        ' 0.5         / 0.5         / 0.5         / 0.1     ',
        BOX_RADIUS:         ' 2           / 2           / 2           / 2       ',
        JSON_MAP:           '             /             /             /         ', 
        JSON_LINK:          '             /             /             /         ', 
        FILTER:             '             /             /             /         ', 
      },
    ],

    SQL2:  [
      {
        F1: '1 = 1',
        F2: '2 = 2',
        F3: '3 = 3',
      },
    ],
  },


}