import __C from '../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 500,
      CanvasHeight: 280,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      
    },

    DataItems: [
      { CDATE: '2023-07-07', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0, },
      { CDATE: '2023-07-14', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.1,  PL_CUM: 0.1,  AC_CUM: 0.1,  FC_CUM: 0.1, },
      { CDATE: '2023-07-21', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.1,  PL_CUM: 0.1,  AC_CUM: 0.1,  FC_CUM: 0.1, },
      { CDATE: '2023-07-28', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.2,  PL_CUM: 0.2,  AC_CUM: 0.2,  FC_CUM: 0.2, },
      { CDATE: '2023-08-04', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.2,  PL_CUM: 0.2,  AC_CUM: 0.2,  FC_CUM: 0.2, },
      { CDATE: '2023-08-11', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.3,  PL_CUM: 0.3,  AC_CUM: 0.3,  FC_CUM: 0.3, },
      { CDATE: '2023-08-18', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.4,  PL_CUM: 0.4,  AC_CUM: 0.4,  FC_CUM: 0.4, },
      { CDATE: '2023-08-25', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.5,  PL_CUM: 0.5,  AC_CUM: 0.5,  FC_CUM: 0.5, },
      { CDATE: '2023-09-01', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.6,  PL_CUM: 0.6,  AC_CUM: 0.6,  FC_CUM: 0.6, },
      { CDATE: '2023-09-08', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.6,  PL_CUM: 0.6,  AC_CUM: 0.6,  FC_CUM: 0.6, },
      { CDATE: '2023-09-15', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.7,  PL_CUM: 0.7,  AC_CUM: 0.7,  FC_CUM: 0.7, },
      { CDATE: '2023-09-22', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.8,  PL_CUM: 0.8,  AC_CUM: 0.8,  FC_CUM: 0.8, },
      { CDATE: '2023-09-29', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 0.9,  PL_CUM: 0.9,  AC_CUM: 0.9,  FC_CUM: 0.9, },
      { CDATE: '2023-10-06', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 1,    PL_CUM: 1,    AC_CUM: 1,    FC_CUM: 1, },
      { CDATE: '2023-10-13', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 1.1,  PL_CUM: 1.1,  AC_CUM: 1.1,  FC_CUM: 1.1, },
      { CDATE: '2023-10-20', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 1.2,  PL_CUM: 1.2,  AC_CUM: 1.2,  FC_CUM: 1.2, },
      { CDATE: '2023-10-27', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 1.3,  PL_CUM: 1.3,  AC_CUM: 1.3,  FC_CUM: 1.3, },
      { CDATE: '2023-11-03', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 1.5,  PL_CUM: 1.5,  AC_CUM: 1.5,  FC_CUM: 1.5, },
      { CDATE: '2023-11-10', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 2,    PL_CUM: 2,    AC_CUM: 2,    FC_CUM: 2, },
      { CDATE: '2023-11-17', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 2.6,  PL_CUM: 2.6,  AC_CUM: 2.6,  FC_CUM: 2.6, },
      { CDATE: '2023-11-24', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 2.7,  PL_CUM: 2.7,  AC_CUM: 2.7,  FC_CUM: 2.7, },
      { CDATE: '2023-12-01', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 2.9,  PL_CUM: 2.9,  AC_CUM: 2.9,  FC_CUM: 2.9, },
      { CDATE: '2023-12-08', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 3,    PL_CUM: 3,    AC_CUM: 3,    FC_CUM: 3, },
      { CDATE: '2023-12-15', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 3.2,  PL_CUM: 3.2,  AC_CUM: 3.2,  FC_CUM: 3.2, },
      { CDATE: '2023-12-22', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 3.3,  PL_CUM: 3.3,  AC_CUM: 3.3,  FC_CUM: 3.3, },
      { CDATE: '2023-12-29', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 3.5,  PL_CUM: 3.5,  AC_CUM: 3.5,  FC_CUM: 3.5, },
      { CDATE: '2024-01-05', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 3.5,  PL_CUM: 3.5,  AC_CUM: 3.5,  FC_CUM: 3.5, },
      { CDATE: '2024-01-12', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 3.5,  PL_CUM: 3.5,  AC_CUM: 3.5,  FC_CUM: 3.5, },
      { CDATE: '2024-01-19', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 3.7,  PL_CUM: 3.7,  AC_CUM: 3.7,  FC_CUM: 3.7, },
      { CDATE: '2024-01-26', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 3.8,  PL_CUM: 3.8,  AC_CUM: 3.8,  FC_CUM: 3.8, },
      { CDATE: '2024-02-02', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 4.1,  PL_CUM: 4.1,  AC_CUM: 4.1,  FC_CUM: 4.1, },
      { CDATE: '2024-02-09', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 4.3,  PL_CUM: 4.3,  AC_CUM: 4.3,  FC_CUM: 4.3, },
      { CDATE: '2024-02-16', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 4.7,  PL_CUM: 4.7,  AC_CUM: 4.6,  FC_CUM: 4.6, },
      { CDATE: '2024-02-23', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 4.9,  PL_CUM: 4.9,  AC_CUM: 4.9,  FC_CUM: 4.9, },
      { CDATE: '2024-03-01', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 5.6,  PL_CUM: 5.3,  AC_CUM: 5.4,  FC_CUM: 5.4, },
      { CDATE: '2024-03-08', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 5.9,  PL_CUM: 5.5,  AC_CUM: 5.9,  FC_CUM: 5.9, },
      { CDATE: '2024-03-15', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 6.6,  PL_CUM: 6,    AC_CUM: 6.7,  FC_CUM: 6.7, },
      { CDATE: '2024-03-22', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 7.3,  PL_CUM: 6.7,  AC_CUM: 7.4,  FC_CUM: 7.4, },
      { CDATE: '2024-03-29', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 7.7,  PL_CUM: 6.9,  AC_CUM: 7.9,  FC_CUM: 7.9, },
      { CDATE: '2024-04-05', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 8,    PL_CUM: 7.1,  AC_CUM: 8.2,  FC_CUM: 8.3, },
      { CDATE: '2024-04-12', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 8.3,  PL_CUM: 7.3,  AC_CUM: 8.5,  FC_CUM: 8.6, },
      { CDATE: '2024-04-19', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 8.7,  PL_CUM: 7.6,  AC_CUM: 8.8,  FC_CUM: 8.9, },
      { CDATE: '2024-04-26', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 8.9,  PL_CUM: 7.7,  AC_CUM: 9.1,  FC_CUM: 9.2, },
      { CDATE: '2024-05-03', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 9.3,  PL_CUM: 8,    AC_CUM: 9.5,  FC_CUM: 9.5, },
      { CDATE: '2024-05-10', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 9.8,  PL_CUM: 8.4,  AC_CUM: 9.8,  FC_CUM: 9.8, },
      { CDATE: '2024-05-17', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 10.2, PL_CUM: 8.7,  AC_CUM: 10,   FC_CUM: 10.1, },
      { CDATE: '2024-05-24', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 10.8, PL_CUM: 9.3,  AC_CUM: 10.6, FC_CUM: 10.6, },
      { CDATE: '2024-05-31', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 11.6, PL_CUM: 9.9,  AC_CUM: 11.2, FC_CUM: 11.2, },
      { CDATE: '2024-06-07', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 12.3, PL_CUM: 10.5, AC_CUM: 11.6, FC_CUM: 11.6, },
      { CDATE: '2024-06-14', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 12.9, PL_CUM: 11.1, AC_CUM: 12,   FC_CUM: 12, },
      { CDATE: '2024-06-21', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 13.6, PL_CUM: 11.8, AC_CUM: 12.8, FC_CUM: 12.8, },
      { CDATE: '2024-06-28', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 14.2, PL_CUM: 12.4, AC_CUM: 13.5, FC_CUM: 13.5, },
      { CDATE: '2024-07-05', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 14.8, PL_CUM: 13,   AC_CUM: 13.8, FC_CUM: 13.8, },
      { CDATE: '2024-07-12', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 15.4, PL_CUM: 13.6, AC_CUM: 14.5, FC_CUM: 14.5, },
      { CDATE: '2024-07-19', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 16,   PL_CUM: 14.2, AC_CUM: 15.2, FC_CUM: 15.2, },
      { CDATE: '2024-07-26', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 16.6, PL_CUM: 14.9, AC_CUM: 16.1, FC_CUM: 16.1, },
      { CDATE: '2024-08-02', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 17.4, PL_CUM: 15.7, AC_CUM: 16.5, FC_CUM: 16.5, },
      { CDATE: '2024-08-09', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 18,   PL_CUM: 16.4, AC_CUM: 16.9, FC_CUM: 16.9, },
      { CDATE: '2024-08-16', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 18.7, PL_CUM: 17.2, AC_CUM: 17.4, FC_CUM: 17.4, },
      { CDATE: '2024-08-23', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 19.5, PL_CUM: 18,   AC_CUM: 18.2, FC_CUM: 18.2, },
      { CDATE: '2024-08-30', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 20.2, PL_CUM: 18.7, AC_CUM: 18.6, FC_CUM: 18.6, },
      { CDATE: '2024-09-06', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 21,   PL_CUM: 19.4, AC_CUM: 19.6, FC_CUM: 19.6, },
      { CDATE: '2024-09-13', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 21.7, PL_CUM: 20.1, AC_CUM: 20.3, FC_CUM: 20.3, },
      { CDATE: '2024-09-20', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 22.5, PL_CUM: 20.9, AC_CUM: 20.6, FC_CUM: 20.6, },
      { CDATE: '2024-09-27', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 23.2, PL_CUM: 21.6, AC_CUM: 21,   FC_CUM: 21, },
      { CDATE: '2024-10-04', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 24,   PL_CUM: 22.3, AC_CUM: null, FC_CUM: 21.6, },
      { CDATE: '2024-10-11', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 24.7, PL_CUM: 23,   AC_CUM: null, FC_CUM: 22, },
      { CDATE: '2024-10-18', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 25.5, PL_CUM: 23.7, AC_CUM: null, FC_CUM: 22.6, },
      { CDATE: '2024-10-25', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 26.3, PL_CUM: 24.4, AC_CUM: null, FC_CUM: 23.1, },
      { CDATE: '2024-11-01', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 27.1, PL_CUM: 25.1, AC_CUM: null, FC_CUM: 23.6, },
      { CDATE: '2024-11-08', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 27.8, PL_CUM: 25.9, AC_CUM: null, FC_CUM: 24.2, },
      { CDATE: '2024-11-15', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 28.6, PL_CUM: 26.6, AC_CUM: null, FC_CUM: 24.7, },
      { CDATE: '2024-11-22', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 29.3, PL_CUM: 27.2, AC_CUM: null, FC_CUM: 25.3, },
      { CDATE: '2024-11-29', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 30.1, PL_CUM: 27.8, AC_CUM: null, FC_CUM: 25.9, },
      { CDATE: '2024-12-06', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 30.9, PL_CUM: 28.4, AC_CUM: null, FC_CUM: 26.5, },
      { CDATE: '2024-12-13', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 31.7, PL_CUM: 29.1, AC_CUM: null, FC_CUM: 27.3, },
      { CDATE: '2024-12-20', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 32.4, PL_CUM: 29.7, AC_CUM: null, FC_CUM: 28, },
      { CDATE: '2024-12-27', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 33.1, PL_CUM: 30.4, AC_CUM: null, FC_CUM: 28.7, },
      { CDATE: '2025-01-03', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 34.1, PL_CUM: 31.3, AC_CUM: null, FC_CUM: 29.6, },
      { CDATE: '2025-01-10', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 34.9, PL_CUM: 32,   AC_CUM: null, FC_CUM: 30.6, },
      { CDATE: '2025-01-17', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 35.9, PL_CUM: 32.9, AC_CUM: null, FC_CUM: 31.7, },
      { CDATE: '2025-01-24', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 36.5, PL_CUM: 33.7, AC_CUM: null, FC_CUM: 32.7, },
      { CDATE: '2025-01-31', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 37.4, PL_CUM: 34.7, AC_CUM: null, FC_CUM: 33.7, },
      { CDATE: '2025-02-07', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 38.3, PL_CUM: 35.6, AC_CUM: null, FC_CUM: 34.8, },
      { CDATE: '2025-02-14', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 39.2, PL_CUM: 36.5, AC_CUM: null, FC_CUM: 35.8, },
      { CDATE: '2025-02-21', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 40.1, PL_CUM: 37.3, AC_CUM: null, FC_CUM: 36.9, },
      { CDATE: '2025-02-28', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 41,   PL_CUM: 38.2, AC_CUM: null, FC_CUM: 38.1, },
      { CDATE: '2025-03-07', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 42,   PL_CUM: 39.3, AC_CUM: null, FC_CUM: 39.4, },
      { CDATE: '2025-03-14', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 43,   PL_CUM: 40.4, AC_CUM: null, FC_CUM: 40.6, },
      { CDATE: '2025-03-21', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 44,   PL_CUM: 41.6, AC_CUM: null, FC_CUM: 41.7, },
      { CDATE: '2025-03-28', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 45,   PL_CUM: 42.6, AC_CUM: null, FC_CUM: 43, },
      { CDATE: '2025-04-04', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 45.9, PL_CUM: 43.6, AC_CUM: null, FC_CUM: 44.2, },
      { CDATE: '2025-04-11', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 47.1, PL_CUM: 44.6, AC_CUM: null, FC_CUM: 45.4, },
      { CDATE: '2025-04-18', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 48.4, PL_CUM: 45.9, AC_CUM: null, FC_CUM: 46.8, },
      { CDATE: '2025-04-25', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 49.5, PL_CUM: 47.1, AC_CUM: null, FC_CUM: 48.2, },
      { CDATE: '2025-05-02', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 50.7, PL_CUM: 48.2, AC_CUM: null, FC_CUM: 49.4, },
      { CDATE: '2025-05-09', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 52,   PL_CUM: 49.6, AC_CUM: null, FC_CUM: 50.9, },
      { CDATE: '2025-05-16', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 53.2, PL_CUM: 50.9, AC_CUM: null, FC_CUM: 52.2, },
      { CDATE: '2025-05-23', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 54.5, PL_CUM: 52.2, AC_CUM: null, FC_CUM: 53.6, },
      { CDATE: '2025-05-30', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 55.7, PL_CUM: 53.5, AC_CUM: null, FC_CUM: 54.9, },
      { CDATE: '2025-06-06', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 56.8, PL_CUM: 54.5, AC_CUM: null, FC_CUM: 56.2, },
      { CDATE: '2025-06-13', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 57.8, PL_CUM: 55.5, AC_CUM: null, FC_CUM: 57.2, },
      { CDATE: '2025-06-20', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 58.9, PL_CUM: 56.7, AC_CUM: null, FC_CUM: 58.4, },
      { CDATE: '2025-06-27', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 59.9, PL_CUM: 58,   AC_CUM: null, FC_CUM: 59.5, },
      { CDATE: '2025-07-04', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 61.1, PL_CUM: 59.3, AC_CUM: null, FC_CUM: 60.7, },
      { CDATE: '2025-07-11', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 62.2, PL_CUM: 60.4, AC_CUM: null, FC_CUM: 61.8, },
      { CDATE: '2025-07-18', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 63,   PL_CUM: 61.4, AC_CUM: null, FC_CUM: 62.6, },
      { CDATE: '2025-07-25', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 63.1, PL_CUM: 61.5, AC_CUM: null, FC_CUM: 62.8, },
      { CDATE: '2025-08-01', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 63.9, PL_CUM: 62.2, AC_CUM: null, FC_CUM: 63.6, },
      { CDATE: '2025-08-08', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 64.7, PL_CUM: 63,   AC_CUM: null, FC_CUM: 64.4, },
      { CDATE: '2025-08-15', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 65.6, PL_CUM: 63.6, AC_CUM: null, FC_CUM: 65.4, },
      { CDATE: '2025-08-22', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 66.5, PL_CUM: 64.6, AC_CUM: null, FC_CUM: 66.3, },
      { CDATE: '2025-08-29', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 67.6, PL_CUM: 65.6, AC_CUM: null, FC_CUM: 67.4, },
      { CDATE: '2025-09-05', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 68.7, PL_CUM: 66.9, AC_CUM: null, FC_CUM: 68.5, },
      { CDATE: '2025-09-12', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 70.1, PL_CUM: 68.5, AC_CUM: null, FC_CUM: 70, },
      { CDATE: '2025-09-19', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 70.9, PL_CUM: 69.4, AC_CUM: null, FC_CUM: 70.8, },
      { CDATE: '2025-09-26', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 71.4, PL_CUM: 70,   AC_CUM: null, FC_CUM: 71.3, },
      { CDATE: '2025-10-03', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 71.7, PL_CUM: 70.3, AC_CUM: null, FC_CUM: 71.7, },
      { CDATE: '2025-10-10', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 72.7, PL_CUM: 70.9, AC_CUM: null, FC_CUM: 72.6, },
      { CDATE: '2025-10-17', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 74,   PL_CUM: 71.4, AC_CUM: null, FC_CUM: 73.9, },
      { CDATE: '2025-10-24', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 75.2, PL_CUM: 72.5, AC_CUM: null, FC_CUM: 75.1, },
      { CDATE: '2025-10-31', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 76.4, PL_CUM: 73.6, AC_CUM: null, FC_CUM: 76.3, },
      { CDATE: '2025-11-07', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 77.4, PL_CUM: 74.4, AC_CUM: null, FC_CUM: 77.4, },
      { CDATE: '2025-11-14', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 78.4, PL_CUM: 75.2, AC_CUM: null, FC_CUM: 78.4, },
      { CDATE: '2025-11-21', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 79.3, PL_CUM: 75.8, AC_CUM: null, FC_CUM: 79.2, },
      { CDATE: '2025-11-28', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 80,   PL_CUM: 76.5, AC_CUM: null, FC_CUM: 80, },
      { CDATE: '2025-12-05', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 80.8, PL_CUM: 77.1, AC_CUM: null, FC_CUM: 80.8, },
      { CDATE: '2025-12-12', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 81.7, PL_CUM: 77.8, AC_CUM: null, FC_CUM: 81.7, },
      { CDATE: '2025-12-19', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 82.3, PL_CUM: 78.4, AC_CUM: null, FC_CUM: 82.2, },
      { CDATE: '2025-12-26', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 82.7, PL_CUM: 79.1, AC_CUM: null, FC_CUM: 82.7, },
      { CDATE: '2026-01-02', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 83.2, PL_CUM: 79.8, AC_CUM: null, FC_CUM: 83.1, },
      { CDATE: '2026-01-09', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 83.5, PL_CUM: 80.4, AC_CUM: null, FC_CUM: 83.5, },
      { CDATE: '2026-01-16', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 83.9, PL_CUM: 81,   AC_CUM: null, FC_CUM: 83.8, },
      { CDATE: '2026-01-23', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 84.1, PL_CUM: 81.6, AC_CUM: null, FC_CUM: 84, },
      { CDATE: '2026-01-30', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 84.3, PL_CUM: 82.2, AC_CUM: null, FC_CUM: 84.3, },
      { CDATE: '2026-02-06', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 84.5, PL_CUM: 82.7, AC_CUM: null, FC_CUM: 84.4, },
      { CDATE: '2026-02-13', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 84.6, PL_CUM: 82.9, AC_CUM: null, FC_CUM: 84.5, },
      { CDATE: '2026-02-20', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 84.7, PL_CUM: 83.3, AC_CUM: null, FC_CUM: 84.7, },
      { CDATE: '2026-02-27', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 84.9, PL_CUM: 83.6, AC_CUM: null, FC_CUM: 84.8, },
      { CDATE: '2026-03-06', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85,   PL_CUM: 83.8, AC_CUM: null, FC_CUM: 85, },
      { CDATE: '2026-03-13', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85.1, PL_CUM: 84.1, AC_CUM: null, FC_CUM: 85.1, },
      { CDATE: '2026-03-20', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85.3, PL_CUM: 84.3, AC_CUM: null, FC_CUM: 85.2, },
      { CDATE: '2026-03-27', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85.4, PL_CUM: 84.5, AC_CUM: null, FC_CUM: 85.4, },
      { CDATE: '2026-04-03', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85.5, PL_CUM: 84.7, AC_CUM: null, FC_CUM: 85.5, },
      { CDATE: '2026-04-10', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85.6, PL_CUM: 84.8, AC_CUM: null, FC_CUM: 85.6, },
      { CDATE: '2026-04-17', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85.7, PL_CUM: 84.9, AC_CUM: null, FC_CUM: 85.7, },
      { CDATE: '2026-04-24', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85.8, PL_CUM: 85,   AC_CUM: null, FC_CUM: 85.8, },
      { CDATE: '2026-05-01', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 85.9, PL_CUM: 85.1, AC_CUM: null, FC_CUM: 85.9, },
      { CDATE: '2026-05-08', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 86.1, PL_CUM: 85.1, AC_CUM: null, FC_CUM: 86.1, },
      { CDATE: '2026-05-15', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 86.4, PL_CUM: 85.3, AC_CUM: null, FC_CUM: 86.4, },
      { CDATE: '2026-05-22', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 86.9, PL_CUM: 85.5, AC_CUM: null, FC_CUM: 86.9, },
      { CDATE: '2026-05-29', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 87.4, PL_CUM: 85.7, AC_CUM: null, FC_CUM: 87.4, },
      { CDATE: '2026-06-05', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 88.1, PL_CUM: 86,   AC_CUM: null, FC_CUM: 88.1, },
      { CDATE: '2026-06-12', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 89,   PL_CUM: 86.3, AC_CUM: null, FC_CUM: 88.9, },
      { CDATE: '2026-06-19', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 89.8, PL_CUM: 86.7, AC_CUM: null, FC_CUM: 89.8, },
      { CDATE: '2026-06-26', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 90.8, PL_CUM: 87.2, AC_CUM: null, FC_CUM: 90.8, },
      { CDATE: '2026-07-03', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 91.8, PL_CUM: 87.8, AC_CUM: null, FC_CUM: 91.8, },
      { CDATE: '2026-07-10', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 92.7, PL_CUM: 88.4, AC_CUM: null, FC_CUM: 92.7, },
      { CDATE: '2026-07-17', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 93.5, PL_CUM: 88.9, AC_CUM: null, FC_CUM: 93.5, },
      { CDATE: '2026-07-24', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 93.6, PL_CUM: 89,   AC_CUM: null, FC_CUM: 93.6, },
      { CDATE: '2026-07-31', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 93.8, PL_CUM: 89.1, AC_CUM: null, FC_CUM: 93.8, },
      { CDATE: '2026-08-07', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 94.5, PL_CUM: 89.7, AC_CUM: null, FC_CUM: 94.5, },
      { CDATE: '2026-08-14', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 95.1, PL_CUM: 90.3, AC_CUM: null, FC_CUM: 95.1, },
      { CDATE: '2026-08-21', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 95.5, PL_CUM: 90.9, AC_CUM: null, FC_CUM: 95.5, },
      { CDATE: '2026-08-28', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 95.9, PL_CUM: 91.5, AC_CUM: null, FC_CUM: 95.9, },
      { CDATE: '2026-09-04', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 96.3, PL_CUM: 92.2, AC_CUM: null, FC_CUM: 96.3, },
      { CDATE: '2026-09-11', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 96.7, PL_CUM: 92.9, AC_CUM: null, FC_CUM: 96.7, },
      { CDATE: '2026-09-18', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 96.9, PL_CUM: 93.4, AC_CUM: null, FC_CUM: 96.9, },
      { CDATE: '2026-09-25', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 97.2, PL_CUM: 94,   AC_CUM: null, FC_CUM: 97.2, },
      { CDATE: '2026-10-02', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 97.5, PL_CUM: 94.6, AC_CUM: null, FC_CUM: 97.5, },
      { CDATE: '2026-10-09', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 97.7, PL_CUM: 95.1, AC_CUM: null, FC_CUM: 97.7, },
      { CDATE: '2026-10-16', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 97.9, PL_CUM: 95.7, AC_CUM: null, FC_CUM: 97.9, },
      { CDATE: '2026-10-23', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 98.1, PL_CUM: 96.1, AC_CUM: null, FC_CUM: 98.1, },
      { CDATE: '2026-10-30', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 98.3, PL_CUM: 96.6, AC_CUM: null, FC_CUM: 98.3, },
      { CDATE: '2026-11-06', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 98.4, PL_CUM: 96.9, AC_CUM: null, FC_CUM: 98.4, },
      { CDATE: '2026-11-13', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 98.6, PL_CUM: 97.3, AC_CUM: null, FC_CUM: 98.6, },
      { CDATE: '2026-11-20', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 98.8, PL_CUM: 97.6, AC_CUM: null, FC_CUM: 98.8, },
      { CDATE: '2026-11-27', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99,   PL_CUM: 97.9, AC_CUM: null, FC_CUM: 99, },
      { CDATE: '2026-12-04', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.2, PL_CUM: 98.2, AC_CUM: null, FC_CUM: 99.2, },
      { CDATE: '2026-12-11', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.5, PL_CUM: 98.4, AC_CUM: null, FC_CUM: 99.5, },
      { CDATE: '2026-12-18', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.6, PL_CUM: 98.6, AC_CUM: null, FC_CUM: 99.6, },
      { CDATE: '2026-12-25', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.6, PL_CUM: 98.7, AC_CUM: null, FC_CUM: 99.6, },
      { CDATE: '2027-01-01', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.7, PL_CUM: 98.8, AC_CUM: null, FC_CUM: 99.7, },
      { CDATE: '2027-01-08', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.8, PL_CUM: 98.9, AC_CUM: null, FC_CUM: 99.8, },
      { CDATE: '2027-01-15', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.8, PL_CUM: 99,   AC_CUM: null, FC_CUM: 99.8, },
      { CDATE: '2027-01-22', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.9, PL_CUM: 99.1, AC_CUM: null, FC_CUM: 99.9, },
      { CDATE: '2027-01-29', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.9, PL_CUM: 99.2, AC_CUM: null, FC_CUM: 99.9, },
      { CDATE: '2027-02-05', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.9, PL_CUM: 99.4, AC_CUM: null, FC_CUM: 99.9, },
      { CDATE: '2027-02-12', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.9, PL_CUM: 99.5, AC_CUM: null, FC_CUM: 99.9, },
      { CDATE: '2027-02-19', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 99.9, PL_CUM: 99.7, AC_CUM: null, FC_CUM: 99.9, },
      { CDATE: '2027-02-26', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 100,  PL_CUM: 99.8, AC_CUM: null, FC_CUM: 100, },
      { CDATE: '2027-03-05', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 100,  PL_CUM: 99.9, AC_CUM: null, FC_CUM: 100, },
      { CDATE: '2027-03-12', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 100,  PL_CUM: 100,  AC_CUM: null, FC_CUM: 100, },
      { CDATE: '2027-03-19', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 100,  PL_CUM: 100,  AC_CUM: null, FC_CUM: 100, },
      { CDATE: '2027-03-26', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 100,  PL_CUM: 100,  AC_CUM: null, FC_CUM: 100, },
      { CDATE: '2027-04-02', PHASE: 'Overall', PHASE_L1: 'Overall', AREA: 'Overall', PE_CUM: 100,  PL_CUM: 100,  AC_CUM: null, FC_CUM: 100, },


    ],

    Queries: {
      
      SQL1: [
        {
          // Timeline
          TL_CUTOFF               : '2024-09-27',
          TL_WEEKEND              : 'friday',
          TL_START                : '2023-07-07',
          TL_END                  : '2027-03-26',
          TL_MONTH_VISIBLE        : 'Y',
          TL_MONTH_TCOLOR         : '#333',
          TL_MONTH_TSIZE          : 8,
          TL_YEAR_TCOLOR          : '#333',
          TL_YEAR_TSIZE           : 10,

          // Chart
          CHART_X                 : 50,
          CHART_Y                 : 60,
          CHART_WIDTH             : 420,
          CHART_HEIGHT            : 185, 
          CHART_BORDER_COLOR      : '#757575',
          CHART_BORDER_SWIDTH     : 0.5,
          CHART_BORDER_OPACITY    : 1,
          
          // Title
          TITLE_MAIN              : 'Stack Chart &  Histogram for Construction',
          TITLE_SUB               : 'Mechanical',
          TITLE_ALIGN             : 'left',
          TITLE_MAIN_VISIBLE      : 'Y',
          TITLE_MAIN_TCOLOR       : '#000000',
          TITLE_MAIN_TSIZE        : 12,
          TITLE_MAIN_MARGIN       : 40,

          TITLE_SUB_VISIBLE       : 'Y',
          TITLE_SUB_TCOLOR        : '#333333',
          TITLE_SUB_TSIZE         : 10,
          TITLE_SUB_MARGIN        : 27,

          // Primery
          PRIMARY_TITLE_NAME      : 'Cumulative Progress(%)',
          PRIMARY_TITLE_TCOLOR    : 0,
          PRIMARY_TITLE_TSIZE     : 10,
          PRIMARY_TITLE_MARGIN    : 30,
          PRIMARY_VALUE_TCOLOR    : 0,
          PRIMARY_VALUE_TSIZE     : 8,
          PRIMARY_VALUE_MARGIN    : 2,

          // Grid
          GRID_COLOR              : '#bcbcbc',
          GRID_SWIDTH             : 0.5,
          GRID_OPACITY            : 0.5,

          // Legend
          LEGEND_TCOLOR           : '#333',
          LEGEND_TSIZE            : 8.5,
          LEGEND_MARGIN           : 4,
          LEGEND_ALIGN            : 'left',
  
          // Line Chart Properties
          REF_CODE                : 'PE_CUM     / PL_CUM      / FC_CUM    / AC_CUM  ',
          NAME                    : 'Early Plan / Late Plan   / Forecast  / Actual   ',
          LINE_TYPE               : 'LINE       / LINE        / DASH      / ACT      ',
          LINE_SIZE               : '1.5        / 1.5         / 1.5       / 1.5      ',
          LINE_COLOR              : '#A7A7A7    / #44A9DF     / #EC407A   / #EC407A  ',
          LINE_OPACITY            : '1          / 1           / 1         / 1        ',   
        },
      ],

    },
}