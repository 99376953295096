
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },


  DataItems: [
    { CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Hull',    DISC: 'E&I',        PE_WK: 1.1, PL_WK: 1.1, AC_WK: 0,   FC_WK: 0,   DE_WK: -1.1, DL_WK: -1.1, PE_CUM: 13.6, PL_CUM: 13.6, AC_CUM: 0,    FC_CUM: 0,    DE_CUM: -13.6, DL_CUM: -13.6, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,   FC_WK: 0,   DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,   FC_WK: 0,   DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Hull',    DISC: 'Overall',    PE_WK: 3,   PL_WK: 3,   AC_WK: 1.7, FC_WK: 1.7, DE_WK: -1.3, DL_WK: -1.3, PE_CUM: 53.7, PL_CUM: 53.7, AC_CUM: 46.1, FC_CUM: 46.1, DE_CUM: -7.6,  DL_CUM: -7.6, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Hull',    DISC: 'Piping',     PE_WK: 3.2, PL_WK: 3.2, AC_WK: 1.3, FC_WK: 1.3, DE_WK: -1.9, DL_WK: -1.9, PE_CUM: 54.8, PL_CUM: 54.8, AC_CUM: 27.1, FC_CUM: 27.1, DE_CUM: -27.8, DL_CUM: -27.8, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Hull',    DISC: 'Structural', PE_WK: 3.8, PL_WK: 3.8, AC_WK: 2.6, FC_WK: 2.6, DE_WK: -1.2, DL_WK: -1.2, PE_CUM: 73.1, PL_CUM: 73.1, AC_CUM: 74.9, FC_CUM: 74.9, DE_CUM: 1.8,   DL_CUM: 1.8, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'LQ',      DISC: 'LQ-All',     PE_WK: 0.1, PL_WK: 0.1, AC_WK: 9.3, FC_WK: 9.3, DE_WK: 9.2,  DL_WK: 9.2,  PE_CUM: 0.7,  PL_CUM: 0.7,  AC_CUM: 35,   FC_CUM: 35,   DE_CUM: 34.3,  DL_CUM: 34.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'LQ',      DISC: 'Overall',    PE_WK: 0.1, PL_WK: 0.1, AC_WK: 9.3, FC_WK: 9.3, DE_WK: 9.2,  DL_WK: 9.2,  PE_CUM: 0.7,  PL_CUM: 0.7,  AC_CUM: 35,   FC_CUM: 35,   DE_CUM: 34.3,  DL_CUM: 34.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Overall', DISC: 'E&I',        PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,   FC_WK: 0,   DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 2,    PL_CUM: 2,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: -2,    DL_CUM: -2, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,   FC_WK: 0,   DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Overall', DISC: 'LQ-All',     PE_WK: 0.1, PL_WK: 0.1, AC_WK: 9.3, FC_WK: 9.3, DE_WK: 9.2,  DL_WK: 9.2,  PE_CUM: 0.7,  PL_CUM: 0.7,  AC_CUM: 35,   FC_CUM: 35,   DE_CUM: 34.3,  DL_CUM: 34.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Overall', DISC: 'Mechanical', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,   FC_WK: 0,   DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Overall', DISC: 'Overall',    PE_WK: 2.2, PL_WK: 2.2, AC_WK: 2.1, FC_WK: 2.2, DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 31.9, PL_CUM: 31.9, AC_CUM: 32.2, FC_CUM: 32.2, DE_CUM: 0.3,   DL_CUM: 0.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Overall', DISC: 'Piping',     PE_WK: 3.2, PL_WK: 3.2, AC_WK: 0.7, FC_WK: 1.1, DE_WK: -2.5, DL_WK: -2.5, PE_CUM: 54.8, PL_CUM: 54.8, AC_CUM: 19.2, FC_CUM: 19.2, DE_CUM: -35.6, DL_CUM: -35.6, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Overall', DISC: 'Structural', PE_WK: 3.8, PL_WK: 3.8, AC_WK: 2.9, FC_WK: 2.9, DE_WK: -1,   DL_WK: -1,   PE_CUM: 52.5, PL_CUM: 52.5, AC_CUM: 66.1, FC_CUM: 66.1, DE_CUM: 13.6,  DL_CUM: 13.6, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Topside', DISC: 'E&I',        PE_WK: 0,   PL_WK: 0,   AC_WK: 0,   FC_WK: 0,   DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,   FC_WK: 0,   DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Topside', DISC: 'Mechanical', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,   FC_WK: 0,   DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,    FC_CUM: 0,    DE_CUM: 0,     DL_CUM: 0, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Topside', DISC: 'Overall',    PE_WK: 2,   PL_WK: 2,   AC_WK: 1.1, FC_WK: 1.3, DE_WK: -0.8, DL_WK: -0.8, PE_CUM: 20.4, PL_CUM: 20.4, AC_CUM: 20.6, FC_CUM: 20.6, DE_CUM: 0.2,   DL_CUM: 0.2, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Topside', DISC: 'Piping',     PE_WK: 3.2, PL_WK: 3.2, AC_WK: 0.1, FC_WK: 1,   DE_WK: -3.1, DL_WK: -3.1, PE_CUM: 54.8, PL_CUM: 54.8, AC_CUM: 11.5, FC_CUM: 11.5, DE_CUM: -43.4, DL_CUM: -43.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Bulk',    AREA: 'Topside', DISC: 'Structural', PE_WK: 3.8, PL_WK: 3.8, AC_WK: 3.3, FC_WK: 3.3, DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 26.9, PL_CUM: 26.9, AC_CUM: 55.1, FC_CUM: 55.1, DE_CUM: 28.2,  DL_CUM: 28.2, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Hull',    DISC: 'E&I',        PE_WK: 1.1, PL_WK: 1,   AC_WK: 0.3, FC_WK: 0.3, DE_WK: -0.8, DL_WK: -0.6, PE_CUM: 30.6, PL_CUM: 28.5, AC_CUM: 22.9, FC_CUM: 22.9, DE_CUM: -7.7,  DL_CUM: -5.5, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 0.6, PL_WK: 0.5, AC_WK: 0.4, FC_WK: 0.4, DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 11.5, PL_CUM: 11.1, AC_CUM: 16.6, FC_CUM: 16.6, DE_CUM: 5,     DL_CUM: 5.5, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 1.2, PL_WK: 0.9, AC_WK: 0,   FC_WK: 0,   DE_WK: -1.2, DL_WK: -0.9, PE_CUM: 49.7, PL_CUM: 43.4, AC_CUM: 38.9, FC_CUM: 38.9, DE_CUM: -10.8, DL_CUM: -4.5, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Hull',    DISC: 'Overall',    PE_WK: 2.2, PL_WK: 2.2, AC_WK: 1.2, FC_WK: 1.2, DE_WK: -1,   DL_WK: -1,   PE_CUM: 50.1, PL_CUM: 49.3, AC_CUM: 41.9, FC_CUM: 41.9, DE_CUM: -8.2,  DL_CUM: -7.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Hull',    DISC: 'Piping',     PE_WK: 2.9, PL_WK: 2.9, AC_WK: 1.1, FC_WK: 1.1, DE_WK: -1.8, DL_WK: -1.8, PE_CUM: 52.8, PL_CUM: 52.4, AC_CUM: 30.2, FC_CUM: 30.2, DE_CUM: -22.6, DL_CUM: -22.2, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Hull',    DISC: 'Structural', PE_WK: 2.9, PL_WK: 2.9, AC_WK: 2.2, FC_WK: 2.2, DE_WK: -0.7, DL_WK: -0.7, PE_CUM: 65.5, PL_CUM: 65.9, AC_CUM: 65.2, FC_CUM: 65.2, DE_CUM: -0.3,  DL_CUM: -0.6, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'LQ',      DISC: 'LQ-All',     PE_WK: 0.1, PL_WK: 0.1, AC_WK: 9.3, FC_WK: 9.3, DE_WK: 9.2,  DL_WK: 9.2,  PE_CUM: 0.7,  PL_CUM: 0.7,  AC_CUM: 35,   FC_CUM: 35,   DE_CUM: 34.3,  DL_CUM: 34.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'LQ',      DISC: 'Overall',    PE_WK: 0.1, PL_WK: 0.1, AC_WK: 9.3, FC_WK: 9.3, DE_WK: 9.2,  DL_WK: 9.2,  PE_CUM: 0.7,  PL_CUM: 0.7,  AC_CUM: 35,   FC_CUM: 35,   DE_CUM: 34.3,  DL_CUM: 34.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Overall', DISC: 'E&I',        PE_WK: 0.6, PL_WK: 0.5, AC_WK: 0.2, FC_WK: 0.2, DE_WK: -0.4, DL_WK: -0.3, PE_CUM: 19.5, PL_CUM: 18.8, AC_CUM: 16.1, FC_CUM: 16.1, DE_CUM: -3.4,  DL_CUM: -2.7, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 0.9, PL_WK: 0.7, AC_WK: 0.4, FC_WK: 0.4, DE_WK: -0.5, DL_WK: -0.3, PE_CUM: 20.2, PL_CUM: 18.7, AC_CUM: 16.9, FC_CUM: 16.9, DE_CUM: -3.3,  DL_CUM: -1.8, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Overall', DISC: 'LQ-All',     PE_WK: 0.1, PL_WK: 0.1, AC_WK: 9.3, FC_WK: 9.3, DE_WK: 9.2,  DL_WK: 9.2,  PE_CUM: 0.7,  PL_CUM: 0.7,  AC_CUM: 35,   FC_CUM: 35,   DE_CUM: 34.3,  DL_CUM: 34.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Overall', DISC: 'Mechanical', PE_WK: 1.1, PL_WK: 1,   AC_WK: 0,   FC_WK: 0,   DE_WK: -1,   DL_WK: -0.9, PE_CUM: 44.9, PL_CUM: 42.8, AC_CUM: 39.3, FC_CUM: 39.3, DE_CUM: -5.7,  DL_CUM: -3.5, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Overall', DISC: 'Overall',    PE_WK: 1.6, PL_WK: 1.5, AC_WK: 1.1, FC_WK: 1.2, DE_WK: -0.4, DL_WK: -0.4, PE_CUM: 37.3, PL_CUM: 36.4, AC_CUM: 34,   FC_CUM: 34,   DE_CUM: -3.3,  DL_CUM: -2.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Overall', DISC: 'Piping',     PE_WK: 2.4, PL_WK: 2.4, AC_WK: 1,   FC_WK: 1.3, DE_WK: -1.4, DL_WK: -1.3, PE_CUM: 46.2, PL_CUM: 45.9, AC_CUM: 23,   FC_CUM: 23,   DE_CUM: -23.3, DL_CUM: -23, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Overall', DISC: 'Structural', PE_WK: 3.2, PL_WK: 3.2, AC_WK: 2.5, FC_WK: 2.5, DE_WK: -0.7, DL_WK: -0.7, PE_CUM: 51.3, PL_CUM: 51.5, AC_CUM: 61.1, FC_CUM: 61.1, DE_CUM: 9.9,   DL_CUM: 9.7, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Topside', DISC: 'E&I',        PE_WK: 0.4, PL_WK: 0.4, AC_WK: 0.1, FC_WK: 0.1, DE_WK: -0.3, DL_WK: -0.3, PE_CUM: 16.7, PL_CUM: 16.4, AC_CUM: 14.3, FC_CUM: 14.3, DE_CUM: -2.4,  DL_CUM: -2.1, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 1.1, PL_WK: 0.9, AC_WK: 0.4, FC_WK: 0.4, DE_WK: -0.8, DL_WK: -0.5, PE_CUM: 28.4, PL_CUM: 26,   AC_CUM: 17.9, FC_CUM: 17.9, DE_CUM: -10.5, DL_CUM: -8.1, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Topside', DISC: 'Mechanical', PE_WK: 1.1, PL_WK: 1,   AC_WK: 0,   FC_WK: 0,   DE_WK: -1,   DL_WK: -0.9, PE_CUM: 44.6, PL_CUM: 42.7, AC_CUM: 39.3, FC_CUM: 39.3, DE_CUM: -5.3,  DL_CUM: -3.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Topside', DISC: 'Overall',    PE_WK: 1.4, PL_WK: 1.3, AC_WK: 0.6, FC_WK: 0.6, DE_WK: -0.8, DL_WK: -0.7, PE_CUM: 33.9, PL_CUM: 32.9, AC_CUM: 30.2, FC_CUM: 30.2, DE_CUM: -3.7,  DL_CUM: -2.7, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Topside', DISC: 'Piping',     PE_WK: 2.1, PL_WK: 2.1, AC_WK: 1,   FC_WK: 1.4, DE_WK: -1.1, DL_WK: -1.1, PE_CUM: 42.3, PL_CUM: 42.1, AC_CUM: 18.6, FC_CUM: 18.6, DE_CUM: -23.7, DL_CUM: -23.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'Overall', AREA: 'Topside', DISC: 'Structural', PE_WK: 3.8, PL_WK: 3.8, AC_WK: 3.2, FC_WK: 3.2, DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 26.5, PL_CUM: 26.5, AC_CUM: 54,   FC_CUM: 54,   DE_CUM: 27.5,  DL_CUM: 27.5, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Hull',    DISC: 'E&I',        PE_WK: 1.1, PL_WK: 0.9, AC_WK: 0.5, FC_WK: 0.5, DE_WK: -0.6, DL_WK: -0.4, PE_CUM: 39.6, PL_CUM: 36.3, AC_CUM: 34.1, FC_CUM: 34.1, DE_CUM: -5.5,  DL_CUM: -2.1, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Hull',    DISC: 'HVAC/Arch.', PE_WK: 1.8, PL_WK: 1.6, AC_WK: 0.6, FC_WK: 0.6, DE_WK: -1.1, DL_WK: -0.9, PE_CUM: 34.8, PL_CUM: 33.5, AC_CUM: 29.2, FC_CUM: 29.2, DE_CUM: -5.6,  DL_CUM: -4.2, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Hull',    DISC: 'Mechanical', PE_WK: 1.2, PL_WK: 0.9, AC_WK: 0,   FC_WK: 0,   DE_WK: -1.2, DL_WK: -0.9, PE_CUM: 49.8, PL_CUM: 43.4, AC_CUM: 38.9, FC_CUM: 38.9, DE_CUM: -10.8, DL_CUM: -4.5, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Hull',    DISC: 'Overall',    PE_WK: 1.2, PL_WK: 1.1, AC_WK: 0.6, FC_WK: 0.6, DE_WK: -0.5, DL_WK: -0.4, PE_CUM: 44.9, PL_CUM: 42.9, AC_CUM: 36.6, FC_CUM: 36.6, DE_CUM: -8.2,  DL_CUM: -6.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Hull',    DISC: 'Piping',     PE_WK: 2.2, PL_WK: 2,   AC_WK: 0.6, FC_WK: 0.6, DE_WK: -1.6, DL_WK: -1.5, PE_CUM: 47.8, PL_CUM: 46.5, AC_CUM: 37.8, FC_CUM: 37.8, DE_CUM: -10,   DL_CUM: -8.7, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Hull',    DISC: 'Structural', PE_WK: 0.6, PL_WK: 0.6, AC_WK: 1.1, FC_WK: 1.1, DE_WK: 0.5,  DL_WK: 0.5,  PE_CUM: 47.8, PL_CUM: 48.9, AC_CUM: 42.4, FC_CUM: 42.4, DE_CUM: -5.4,  DL_CUM: -6.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Overall', DISC: 'E&I',        PE_WK: 0.9, PL_WK: 0.8, AC_WK: 0.3, FC_WK: 0.3, DE_WK: -0.6, DL_WK: -0.5, PE_CUM: 35.1, PL_CUM: 33.9, AC_CUM: 30.6, FC_CUM: 30.6, DE_CUM: -4.6,  DL_CUM: -3.3, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Overall', DISC: 'HVAC/Arch.', PE_WK: 1.6, PL_WK: 1.3, AC_WK: 0.7, FC_WK: 0.7, DE_WK: -0.9, DL_WK: -0.6, PE_CUM: 37.2, PL_CUM: 34.5, AC_CUM: 31.1, FC_CUM: 31.1, DE_CUM: -6,    DL_CUM: -3.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Overall', DISC: 'Mechanical', PE_WK: 1.1, PL_WK: 1,   AC_WK: 0,   FC_WK: 0,   DE_WK: -1,   DL_WK: -0.9, PE_CUM: 44.9, PL_CUM: 42.8, AC_CUM: 39.3, FC_CUM: 39.3, DE_CUM: -5.7,  DL_CUM: -3.5, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Overall', DISC: 'Overall',    PE_WK: 1.1, PL_WK: 1,   AC_WK: 0.4, FC_WK: 0.4, DE_WK: -0.7, DL_WK: -0.6, PE_CUM: 41.3, PL_CUM: 39.7, AC_CUM: 35.4, FC_CUM: 35.4, DE_CUM: -5.9,  DL_CUM: -4.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Overall', DISC: 'Piping',     PE_WK: 1.5, PL_WK: 1.4, AC_WK: 1.4, FC_WK: 1.4, DE_WK: -0.1, DL_WK: 0,    PE_CUM: 36.4, PL_CUM: 35.8, AC_CUM: 27.2, FC_CUM: 27.2, DE_CUM: -9.2,  DL_CUM: -8.6, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Overall', DISC: 'Structural', PE_WK: 0.6, PL_WK: 0.6, AC_WK: 1.1, FC_WK: 1.1, DE_WK: 0.5,  DL_WK: 0.4,  PE_CUM: 46.2, PL_CUM: 47.2, AC_CUM: 41,   FC_CUM: 41,   DE_CUM: -5.2,  DL_CUM: -6.2, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Topside', DISC: 'E&I',        PE_WK: 0.8, PL_WK: 0.8, AC_WK: 0.2, FC_WK: 0.2, DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 33.6, PL_CUM: 33.1, AC_CUM: 29.3, FC_CUM: 29.3, DE_CUM: -4.4,  DL_CUM: -3.8, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Topside', DISC: 'HVAC/Arch.', PE_WK: 1.5, PL_WK: 1.1, AC_WK: 0.8, FC_WK: 0.8, DE_WK: -0.7, DL_WK: -0.4, PE_CUM: 38.1, PL_CUM: 34.9, AC_CUM: 38.1, FC_CUM: 38.1, DE_CUM: 0,     DL_CUM: 3.2, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Topside', DISC: 'Mechanical', PE_WK: 1.1, PL_WK: 1,   AC_WK: 0,   FC_WK: 0,   DE_WK: -1,   DL_WK: -0.9, PE_CUM: 44.6, PL_CUM: 42.7, AC_CUM: 39.3, FC_CUM: 39.3, DE_CUM: -5.3,  DL_CUM: -3.4, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Topside', DISC: 'Overall',    PE_WK: 1.1, PL_WK: 1,   AC_WK: 0.3, FC_WK: 0.3, DE_WK: -0.8, DL_WK: -0.7, PE_CUM: 40.3, PL_CUM: 38.9, AC_CUM: 35,   FC_CUM: 35,   DE_CUM: -5.4,  DL_CUM: -3.9, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Topside', DISC: 'Piping',     PE_WK: 1.3, PL_WK: 1.2, AC_WK: 1.7, FC_WK: 1.7, DE_WK: 0.3,  DL_WK: 0.4,  PE_CUM: 33,   PL_CUM: 32.6, AC_CUM: 24,   FC_CUM: 24,   DE_CUM: -9,    DL_CUM: -8.6, },
{ CDATE: '2024-09-27', PHASE: 'Procurement', LEV: 'RFQ',     AREA: 'Topside', DISC: 'Structural', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,   FC_WK: 0,   DE_WK: 0,    DL_WK: 0,    PE_CUM: 7.5,  PL_CUM: 7.5,  AC_CUM: 7.5,  FC_CUM: 7.5,  DE_CUM: 0,     DL_CUM: 0, },

  ],


  Queries: {

    SQL1: [ 
      {
        CANVAS_WIDTH:           1300,
        CANVAS_HEIGHT:          850,
        
        PADDING_TOP:            290,
        PADDING_RIGHT:          90,
        PADDING_BOTTOM:         30,
        PADDING_LEFT:           90,

        // Timeline
        WEEKEND:                'friday',
        CUTOFF:                 '2024-09-27',


        // Line Chart Properties
        REF_CODE                : 'PE_CUM     / PL_CUM     / AC_CUM  / FC_CUM   ',
        NAME                    : 'Early Plan / Late Plan  / Actual  / Forecast ',
        LINE_TYPE               : 'LINE       / LINE       / ACT     / DASH     ',
        LINE_SIZE               : '1.5        / 1.5        / 1.5     / 1.5      ',
        LINE_COLOR              : '#A7A7A7    / #44A9DF    / #EC407A / #EC407A  ',
        LINE_OPACITY            : '1          / 1          / 1       / 1        ',
      }
    ],

    SQL2: [
      { MS: 'Steel Cutting',         MSDATE: '2024-11-01', },
      { MS: 'Block Paint',           MSDATE: '2025-03-14', },
      { MS: '2nd P.E ',              MSDATE: '2025-04-08', },
      { MS: 'Setting',               MSDATE: '2025-07-01', },
      { MS: 'Mechanical Completion', MSDATE: '2025-08-25', },
      { MS: 'Commissioning',         MSDATE: '2026-02-02', },
    ],
    
  }
}