
export default {
  Canvas: {
    CanvasWidth: 1500,
    CanvasHeight: 300,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  // --> this.rawData
  DataItems: [ 
    {
      COUNT: 4,
      TITLE: 'WorkDown',
      PROG:  75,

      TEXT1: 'Total',
      TEXT2: 'Issued$',
      TEXT3: 'Remain%',
      TEXT4: 'Remain%',

      COLOR1: '#fff',
      COLOR2: '#fff',
      COLOR3: '#F35E90',
      COLOR4: '#F35E90',
      
      VALUE1: 1111,
      VALUE2: 2222,
      VALUE3: 3333,
      VALUE4: 444,

      LINK1: 'Y',
      LINK2: 'Y',
      LINK3: 'N',
      LINK4: 'Y',

      FILTER1: 'filter...',
      FILTER2: 'filter...',
      FILTER3: 'filter...',
      FILTER4: 'filter...',
    },
  ],

  Queries: {
    // --> this.style
    SQL1:  [
      {
        // TITLE
        TITLE_VISIBLE: 'Y',
        TITLE_V_PADDING: 5,
        TITLE_TEXT_SIZE: 11,
        TITLE_TEXT_COLOR: '#333',
        TITLE_BOX_COLOR: '#E0E0DF',
        TITLE_BOX_RADIUS: 3,
        PLUS_Y: 1,

        // PROGRESS
        PROG_VISIBLE: 'Y',
        PROG_TEXT_SIZE: 15,
        PROG_TEXT_COLOR: '#44A9DF',
        PROG_TEXT_UNIT: '%',

        // TABLE
        TABLE_BOX_WIDTH: 150,
        TABLE_BOX_HEIGHT: 40,
        TABLE_BOX_COLOR: '#83D2F5',
        TABLE_BOX_OPACITY: .7,
        TABLE_RADIUS: 3,

        TABLE_HEADER_Y: 5,
        TABLE_HEADER_TEXT_SIZE: 9,
        TABLE_HEADER_TEXT_COLOR: '#333',
        
        TABLE_VALUE_Y: 20,
        TABLE_VALUE_TEXT_SIZE: 11,
      },
    ],
  },
}