export default {
    Canvas: {
      CanvasWidth: 100,
      CanvasHeight: 100,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 100,
      CanvasChartY: 100,
      CanvasChartWidth: 0,
      CanvasChartHeight: 0,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    DataItems: [],    
}