import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 16,
      CanvasHeight: 16,
      CanvasBorderWeight: 0,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 300,
      CanvasChartHeight: 300,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#fff',
      CanvasChartFillColor: '#fff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },


    DataItems: [
      {text: 'Disciplines', value: 0},
      {text: 'BMW',         value: 1},
      {text: 'Audi',        value: 2},
      {text: 'Mercedes',    value: 3},
      {text: 'Hyundai',     value: 4},
    ],
}