// Common
import Comm_TabModalContainer_Data from '../01_ModalContainer/includes/primitives/Data'

// Project
import Project_InformationGuideSectors_Data from '../../09_Projects/China1/01_Project/InformationGuideSectors/includes/primitives/Data'

// Planning

// Engineering

// Procurement

// Construction

// System Completion

// ------------------------------------------------------------------------------------------------------------------------------------------------

export default {
  Comm_TabModalContainer_Data,
  Project_InformationGuideSectors_Data,
}

