import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 127,
      CanvasHeight: 20,
      CanvasBorderWeight: 0,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent', //Transparent
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 11,
      CanvasChartHeight: 11,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#fff',
      CanvasChartFillColor: '#fff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [
      {text: 'Disciplines', value: 0},
      {text: 'BMW',         value: 1},
      {text: 'Audi',        value: 2},
      {text: 'MercedesMercedes',    value: 3},
      {text: 'Hyundai',     value: 4},
    ],
}