import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 230,
      CanvasHeight: 1000,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: '#003D74', //Transparent
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 11,
      CanvasChartHeight: 11,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#fff',
      CanvasChartFillColor: '#fff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
      { MOD: '42M078', FAB: 100, A1: 100, B1: 94.4, },
      { MOD: '41M008', FAB: 100, A1: 100, B1: 88, },
      { MOD: '41M010', FAB: 100, A1: 99.7, B1: 66.3, },
      { MOD: '42M065', FAB: 100, A1: 100, B1: 91.9, },
      { MOD: '42M057', FAB: 100, A1: 100, B1: 96, },
      { MOD: '42M075', FAB: 100, A1: 100, B1: 84.7, },
      { MOD: '42M056', FAB: 99, A1: 94.5, B1: 84.6, },
      { MOD: '42M055', FAB: 97.9, A1: 88.1, B1: 85.8, },
      { MOD: '42M054', FAB: 97.7, A1: 88.9, B1: 86.7, },
      { MOD: '41M011', FAB: 97.7, A1: 90.7, B1: 77.1, },
      { MOD: '41M009', FAB: 93.1, A1: 85.2, B1: 26.3, },
      { MOD: '47M265', FAB: 91.8, A1: 31.7, B1: 0, },
      { MOD: '41M801', FAB: 91.5, A1: 64.8, B1: 78.8, },
      { MOD: '47M266', FAB: 90.8, A1: 33.2, B1: 0, },
      { MOD: '41M006', FAB: 86.6, A1: 43.6, B1: 81.7, },
      { MOD: '41M007', FAB: 86.3, A1: 35.3, B1: 0, },
      { MOD: '47M268', FAB: 86.2, A1: 39.7, B1: 18.7, },
      { MOD: '41M001', FAB: 81.8, A1: 31, B1: 22.3, },
      { MOD: '47M267', FAB: 81, A1: 33.4, B1: 6.7, },
      { MOD: '47M261', FAB: 79.5, A1: 17.7, B1: 0, },
      { MOD: '47M262', FAB: 77.8, A1: 13.7, B1: 0, },
      { MOD: '41M004', FAB: 74.3, A1: 15.9, B1: 8.2, },
      { MOD: '41M003', FAB: 73, A1: 14.4, B1: 5.9, },
      { MOD: '47M263', FAB: 71, A1: 3.6, B1: 0, },
    ],
}